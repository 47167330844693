import { useCallback, useContext, useReducer } from "react";
import CompetitionDropdown from "../components/forms/Dropdowns/CompetitionDropdown"
import { UserDataContext } from "../firebase/UserDataContext";
import { Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { FirebaseNewContext } from "../firebase/FirebaseNewContext";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormErrorMessages from "../components/forms/Messages/FormErrorMessages";
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

function accountPageInitalState(values) {
    return {
        defaultCompetition: values.defaultCompetition,
        password: {
            new: '',
            newShow: false,
            newConfirm: '',
            newConfirmShow: false,
            messages: []
        },
        email: {
            current: '',
            new: '',
            newConfirm: '',
            messages: []
        }
    }
}
function accountPageReducer(state, action) {
    switch (action.type) {
        case 'competition_changed':
            return {
                ...state,
                defaultCompetition: action.value
            }
        case 'password_message_changed':
            return {
                ...state,
                password: {
                    ...state.password,
                    ['messages']: action.value,
                    ['success']: action.success

                }
            }
        case 'password_changed':
            return {
                ...state,
                password: {
                    ...state.password,
                    [action.for]: action.value
                }
            }
        case 'password_show':
            return {
                ...state,
                password: {
                    ...state.password,
                    [`${action.for}Show`]: !state.password[`${action.for}Show`]
                }
            }
        case 'email_message_changed':
            return {
                ...state,
                email: {
                    ...state.email,
                    ['messages']: action.value,
                    ['success']: action.success

                }
            }
        case 'email_changed':
            return {
                ...state,
                email: {
                    ...state.email,
                    [action.for]: action.value
                }
            }
        default:
            return state;
    }
}
const AccountPage = (props) => {
    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const [state, dispatch] = useReducer(accountPageReducer, { ...props, ...{ defaultCompetition: defaultSelectedCompetition } }, accountPageInitalState)
    const { firebase } = useContext(FirebaseNewContext)

    const competitionChange = useCallback((value) => {
        dispatch({
            type: 'competition_changed',
            value: value
        })

    }, [])
    const saveChanges = async () => {
        console.log('this is our saved default competition', state.defaultCompetition)
        const result = await firebase.patchUser(user.uid, { ...user, ...{ defaultCompetition: state.defaultCompetition } }, defaultSelectedCompetition)
    }

    const submitButtonPressed = useCallback(() => {
        saveChanges()

    }, [state.defaultCompetition])

    const passwordChange = useCallback((forWhat, e) => {
        e.preventDefault()
        dispatch({
            type: 'password_changed',
            value: e.target.value,
            for: forWhat
        })
    }, [state.password])
    const passwordVisibilityChange = useCallback((forWhat, e) => {
        e.preventDefault()
        dispatch({
            type: 'password_show',
            for: forWhat
        })

    }, [state.password])

    const PasswordVisibility = (props) => {
        return (
            <InputAdornment position="start">
                <IconButton onClick={props.onClick}>
                    {props.show ? <VisibilityOff /> : <Visibility />}
                </IconButton>
            </InputAdornment>
        )
    }

    const savePasswordPressed = useCallback((e) => {
        e.preventDefault()
        dispatch({
            type: 'toggle_buttons',
        })

        const results = passwordValid(state.password.new, state.password.newConfirm)
        dispatch({
            type: 'password_message_changed',
            value: results,
        })

        const submit = async (results) => {
            if (results.length == 0) {
                const result = await firebase.updateUserPassword(state.password.new)
                dispatch({
                    type: 'password_error_changed',
                    value: result.reason,
                })
                // if result.succcess show good job dialog and reload the page on close

                // if success also update their email within our current firebase instance
                //const result = await firebase.patchUser(user.uid, { ...user, ...{ defaultCompetition: state.defaultCompetition } })
            }
            dispatch({
                type: 'toggle_buttons',
            })
        }
        submit(results)
    }, [state.password])

    const userEmailChanged = useCallback((forWhat, e) => {
        e.preventDefault()
        dispatch({
            type: 'email_changed',
            value: e.target.value.trim(),
            for: forWhat
        })

    }, [state.email, user])

    const emailValid = (email1, email2) => {
        let errors = []
        switch (true) {
            case !email1.trim() || !email2.trim():
                errors = errors.concat("Email fields cannot be blank")
                break;
            case email1 !== email2:
                errors = errors.concat("Emails do not match")
                break;
        }
        return errors
    }
    const passwordValid = (password1, password2) => {
        let errors = []
        switch (true) {
            case !password1.trim() || !password2.trim():
                errors = errors.concat("Passwords fields cannot be blank")
                break;
            case password1 !== password2:
                errors = errors.concat("Passwords do not match")
                break;
            case password1.length < 8:
                errors = errors.concat("Passwords must have at least 8 characters")
                break;
        }
        return errors
    }

    const changeEmailPressed = useCallback((e) => {
        e.preventDefault()
        dispatch({
            type: 'toggle_buttons',
        })

        const results = emailValid(state.email.new, state.email.newConfirm)
        dispatch({
            type: 'email_message_changed',
            value: results,
        })

        const submit = async (results) => {
            if (results.length == 0) {
                const result = await firebase.updateUserEmail(state.email.new)
                console.log('this is our result from the call', result)
                dispatch({
                    type: 'email_message_changed',
                    value: result.reason,
                })
                // if result.succcess show good job dialog and reload the page on close

                // TODO patch the user in the database
                //const result = await firebase.patchUser(user.uid, { ...user, ...{ defaultCompetition: state.defaultCompetition } })

            }
            dispatch({
                type: 'toggle_buttons',
            })
        }
        submit(results)

    }, [state.email])

    return (
        <Grid2 textAlign={"left"}>
            <Typography variant="h3">Account Page</Typography>
            <Grid2>
                <CompetitionDropdown
                    value={state.defaultCompetition}
                    onChange={competitionChange}
                />
                <Button onClick={submitButtonPressed}>Save Competition</Button>
            </Grid2>
            <Grid2 container flexDirection={'column'}>
                <Typography variant="h4">Password Change</Typography>
                <Grid2
                    sx={{
                        '& .MuiTextField-root': { m: 1, },
                    }}
                    container
                    flexDirection={"column"}
                >
                    <TextField
                        fullWidth
                        sx={{ flex: 1 }}
                        value={state.password.new}
                        label={'new password'}
                        type={state.password.newShow ? 'text' : 'password'}
                        onChange={passwordChange.bind(this, 'new')}
                        InputProps={{
                            startAdornment: (
                                <PasswordVisibility
                                    onClick={passwordVisibilityChange.bind(this, 'new')}
                                    show={state.password.newShow}
                                />
                            )
                        }}
                    />
                    <TextField
                        fullWidth
                        value={state.password.newConfirm}
                        type={state.password.newConfirmShow ? 'text' : 'password'}
                        label={'confirm password'}
                        onChange={passwordChange.bind(this, 'newConfirm')}
                        InputProps={{
                            startAdornment: (
                                <PasswordVisibility
                                    onClick={passwordVisibilityChange.bind(this, 'newConfirm')}
                                    show={state.password.newConfirmShow}
                                />
                            )
                        }}
                    />
                    <FormErrorMessages
                        errorMessages={state.password.messages}
                        success={state.password.success}
                    />
                </Grid2>
            </Grid2>
            <Button onClick={savePasswordPressed}>Save Password</Button>
            <Grid2 container flexDirection={"column"}
                sx={{
                    '& .MuiTextField-root': { m: 1, },
                }}
            >
                <Typography variant="h4">Email</Typography>
                <TextField
                    value={user.email}
                    label={"current email"}
                    disabled
                />
                <TextField
                    value={state.email.new}
                    label={"new email"}
                    onChange={userEmailChanged.bind(this, 'new')}
                    disabled
                    sx={{display: 'none'}}
                />
                <TextField
                    value={state.email.newConfirm}
                    label={"new email confirm"}
                    onChange={userEmailChanged.bind(this, 'newConfirm')}
                    disabled
                    sx={{display: 'none'}}
                />
                <FormErrorMessages
                    errorMessages={state.email.messages}
                />
            </Grid2>
            <Button sx={{display: 'none'}} onClick={changeEmailPressed}>Change Email</Button>
        </Grid2>
    )
}
// TODO - where we left off, success dialog messaing
//  from here reload the page on success
// TODO - confirm the user email gets updated in the application (not just firebase)
// TODO - delete the old form
// TODO - fix all the errors that come up when you have empty data

export default AccountPage
