import React, { useState, useEffect, useContext } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import DraggableEvent from './DraggableEvent';
import EventDrawer from './EventDrawer';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CheckCircleOutline } from '@mui/icons-material';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';

function SnakeEventManager({ initialEvents, presentEvent, setOuterEvents, competition }) {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [newEvent, setNewEvent] = useState({ type: 'Event', name: '', duration: '' });
    const { firebase } = useContext(FirebaseNewContext);
    const [selectedCompetition, setSelectedCompetition] = useState(null);
    const { globalSettings } = useContext(GlobalSettingsContext);

    useEffect(() => {
        setEvents(initialEvents);
        setSelectedCompetition(competition);
    }, [initialEvents, selectedCompetition, competition]);

    const columns = 4;

    const handleEventClick = (event) => {
        let { type, name, duration, carousel, image } = event;

        switch (type) {
            case 'Event':
                name = name || 'Event';
                duration = duration || 0;
                break;
            case 'Break':
                name = name || 'Break';
                duration = duration || 0;
                break;
            case 'Lunch':
                name = name || 'Lunch';
                duration = duration || 0;
                break;
default:
                type = 'Custom';
                break;
        }

        setSelectedEvent(event);
        setNewEvent({ type, name, duration, carousel, image });
        setDrawerOpen(true);
    };

    const handleAddClick = () => {
        setNewEvent({ type: 'Event', name: 'Event', duration: '', carousel: false, image: 'default' });
        setDrawerOpen(true);
    };

    const handleTypeChange = (e) => {
        const type = e.target.value;
        let name = '';
        let duration = '';
        let carousel = false;

        switch (type) {
            case 'Event':
                name = 'Event';
                duration = 15;
                break;
            case 'Break':
                name = 'Break';
                duration = 30;
                carousel = true;
                break;
            case 'Lunch':
                name = 'Lunch';
                duration = 45;
                carousel = true;
                break;
            case 'Custom':
                name = '';
                duration = '';
                break;
            default:
                break;
        }

        setNewEvent({ ...newEvent, type, name, duration, carousel });
    };

    const handleSave = () => {
        let updatedEvents;
        if (selectedEvent) {
            updatedEvents = events.map((event, index) =>
                index === selectedEvent.originalIndex
                    ? { ...newEvent, carousel: newEvent.carousel, originalIndex: selectedEvent.originalIndex, duration: newEvent.duration === '' ? 0 : newEvent.duration }
                    : event
            );
            setEvents(updatedEvents);
        } else if (newEvent) {
            if (newEvent.duration === undefined) {
                newEvent.duration = 0;
            }
            
            updatedEvents = [...events, { ...newEvent, carousel: newEvent.carousel, originalIndex: events.length, duration: newEvent.duration === '' ? 0 : newEvent.duration }];

            if(Math.floor((updatedEvents.length - 1) / columns) % 2 !== 0) {
                let max = Math.ceil(updatedEvents.length / columns) * columns - 1;
                updatedEvents = moveEvent(max, max - ((updatedEvents.length - 1) % columns), updatedEvents);
            }
            setEvents(updatedEvents);
        }

        setNewEvent({ type: 'Event', name: '', duration: '' });
        setSelectedEvent(null);
        setDrawerOpen(false);

        setOuterEvents(updatedEvents);
    };

    const deleteEvent = (index) => {
        const updatedEvents = events.filter((_, i) => i !== index);
        setEvents(updatedEvents);
        setOuterEvents(updatedEvents);
        setSelectedEvent(null);
        setDrawerOpen(false);
    };

    const adjustedIndex = (index, columns) => {
        const row = Math.floor(index / columns);
        const positionInRow = index % columns;

        return row % 2 === 0 
            ? index
            : (row + 1) * columns - positionInRow - 1;
    };

    const moveItemInFrontOf = (array, fromIndex, toIndex) => {
        if (fromIndex === toIndex) return array;

        const [item] = array.splice(fromIndex, 1);
        array.splice(toIndex, 0, item);

        return array;
    };

    const moveEvent = (fromIndex, toIndex, initialEvents) => {
        let nEvents = initialEvents !== undefined ? initialEvents : events;

        fromIndex = adjustedIndex(fromIndex, columns);
        toIndex = adjustedIndex(toIndex, columns);

        let sortedList = [...nEvents]
            .map((item, index) => ({
                ...item,
                adjustedIndex: adjustedIndex(index, columns),
            }))
            .sort((a, b) => a.adjustedIndex - b.adjustedIndex)
            .map((item) => item);

        moveItemInFrontOf(sortedList, fromIndex, toIndex);

        sortedList = sortedList.map((item, index) => {
            const unadjustedIdx = adjustedIndex(index, columns);
            const row = Math.floor(unadjustedIdx / columns);

            let newOriginalIndex;
            if (row % 2 === 0) {
                newOriginalIndex = unadjustedIdx;
            } else {
                newOriginalIndex = (columns - 1) - (unadjustedIdx % columns) + (row * columns);
            }

            return {
                ...item,
                originalIndex: newOriginalIndex,
            };
        });

        let unsortedList = sortedList
            .map((item, index) => ({
                ...item,
                adjustedIndex: adjustedIndex(index, columns),
            }))
            .sort((a, b) => a.adjustedIndex - b.adjustedIndex)
            .map((item) => item);

        setEvents(unsortedList);
        setOuterEvents(unsortedList);

        return unsortedList;
    };

    const renderEvents = () => {
        const rows = Math.ceil(events.length / columns);
        let renderedEvents = [];

        for (let row = 0; row < rows; row++) {
            const start = row * columns;
            const end = start + columns;
            let currentRowEvents = events.slice(start, end).map((event, index) => ({
                ...event,
                originalIndex: start + index,
            }));

            if (row % 2 !== 0) {
                // Add empty slots at the start of the row for the snake pattern
                const emptySlots = Array(columns - currentRowEvents.length).fill(null);
                currentRowEvents = emptySlots.concat(currentRowEvents);
            }

            renderedEvents = [
                ...renderedEvents,
                ...currentRowEvents.map((event, index) =>
                    event ? (
                        <DraggableEvent
                            key={event.originalIndex}
                            event={event}
                            index={start + index}
                            moveEvent={moveEvent}
                            handleEventClick={handleEventClick}
                            renderArrow={(index) => renderArrow(row, index, currentRowEvents.length)}
                            disabled={globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[selectedCompetition]}
                        />
                    ) : (
                        <div key={`empty-${start + index}`} />
                    )
                ),
            ];
        }

        return renderedEvents;
    };

    const renderArrow = (row, index, rowLength) => {
        const isFirstInRow = (rowLength - index) === 0;
        const isLastInRow = index === (rowLength * (row + 1)) - 1;
        
        if(adjustedIndex(index, columns) === events.length - 1) {
            return <CheckCircleOutline />;
        }

        if (row % 2 === 0) {
            if (!isLastInRow) {
                return <ArrowForwardIcon />;
            } else {
                return <ArrowDownwardIcon />;
            }
        } else {
            if (!isFirstInRow) {
                return <ArrowBackIcon />;
            } else {
                return <ArrowDownwardIcon />;
            }
        }
    };

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: `repeat(${columns}, 1fr)`,
                        gridAutoRows: 'minmax(100px, auto)',
                        gap: '8px',
                    }}
                >
                    {renderEvents()}
                    <Paper
                        onClick={handleAddClick}
                        style={{
                            padding: '16px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <AddIcon style={{ fontSize: 48 }} />
                    </Paper>
                </div>
                <EventDrawer
                    drawerOpen={drawerOpen}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    newEvent={newEvent}
                    setNewEvent={setNewEvent}
                    handleTypeChange={handleTypeChange}
                    handleSave={handleSave}
                    setDrawerOpen={setDrawerOpen}
                    deleteEvent={deleteEvent}
                    presentEvent={presentEvent}
                    firebase={firebase}
                    selectedCompetition={selectedCompetition}
                />
            </div>
        </DndProvider>
    );
}

export default SnakeEventManager;

