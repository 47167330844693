import React, { useState, useContext, useMemo, useCallback } from 'react';
import * as ROUTES from '../routes/routes';
import { UserDataContext } from '../firebase/UserDataContext';
import { Button, Chip, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { EventScoresheetForm } from '../components/forms/ScoresheetForm';
import { CommonDataContext } from '../firebase/CommonDataContext';
import { DataGrid } from '@mui/x-data-grid';
import { determineScoresheetTableChipColor } from '../components/misc/Utils';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';

const eventScoresheetColumns = [
    { field: 'col1', headerName: 'Event Number', width: 200 },
    { field: 'col2', headerName: 'Event Name', width: 500 },
    {
        field: 'col3', headerName: 'Status', width: 200,
        renderCell: (params) => {
            const {
                status,
                eventInfo
            } = params.value
            if (eventInfo.isQuiz) {
                return false
            }
            let variant = determineScoresheetTableChipColor(status);
            let variantProps = variant === '' ? {} : { color: variant }
            return (<Chip label={status} {...variantProps} />)
        }
    },
    // TODO - make the actions do what you need them to
    // VIEW | LOCK | APPROVE | ALLOW_EDIT
    {
        field: 'col4', headerName: 'Actions', width: 200,
        renderCell: (params) => {
            const { eventUid, competition, status, eventInfo } = params.value
            const navParams = {
                eventUid,
                competition
            }
            if (eventInfo.isQuiz) {
                return (
                    <Button
                        disabled
                    >Unable To Edit Quizzes</Button>
                )
            }
            let toNavigate = ROUTES.routeReplace(ROUTES.EVENT_SCORESHEET_PAGE_NEW, navParams)
            if (status !== '') {
                toNavigate = ROUTES.routeReplace(ROUTES.EVENT_SCORESHEET_PAGE_EDIT, navParams)
            }

            return (
                <Button
                    component={Link}
                    to={toNavigate}>
                    {!status ? 'Create' : 'Edit'}
                </Button>
            )
        }
    },

]

function JudgeScoresheetPage(props) {
    const location = useLocation();
    const params = useParams()

    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const { scoresheetsMap: commonScoresheetsMap, eventsMap: commonEventsMap } = useContext(CommonDataContext)
    const eventsScoresheetsMap = useMemo(() => {
        if (!commonScoresheetsMap || !commonScoresheetsMap[selectedCompetition]) {
            return {}
        }
        return commonScoresheetsMap[selectedCompetition]['events'] || {}
    }, [selectedCompetition, commonScoresheetsMap])

    const eventsMap = useMemo(() => {
        if (!commonEventsMap) {
            return {}
        }
        return commonEventsMap[selectedCompetition] || {}
    }, [selectedCompetition, commonEventsMap])

    const eventsScoresheetRows = useCallback(() => {
        // get the events which are assigned to the judges
        let events = []
        if (user.eventsNew && user.eventsNew[selectedCompetition]) {
            events = user.eventsNew[selectedCompetition]
        }
        return events.map((e, _) => {
            const event = eventsMap[e]
            const scoresheet = eventsScoresheetsMap[e] || {}
            return {
                id: e,
                col1: event['number'],
                col2: event['fullTitle'],
                col3: { status: scoresheet.status || 'Not Yet Created', eventInfo: event },
                col4: { status: scoresheet.status, competition: selectedCompetition, eventUid: e, eventInfo: event }

            }
        })

    }, [selectedCompetition, user])
    return (
        <Grid2>
            {
                location.pathname === ROUTES.EVENT_SCORESHEET_PAGE &&
                (
                    <Grid2>
                        <Typography variant='h3'>Base Scoresheet Page</Typography>
                        <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                            <CompetitionDropdown
                                value={selectedCompetition}
                                onChange={setSelectedCompetition}
                            />
                        </Grid2>
                        <Grid2>
                            <DataGrid
                                sx={{ marginBottom: '25px' }}
                                rows={eventsScoresheetRows()}
                                columns={eventScoresheetColumns}
                            />
                        </Grid2>
                    </Grid2>
                )
            }
            {
                location.pathname.includes('new') &&
                location.pathname.includes('event-scoresheet') &&
                <EventScoresheetForm
                    competition={params.competition}
                    eventUid={params.eventUid}
                />
            }
            {
                location.pathname.includes('edit') &&
                location.pathname.includes('event-scoresheet') &&
                <EventScoresheetForm
                    competition={params.competition}
                    eventUid={params.eventUid}
                    eventTitle={eventsMap[params.eventUid]?.fullTitle}
                />
            }
        </Grid2 >
    )


}



export {
    JudgeScoresheetPage,
    eventScoresheetColumns
}
