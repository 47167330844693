
import React, { useContext, useEffect } from 'react';

import * as routes from '../routes/routes';

import CategoryPage from '../pages/CategoryPage';
import EventPage from '../pages/EventPage';
import DivisionPage from '../pages/DivisionPage';
import UserPage from '../pages/UserPage';
import TeamPage from '../pages/TeamPage';
import CompetitionPage from '../pages/CompetitionPage';
import SlideShowTimerPage from '../pages/SlideShowTimerPage';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import { UserDataContext } from '../firebase/UserDataContext';
import AdminActionsPage from '../pages/AdminActionsPage';
import GlobalSettingsPage from '../pages/GlobalSettingsPage';
import PenaltiesReportPage from '../pages/PenaltiesReportPage';
import { useLocation } from 'react-router';
import BaseScoresheetPage from '../pages/BaseScoresheetPage';
import { ROLES } from '../components/misc/Utils';
import { useNavigate } from 'react-router-dom';
import LiabilityPage from '../pages/LiabilityPage';
import RegistrationSettingsPage from '../pages/RegistrationSettingsPage';
import RegistrationSettingsContextProvider from '../firebase/RegistrationSettingsContext';
import WaitroomPage from '../pages/Waitroom';

// TODO - JUDGE Actions router should be created
//  remove judge event actions from here
//  we will want to keep these two areas separated
// TODO - new Admin-actions with proper prefixed routes
// TODO - new Judge-actions with proper prefixed routes
// TODO - each of these actions go to the router

const AdminActionsRouter = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { user } = useContext(UserDataContext)

    useEffect(() => {
        if (Object.keys(user).length > 0 && !user.loggedIn) {
            navigate(routes.SIGN_IN)
        }
    }, [user, navigate])

    // TODO -
    //  check for Admin Actions as far as the base route
    //  then check for further "base-routes"
    switch (true) {
        case Object.keys(globalSettings).length === 0 || Object.keys(user).length === 0:
            return <div>Loading...</div>
        case user.role !== ROLES.ADMIN:
            return <div>Not authorized to see this view, contact an Adminstrator</div>
        case location.pathname.includes(routes.CATEGORY_PAGE):
            return <CategoryPage />
        case location.pathname.includes(routes.COMPETITION_PAGE):
            return <CompetitionPage />
        case location.pathname.includes(routes.EVENT_PAGE):
            return <EventPage />
        case location.pathname.includes(routes.DIVISION_PAGE):
            return <DivisionPage />
        case location.pathname.includes(routes.USER_PAGE):
            return <UserPage />
        case location.pathname.includes(routes.TEAM_PAGE):
            return <TeamPage />
        case location.pathname.includes(routes.SLIDE_SHOW_TIMER_PAGE):
            return <SlideShowTimerPage />
        case location.pathname.includes(routes.LIABILITY_PAGE):
            return <LiabilityPage />
        case location.pathname.includes(routes.GLOBAL_SETTINGS_PAGE):
            return <GlobalSettingsPage />
        case location.pathname.includes(routes.PENALTIES_REPORT):
            return <PenaltiesReportPage />
        case location.pathname.includes(routes.BASE_SCORESHEET_PAGE):
            return <BaseScoresheetPage />
        case location.pathname.includes(routes.REGISTRATION_SETTINGS_PAGE):
            return (
                <RegistrationSettingsContextProvider>
                    <RegistrationSettingsPage />
                </RegistrationSettingsContextProvider>
            )
        case location.pathname.includes(routes.WAITROOM_PAGE):
            return <WaitroomPage />
        default:
            return <AdminActionsPage />

    }
}

export default AdminActionsRouter
