import React, { useRef, useEffect, useState, useCallback, useMemo, useContext } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { CircularProgress, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SnakeEventManager from '../components/slideshow/SnakeChart';
import breakImage1 from '../images/break-image-1.jpg';
import breakImage2 from '../images/break-image-2.jpg';
import breakImage3 from '../images/break-image-3.jpg';
import breakImage4 from '../images/break-image-4.jpg';
import newGeneralGraphic2024 from '../images/event-image.jpg';
import { UserDataContext } from '../firebase/UserDataContext';
import { FirebaseNewContext } from '../firebase/FirebaseNewContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import AsyncImage from '../components/misc/AsyncImage';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00ff00', // Green
        },
        warning: {
            main: '#ffeb3b', // Yellow
        },
        secondary: {
            main: '#ff5722', // Orange
        },
        error: {
            main: '#f44336', // Red
        },
    },
});

function VisualTimer({ minutes, suffix, onFinished, restartKey, whereAreWe }) {
    const [timeLeft, setTimeLeft] = useState(minutes * 60);
    const [finished, setFinished] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (timeLeft > 0) {
            intervalRef.current = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
        } else if(!finished) {
            setFinished(true);
            clearInterval(intervalRef.current);
            onFinished();
            console.log("Finished");
        } else {
            setFinished(false);   
        }
        return () => clearInterval(intervalRef.current); 
    }, [timeLeft, onFinished]);


    useEffect(() => {
        setTimeLeft(minutes * 60); // Reset timer when minutes or restartKey changes
        return () => clearInterval(intervalRef.current);
    }, [minutes, restartKey]);

    const getColor = (progress) => {
        if (progress > 66) return 'primary';
        if (progress > 33) return 'warning';
        if (progress > 10) return 'secondary';
        return 'error';
    };

    const onBreak = ['Break', 'Lunch'].includes(whereAreWe);
    const circularBreakStyles = { position: 'absolute', top: 50, left: 110 };

    return (
        <ThemeProvider theme={theme}>
            <Grid2 container alignItems="flex-end" justifyContent="center" sx={{ height: '100%', position: 'relative', marginRight: '5em'}}>

                <Grid2 container textAlign={onBreak ? 'right' : 'right'} style={{
                    display: 'inline-flex',
                        position: 'relative',
                }}>
                    <CircularProgress
                        variant="determinate"
                        value={parseFloat(((timeLeft / (minutes * 60)) * 100).toFixed(2))}
                        size={onBreak ? 600 : 600}
                        thickness={1.5}
                        color={getColor((timeLeft / (minutes * 60)) * 100)}
                    />
                </Grid2>
                <Grid2 item xs={12} sx={{
                    justifyContent: 'center', alignItems: 'center',
                    color: onBreak ? 'black' : 'black',
                    textAlign: onBreak ? 'center' : 'center',
                    position: 'absolute',
                        top: 0, left: 0, right: 0, bottom: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: onBreak ? 0 : 0,
                    margin: '0 auto',
                    paddingTop: onBreak ? 0 : 0,
                }}>
                    <Typography variant={onBreak ? 'h1' : 'h1'}>
                        {`${Math.floor(timeLeft / 60)}:${Math.floor(timeLeft % 60).toString().padStart(2, '0')}`}
                    </Typography>
                    <Typography variant={onBreak ? 'h3' : 'h3'} sx={{ marginLeft: onBreak ? 0 : 0, translate: onBreak ? "0" : "0" }}>
                        {suffix}
                    </Typography>
                </Grid2>
            </Grid2>
        </ThemeProvider>
    );
}

function SlideShowTimerPage(props) {
    const [eventTimePlay, setEventTimePlay] = useState(false);
    const [eventCounter, setEventCounter] = useState(0);
    const [schedule, setSchedule] = useState(null); 
    const [breakGraphic, setBreakGraphic] = useState({ toShow: breakImage1, used: [] });
    const screen1 = useFullScreenHandle();
    const { firebase } = useContext(FirebaseNewContext);
    const { defaultSelectedCompetition } = useContext(UserDataContext);
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition);

    useEffect(() => {
        const fetchSchedule = async () => {
            console.log("here");
            const fetchedSchedule = await firebase.getSchedule(selectedCompetition);
            setSchedule(fetchedSchedule != null ? fetchedSchedule : []); 
        };
        fetchSchedule();
    }, [firebase, selectedCompetition]);

    const selectBreakGraphic = (graphics, graphicsUsed) => {
        console.log(graphicsUsed);
        const availableGraphics = graphics.filter((_, k) => !graphicsUsed.includes(k));
        return availableGraphics[0] || graphics[0];
    };

    const reportChange = useCallback((state, handle) => {
        if (handle === screen1 && !state) {
            setEventTimePlay(false);
        }
    }, [screen1]);

    const breakGraphics = [breakImage1, breakImage2, breakImage3, breakImage4];

    useEffect(() => {
        if (schedule && schedule.length > 0 && schedule[eventCounter]) {
            const currentEvent = schedule[eventCounter];
            if (['Break', 'Lunch'].includes(currentEvent.type)) {
                const selectedGraphic = selectBreakGraphic(breakGraphics, breakGraphic.used);
                
                let used = [];
                if (breakGraphic.used.length < breakGraphics.length - 1) {
                    used = [...breakGraphic.used, breakGraphics.indexOf(selectedGraphic)]
                }
                
                setBreakGraphic({
                    toShow: selectedGraphic,
                    used,
                });
            }
        }
    }, [eventCounter, schedule]);

    const handleSnakeEventSelection = (index) => {
        setEventCounter(index);
        screen1.enter().then(() => {
            setEventTimePlay(true);
        }).catch((error) => {
            console.error("Error entering full-screen mode:", error);
        });
    };

    const setOuterEvents = (events) => {
        setSchedule([...events]);
        firebase.insertSchedule(selectedCompetition, events);
    };

    const handleNextEvent = useCallback(() => {
        let nextEvent = eventCounter + 1;
        if (schedule[nextEvent]) {
            setEventCounter((prevCounter) => {
                if (prevCounter === eventCounter) {
                    return nextEvent; // Update only if previous counter matches current
                }
                return prevCounter;
            });
        } else {
            screen1.exit();
        }
    }, [eventCounter, schedule, screen1]);

    if (schedule === null) {
        return <Typography variant='h5'>Loading schedule...</Typography>;
    }

    return (
        <>
        <Box sx={{ padding: '15px' }}>
            <Typography variant='h3' sx={{ marginBottom: "18px"}}>Competition Slideshow Timer Page</Typography>
            <CompetitionDropdown
            value={selectedCompetition}
            onChange={setSelectedCompetition}
            sx={{ marginBottom: '10px' }}
            />

            <br />

            <SnakeEventManager 
                initialEvents={schedule} 
                presentEvent={handleSnakeEventSelection} 
                setOuterEvents={setOuterEvents}
                competition={selectedCompetition}
            />
            <Grid2 sx={{ display: screen1.active ? "block" : "none" }}>
                <FullScreen handle={screen1} onChange={reportChange}>
                    <Grid2 container sx={{ height: '100vh' }}>
                        <Grid2 sx={{ textAlign: 'center', width: '100%' }}>
                            {eventTimePlay && (
                                <Grid2
                                    container
                                    sx={{
                                        backgroundImage: schedule[eventCounter].carousel 
                                            ? `url(${['Break', 'Lunch'].includes(schedule[eventCounter].type) ? breakGraphic.toShow : newGeneralGraphic2024})`
                                            : undefined, 
                                        backgroundPosition: 'center',
                                        backgroundColor: 'white',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        width: '100%',
                                        height: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    {!schedule[eventCounter].carousel && (
                                        <AsyncImage
                                            getImageSrc={async () => {
                                                if (schedule[eventCounter]?.image === "default") {
                                                    return newGeneralGraphic2024;
                                                } else {
                                                    try {
                                                        const imageSrc = schedule[eventCounter]?.image !== undefined 
                                                            ? await firebase.getImage(selectedCompetition, schedule[eventCounter]?.image) 
                                                            : undefined; 
                                                        return imageSrc || newGeneralGraphic2024;
                                                    } catch (error) {
                                                        console.error("Failed to get image:", error);
                                                        return newGeneralGraphic2024;
                                                    }
                                                }
                                            }}
                                            width="auto"
                                            height="100%"
                                            alt="Event Image"
                                            imageStyle={{ position: "absolute", top: schedule[eventCounter]?.image === 'default' ? undefined : 0, bottom: schedule[eventCounter]?.image === 'default' ? 0 : undefined, left: 0 }}
                                        />
                                    )}
                                    <Grid2 item xs={12}>
                                        <VisualTimer
                                            whereAreWe={schedule[eventCounter].type}
                                            minutes={schedule[eventCounter].duration}
                                            restartKey={eventCounter}
                                            onFinished={handleNextEvent}
                                            suffix={schedule[eventCounter].name}
                                        />
                                    </Grid2>
                                </Grid2>
                            )}
                        </Grid2>
                    </Grid2>
                </FullScreen>
            </Grid2>
        </Box>
        </>
    );
}

export default SlideShowTimerPage;

