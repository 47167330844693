import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete, FormHelperText, InputLabel, TextField } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

const TeamDropdown = (props) => {
    const {
        value,
        onChange,
        readonly,
        teamsMapValues
    } = props

    return (
        <Grid2>
            <FormControl className='scoring-form-div' variant='standard'
                sx={{ minWidth: '200px' }}>
                <InputLabel id="demo-simple-select-helper-label">Team</InputLabel>

                <Select
                    name="teamUid"
                    value={value}
                    onChange={(e) => onChange(e, teamsMapValues[e.target.value] || {})}
                    disabled={readonly}
                >
                    <MenuItem value={''}>Team</MenuItem>
                    {Object.keys(teamsMapValues).map(tUid => {
                        return (
                            <MenuItem
                                key={tUid}
                                value={tUid}
                            >
                                {`${teamsMapValues[tUid]['teamNumber']} - ${teamsMapValues[tUid]['teamName']}`}
                            </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        </Grid2>
    )
}

const MultiSearchSelectTeamWrapper = (props) => {
    // NOTE - this is because we were at first only storing an array of UIDs to associate
    //  with the EventForm
    const {
        selectedTeams = [],
        teamsMapValues
    } = props
    const newValues = Object.keys(teamsMapValues).reduce((acc, v) => {
        if (selectedTeams.includes(v)) {
            acc = acc.concat({
                ...teamsMapValues[v],
                ...{
                    uid: v
                }
            })
        }
        return acc
    }, [])

    return (
        <MultiSearchSelectTeam
            {...props}
            value={newValues}
        />
    )
}

const MultiSearchSelectTeam = (props) => {
    const {
        value,
        onChange,
        readonly,
        teamsMapValues = {}
    } = props

    const filterMethod = (options, { inputValue }) => {
        return options.filter(item => {
            if(item.teamName == undefined) {
                return;
            }

            return item.teamName.toLowerCase().includes(inputValue)
                || item.teamNumber.toString().includes(inputValue)
        })
    }
    const teamsList = Object.keys(teamsMapValues).map(v => {
        return { ...teamsMapValues[v], uid: v }
    })

    return (
        <>
            <Autocomplete
                fullWidth
                readOnly={readonly}
                multiple
                filterOptions={filterMethod}
                getOptionLabel={(option) => `${option.teamNumber} - ${option.teamName}`}
                options={teamsList}
                renderInput={(params) => <TextField {...params} label="Teams" />}
                isOptionEqualToValue={(option, value) => {
                    return option.uid === value.uid
                }}
                value={value}
                onChange={(event, value, reason, details) => {
                    onChange(value)
                }}
            />
        </>
    )
}

export {
    TeamDropdown,
    MultiSearchSelectTeam,
    MultiSearchSelectTeamWrapper
}
