import React, { useEffect, useState, useMemo, useContext } from 'react';
import * as routes from '../../routes/routes';
import { UserDataContext } from '../../firebase/UserDataContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Stack, Typography, Checkbox, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import SponsorRegistrationForm from '../../components/registration/SponsorRegistrationForm';
import { REGISTRATION_STATES } from '../../components/misc/Utils';
import * as ROUTES from '../../routes/routes';

function alternateReady(status) {

    if(status == REGISTRATION_STATES.PUSHED || status == REGISTRATION_STATES.READY_TO_PUSH) {
        return REGISTRATION_STATES.SUBMITTED;
    }

    return REGISTRATION_STATES.READY_TO_PUSH;
}

const registrationRows = (handleCheckboxChange, handleDeleteClick, competitionLock) => [
    { field: 'col1', headerName: 'Team Name', width: 150 },
    { field: 'col2', headerName: 'Sponsorship', width: 120 },
    { field: 'col3', headerName: 'Organization', width: 120 },
    { field: 'col4', headerName: 'Category', width: 150 },
    { field: 'col5', headerName: 'Status', width: 150 },
    { field: 'col6', headerName: 'Ready to Push?', width: 200, renderCell: (params) => {
        return (
            <Checkbox
                checked={[REGISTRATION_STATES.READY_TO_PUSH, REGISTRATION_STATES.PUSHED].includes(params.value.status)}
                disabled={[REGISTRATION_STATES.PUSHED, REGISTRATION_STATES.NOT_PUSHED].includes(params.value.status)}
                onChange={() => handleCheckboxChange(params.row.id, alternateReady(params.value.status))}
            />
        );
    }},
    {
        field: 'col7', headerName: 'Actions', width: 300, getApplyQuickFilterFn: () => null,
        renderCell: (params) => {
            return (
                <>
                <Button component={Link} to={
                        ROUTES.routeReplace(ROUTES.SPONSOR_REGISTRATION_EDIT, { editSponsor: params.value.uid, competition: params.value.selectedCompetition })
                    } 
                    target="_blank" rel="noopener noreferrer" disabled={params.value.disabled}>Edit</Button>
                <Button component={Link} to={
                        ROUTES.routeReplace(ROUTES.SPONSOR_REGISTRATION_VIEW, { sponsorUid: params.value.uid, competition: params.value.selectedCompetition })
                    } 
                    target="_blank" rel="noopener noreferrer" disabled={competitionLock}>View</Button>
                <Button color="error" disabled={params.value.disabled} onClick={() => handleDeleteClick(params.value.sponsor, params.value.uid)}>Delete</Button>
                </>
            )
        }
    },
];

function WaitroomSponsor(props) {
    const location = useLocation();
    const params = useParams();
    const { globalSettings: { competitions } } = useContext(GlobalSettingsContext);
    const { globalSettings } = useContext(GlobalSettingsContext);
    const { firebase } = useContext(FirebaseNewContext);
    const { defaultSelectedCompetition } = useContext(UserDataContext);
    const [selectedCompetition, setSelectedCompetition] = useState(defaultSelectedCompetition);
    const [sponsors, setSponsors] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState({ open: false, sponsor: null });
    const [pushed, setPushed] = useState(false);

    // Fetch sponsors in useEffect
    useEffect(() => {
        const fetchSponsors = async () => {
            const sponsorList = await firebase.getSponsorRegistrations(selectedCompetition);
            setSponsors(sponsorList);
            setPushed(await firebase.getSponsorRegistrationPushed(selectedCompetition));
        };
        if (selectedCompetition) {
            fetchSponsors().catch(console.error);
        }
    }, [selectedCompetition]);

    // Handle checkbox change sponsor
    const handleCheckboxChange = async (sponsorId, newReadyStatus) => {
        const sponsor = sponsors[sponsorId];
        const updatedSponsor = { ...sponsor, status: newReadyStatus };

        // Update in Firebase
        await firebase.patchSponsorRegistration(selectedCompetition, sponsorId, updatedSponsor);

        // Update the local state
        setSponsors((prevSponsors) => ({
            ...prevSponsors,
            [sponsorId]: updatedSponsor
        }));
    };

    const handleDeleteClick = (sponsor, uid) => {
        console.log(sponsor);
        sponsor.uid = uid;
        setConfirmDelete({ open: true, sponsor })
    }

    const handleCloseDialog = () => {
        setConfirmDelete({ open: false, team: null })
    }

    const deleteRegistrationSponsor = async (sponsor) => {
        console.log(sponsor);
        await firebase.deleteSponsorRegistration(selectedCompetition, sponsor.uid);
        window.location.reload(false);
    }

    const pushSponsors = async() => {
        Object.keys(sponsors).forEach(async (key) => {
            const sponsor = sponsors[key];
            
            if(sponsor.status == REGISTRATION_STATES.READY_TO_PUSH) {
                await firebase.patchSponsorRegistration(selectedCompetition, key, {...sponsor, status: REGISTRATION_STATES.PUSHED});
            } else if (sponsor.status == REGISTRATION_STATES.PUSHED) {
                console.log("An sponsor was already pushed, this shouldnt be allowed");
            } else {
                await firebase.patchSponsorRegistration(selectedCompetition, key, {...sponsor, status: REGISTRATION_STATES.NOT_PUSHED});
            }
        });

        await firebase.setSponsorRegistrationPushed(selectedCompetition, true);
        window.location.reload();
    }

    const rollbackSponsors = async() => {
        Object.keys(sponsors).forEach(async (key) => {
            const sponsor = sponsors[key];
            
            if(sponsor.status == REGISTRATION_STATES.NOT_PUSHED) {
                await firebase.patchSponsorRegistration(selectedCompetition, key, {...sponsor, status: REGISTRATION_STATES.SUBMITTED});
            } else if (sponsor.status == REGISTRATION_STATES.SUBMITTED) {
                console.log("An sponsor was already pushed, this shouldnt be allowed");
            } else {
                await firebase.patchSponsorRegistration(selectedCompetition, key, {...sponsor, status: REGISTRATION_STATES.READY_TO_PUSH});
            }
        });

        await firebase.setSponsorRegistrationPushed(selectedCompetition, false);
        window.location.reload();
    }

    const rows = useMemo(() => {
        if (!sponsors) return [];

        return Object.keys(sponsors).map((v) => {
            const sponsor = sponsors[v];
            return {
                id: v,
                col1: sponsor.teamName || '',
                col2: sponsor.sponsorship || '',
                col3: sponsor.organization || '',
                col4: sponsor.teamCategory || '',
                col5: sponsor.status || 'SUBMITTED',
                col6: { 
                    status: sponsor.status || '', 
                    callback: async () => handleCheckboxChange(v, alternateReady(sponsor.status))
                },
                col7: { 
                    uid: v, 
                    selectedCompetition, 
                    sponsor: sponsor, 
                    deleteCallback: () => handleDeleteClick(sponsor, v), 
                    disabled: (globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[selectedCompetition]) || [REGISTRATION_STATES.NOT_PUSHED, REGISTRATION_STATES.PUSHED].includes(sponsor.status) 
                }
            };
        });
    }, [sponsors]);

    return (
        <Grid2>
            {typeof selectedCompetition !== 'string' && <h3>Please Select A Competition</h3>}

            {!pushed &&
                <Button onClick={() => pushSponsors()}>Push All</Button>
            }
            {pushed &&
                <Button onClick={() => rollbackSponsors()}>Rollback</Button>
            }

            {location.pathname === routes.WAITROOM_PAGE && typeof selectedCompetition === 'string' && (
                <Grid2 flexDirection={'column'}>
                    <DataGrid
                        sx={{ marginBottom: '25px' }}
                        autoHeight
                        rows={rows || []}
                        columns={registrationRows(handleCheckboxChange, handleDeleteClick, globalSettings?.lockCompetition[selectedCompetition] || false)}
                        slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <Typography variant="body">For the {competitions[selectedCompetition]} competition</Typography>
                                    <Typography variant="body">No Sponsors Have Been Created</Typography>
                                </Stack>
                            ),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Grid2>
            )}

            {Object.keys(params).length !== 0 && (
                <SponsorRegistrationForm {...props} team={`${params.edit}`} competition={selectedCompetition} />
            )}
            {location.pathname.includes(routes.EVENT_REGISTRATION_PAGE) && <SponsorRegistrationForm {...props} />}

             <Dialog
                open={confirmDelete.open}
                onClose={handleCloseDialog}
             >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this team?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">No</Button>
                    <Button onClick={async () => await deleteRegistrationSponsor(confirmDelete.sponsor)} color="error">Yes</Button>
                </DialogActions>
            </Dialog>
        </Grid2>
    );
}

export default WaitroomSponsor;

