import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";

function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{ location, navigate, params }}
            />
        );
    }

    return ComponentWithRouterProp;
}
// im sorry for the sin im about to commit here
function sortBy(toSort, key, sortDirection) {
    return [...toSort].sort((a, b) => {
        // NOTE - this is hacky but fine for now
        if (key === 'number') {
            return sortDirection === 'ASC' ? a.number - b.number : b.number - a.number
        }

        if (!('score' in a)) {
            return 1;
        } else if (!('score' in b)) {
            return -1;
        }
        return sortDirection === 'ASC' ? a.score[key] - b.score[key] : b.score[key] - a.score[key]
    });
}
function sortEventArrayBy(teamObj, key, sortDirection) {
    const newObject = { ...teamObj }
    newObject.events = sortBy(newObject.events, key, sortDirection);
    return newObject;

}
function sortTeamArrayBy(eventObj, key, sortDirection) {
    const newObject = { ...eventObj }
    newObject.teams = sortBy(newObject.teams, key, sortDirection);
    return newObject;
}

function generateNumber() {
    return Math.floor(Math.random() * 200) + 1;
}
function numberSuggester(haystack, excludeList = []) {
    const needle = generateNumber()
    if (excludeList.includes(needle) || haystack.includes(needle)) {
        return numberSuggester(haystack, excludeList.concat(needle))
    }
    return needle;
}

// NOTE - need to mutate, nothing is capturing as a variable
function calculatePlacements(standings, accessor, saveKey) {
    // TODO - change this to ordinal / standard / modified / dense
    rankings(standings, accessor).forEach(v => {
        v[saveKey] = v['standard']
    })
    return standings
}
function calculatePlacementsAsMap(standings, accessor, saveKey) {
    // TODO - change this to ordinal / standard / modified / dense
    Object.keys(rankingsAsMap(standings, accessor)).forEach(v => {
        if (standings[v]) {
            standings[v][saveKey] = standings[v]['standard']

        }
    })
    return standings
}
function rankingsAsMap(standings, accessor) {
    const sortedStandings = Object.values(standings).sort(function (a, b) {
        return a[accessor] - b[accessor]
    })
    const scores = sortedStandings.map(v => v[accessor])
    const reversed = scores.slice(0).reverse()
    const unique = scores.filter((x, i) => scores.indexOf(x) === i);
    const result = {};
    for (const [index, t] of sortedStandings.entries()) {
        t['ordinal'] = index + 1;
        t['standard'] = scores.indexOf(t[accessor]) + 1
        t['modified'] = reversed.length - reversed.indexOf(t[accessor])
        t['dense'] = unique.indexOf(t[accessor]) + 1
        t['fractional'] = (n => (
            (scores.indexOf(n) + 1) +
            (reversed.length - reversed.indexOf(n))
        ) / 2)(t[accessor])
        result[t.uid] = t;
    }
    return result;
}

function rankings(standings, accessor) {
    standings.sort(function (a, b) {
        return a[accessor] - b[accessor]
    })
    const scores = standings.map(v => v[accessor])
    const reversed = scores.slice(0).reverse()
    const unique = scores.filter((x, i) => scores.indexOf(x) === i);
    for (const [index, t] of standings.entries()) {
        //if (t.event && t.event.number == '12') {
        //    t['standard'] = 0
        //    continue;
        //}
        t['ordinal'] = index + 1;
        t['standard'] = scores.indexOf(t[accessor]) + 1
        t['modified'] = reversed.length - reversed.indexOf(t[accessor])
        t['dense'] = unique.indexOf(t[accessor]) + 1
        t['fractional'] = (n => (
            (scores.indexOf(n) + 1) +
            (reversed.length - reversed.indexOf(n))
        ) / 2)(t[accessor])
    }
    return standings
}

function scoreSheetCalculation(minutesIngest, secondsIngest, penalties, quiz) {
    const minutes = minutesIngest === '' ? 0 : minutesIngest
    const seconds = secondsIngest === '' ? 0 : secondsIngest

    let time = parseInt(minutes) * 60 + parseInt(seconds)
    for (const key in penalties) {
        if (penalties.hasOwnProperty(key)) {
            const penalty = penalties[key];
            if (penalty.checked === true) {
                let add = parseInt(penalty.value)
                if ('format' in penalty && penalty.format === 'MINUTES') {
                    add = add * 60
                }
                const multiplier = penalty.multiplier ? parseInt(penalty.multiplier) : 1
                time += (add * multiplier)
            }
        }
    }

    time += quiz * 30

    time = Math.max(time, 0)
    return Math.min(time, 1500)
}
function getFinalScoresByTeam(scoresMap, teamsMap, eventsMap) {
    let allEvents = {}
    const totalObject = Object.keys(scoresMap).reduce((acc, v) => {

        const eventUid = scoresMap[v]['eventUid']
        const event = eventsMap[eventUid]
        if (event['excludeEvent']) {
            return acc
        }

        const teamUid = scoresMap[v]['teamUid']
        if (!(teamUid in acc)) {
            acc[teamUid] = {
                team: teamsMap[teamUid],
                scores: [],
                finalScore: 0
            }
        }
        //if (scoresMap[v]['team']['teamNumber'] == '5') {
        //    allEvents[scoresMap[v]['event']['number']] = scoresMap[v]['event']['number'] in allEvents ? allEvents[scoresMap[v]['event']['number']] +scoresMap[v]['finalScore']: scoresMap[v]['finalScore']
        //    //console.log('team 23 score', scoresMap[v])
        //    //console.log('the score we are on', scoresMap[v]['finalScore'], 'event number ->',scoresMap[v]['event']['number'] )
        //    ////console.log('about to enter', parseInt(acc[teamUid]['finalScore']) + parseInt(scoresMap[v]['finalScore']))
        //    //console.log('team 23 calculated score ', acc[teamUid]['finalScore'] + parseInt(scoresMap[v]['finalScore']))
        //    console.log('team 5 calculated score ',parseInt(acc[teamUid]['finalScore']) + parseInt(scoresMap[v]['finalScore']))
        //}
        acc[teamUid]['finalScore'] = parseInt(acc[teamUid]['finalScore']) + parseInt(scoresMap[v]['finalScore'])
        acc[teamUid]['scores'] = acc[teamUid]['scores'].concat(scoresMap[v])

        return acc
    }, {})
    console.log('all our event numbers',allEvents)

    return totalObject
}
function groupedEventsScores(scoresMap, eventsMap) {
    const totalObject = Object.keys(scoresMap).reduce((acc, v) => {
        const eventUid = scoresMap[v]['eventUid']
        if (eventsMap[eventUid]['excludeEvent']) {
            return acc
        }

        if (!(eventUid in acc)) {
            acc[eventUid] = {
                event: eventsMap[eventUid],
                scores: []
            }
        }
        acc[eventUid]['scores'] = acc[eventUid]['scores'].concat({...scoresMap[v], scoreUid: v})

        return acc
    }, {})

    return totalObject
}

function avgByCategoryInEvent(eventScores, categoriesMap) {
    const totalObject = Object.keys(categoriesMap).reduce((accCategory, c) => {
        accCategory = Object.keys(eventScores).reduce((accEvent, e) => {
            accEvent[e] = eventScores[e]
            if (!('categoriesAvg' in accEvent[e])) {
                accEvent[e]['categoriesAvg'] = {}
            }
            if (!(c in accEvent[e]['categoriesAvg'])) {
                accEvent[e]['categoriesAvg'][c] = 0
            }
            const scoresMatchingCategory = eventScores[e]['scores'].filter(x => x.team.category == c)
            if (scoresMatchingCategory.length == 0) {
                return accEvent
            }
            const cateogryAverage = Math.round((scoresMatchingCategory.reduce((total, currentScore) => {
                const finalScore = !currentScore.finalScore || currentScore.finalScore == '0' ? 0 : currentScore.finalScore
                total = finalScore + total

                return total
            }, 0) / scoresMatchingCategory.length) * 10) / 10

            accEvent[e]['categoriesAvg'][c] = cateogryAverage

            return accEvent
        }, {})
        return accCategory
    }, {})
    return totalObject
}

function avgByDivisionInEvent(eventScores, divisionsMap) {
    const totalObject = Object.keys(divisionsMap).reduce((accDivision, d) => {
        accDivision = Object.keys(eventScores).reduce((accEvent, e) => {
            accEvent[e] = eventScores[e]
            if (!('divisionsAvg' in accEvent[e])) {
                accEvent[e]['divisionsAvg'] = {}
            }
            if (!(d in accEvent[e]['divisionsAvg'])) {
                accEvent[e]['divisionsAvg'][d] = 0
            }
            const scoresMatchingCategory = eventScores[e]['scores'].filter(x => x.team.division == d)
            if (scoresMatchingCategory.length == 0) {
                return accEvent
            }
            const divisionAverage = Math.round((scoresMatchingCategory.reduce((total, currentScore) => {
                total = currentScore.finalScore + total

                return total
            }, 0) / scoresMatchingCategory.length) * 10) / 10

            accEvent[e]['divisionsAvg'][d] = divisionAverage

            return accEvent
        }, {})
        return accDivision
    }, {})
    return totalObject
}
function avgOverallInEvent(eventScores) {
    const totalObject = Object.keys(eventScores).reduce((accEvent, e) => {
        accEvent[e] = eventScores[e]
        if (!('avgOverall' in accEvent[e])) {
            accEvent[e]['avgOverall'] = 0
        }
        const total = Math.round((accEvent[e]['scores'].reduce((accScores, s) => {
            const finalScore = !s.finalScore || s.finalScore == '0' ? 0 : s.finalScore
            accScores = finalScore + accScores
            return accScores
        }, 0) / accEvent[e]['scores'].length) * 10) / 10
        accEvent[e]['avgOverall'] = total
        return accEvent
    }, {})
    return totalObject
}
function getOverallTopInEvent(eventScores) {
    const totalObject = Object.keys(eventScores).reduce((accEvent, e) => {
        accEvent[e] = eventScores[e]
        if (!('overallTopInEvent' in accEvent[e])) {
            accEvent[e]['overallTopInEvent'] = 0
        }
        const bestScore = Math.min(...eventScores[e]['scores'].map(v => v.finalScore))
        accEvent[e]['overallTopInEvent'] = bestScore

        return accEvent
    }, {})
    return totalObject
}

function getCategoryTopOverallInEvent(eventScores, categories) {
    const totalObject = Object.keys(categories).reduce((accCategory, c) => {
        accCategory = Object.keys(eventScores).reduce((accEvent, e) => {
            accEvent[e] = eventScores[e]
            if (!('categoryTop' in accEvent[e])) {
                accEvent[e]['categoryTop'] = {}
            }
            if (!(c in accEvent[e]['categoriesAvg'])) {
                accEvent[e]['categoryTop'][c] = 0
            }
            const bestScoreInCategory = Math.min(...eventScores[e]['scores'].filter(x => x.team.category == c).map(x => x.finalScore))
            accEvent[e]['categoryTop'][c] = Number.isFinite(bestScoreInCategory) ? bestScoreInCategory : -1

            return accEvent
        }, {})
        return accCategory
    }, {})

    return totalObject
}

// NOTE - allowing for mutation
function getEventCategoryPlaceInEvent(eventScores, categories) {
    Object.keys(categories).reduce((accCategory, c) => {
        accCategory = Object.keys(eventScores).reduce((accEvent, e) => {
            const toAnalyze = eventScores[e]['scores']
                .filter(x => x.team.category == c)
            //.filter(x => x.finalScore != 0)
            calculatePlacements(toAnalyze, 'finalScore', 'categoryPlace')
        }, {})
    }, {})
}

// NOTE - allowing for mutation
function getPlacementOverallInEvent(eventScores) {
    Object.keys(eventScores).reduce((accEvent, e) => {
        accEvent[e] = eventScores[e]
        const toAnalyze = eventScores[e]['scores']
        //.filter(x => x.finalScore != 0)
        //if (e == '-NT-i-KEheK6OZeB8SHv') {
        //    console.log('this is our event scores', toAnalyze)

        //}
        calculatePlacements(toAnalyze, 'finalScore', 'overallPlace')
        return accEvent
    }, {})
}

// NOTE - allowing for mutation
function getPlacementOverAllEventsAndCategories(eventScores, teamsMap, categoriesMap) {
    Object.keys(eventScores).forEach(e => {
        const event = eventScores[e]
        Object.keys(teamsMap).reduce((accTeam, t) => {
            accTeam[t] = teamsMap[t]
            accTeam[t]['uid'] = t
            if (!('finalScore' in accTeam[t])) {
                accTeam[t]['finalScore'] = 0
            }
            event.scores.forEach(s => {
                if (s.teamUid == t) {
                    //if (s.team.teamNumber == '5') {
                    //    console.log('this is our final score', s.finalScore, accTeam[t]['finalScore'])
                    //}
                    const finalScoreKeyAcc = accTeam[t]['finalScore']
                    const finalScore = !s.finalScore || s.finalScore == '0' ? 0 : s.finalScore
                    accTeam[t]['finalScore'] = finalScoreKeyAcc + finalScore
                }
            })

            return accTeam
        }, {})
    });
    //const toAnalyze = Object.keys(teamsMap).reduce((acc, v) => {
    //    if (teamsMap[v].finalScore != 0) {
    //        acc[v] = teamsMap[v]
    //    }
    //    return acc
    //}, {})
    calculatePlacementsAsMap(teamsMap, 'finalScore', 'place')
    Object.keys(categoriesMap).reduce((accCategory, c) => {
        let matchingTeams = {}
        Object.keys(teamsMap).reduce((accTeam, t) => {
            if (teamsMap[t]['category'] == c && teamsMap[t]['finalScore'] != 0) {
                matchingTeams[t] = teamsMap[t]
            }
        }, {})
        calculatePlacementsAsMap(matchingTeams, 'finalScore', 'categoryPlace')

    }, {})
}

const SCORESHEET_STATES = {
    CREATED: 'CREATED',
    SUBMITTED: 'SUBMITTED',
    EDIT_REQUESTED: 'EDIT_REQUESTED',
    APPROVED: 'APPROVED',
    LOCKED: 'LOCKED',
}
const ROLES = {
    ADMIN: "ADMIN",
    SCORECOMMITTEE: "SCORECOMMITTEE",
    JUDGE: "JUDGE",
    TEAM: "TEAM"
}

const REGISTRATION_STATES = {
    SUBMITTED: "SUBMITTED",
    READY_TO_PUSH: "READY_TO_PUSH",
    PUSHED: "PUSHED",
    NOT_PUSHED: "NOT_PUSHED"
}

const determineScoresheetTableChipColor = (status) => {
    switch (status) {
        case SCORESHEET_STATES.CREATED:
            return 'primary' // BLUE
        case SCORESHEET_STATES.SUBMITTED:
            return 'secondary' // PINK
        case SCORESHEET_STATES.APPROVED:
            return 'success' // GREEN
        case SCORESHEET_STATES.LOCKED:
            return ''
        case SCORESHEET_STATES.EDIT_REQUESTED:
            return 'warning' // YELLOW
        default:
            return ''
    }
}

export {
    withRouter,
    sortEventArrayBy,
    sortTeamArrayBy,
    numberSuggester,
    calculatePlacements,
    calculatePlacementsAsMap,
    scoreSheetCalculation,
    getFinalScoresByTeam,
    groupedEventsScores,
    avgByCategoryInEvent,
    avgByDivisionInEvent,
    avgOverallInEvent,
    getOverallTopInEvent,
    getCategoryTopOverallInEvent,
    getEventCategoryPlaceInEvent,
    getPlacementOverallInEvent,
    getPlacementOverAllEventsAndCategories,
    SCORESHEET_STATES,
    ROLES,
    REGISTRATION_STATES,
    determineScoresheetTableChipColor
}
