import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useReducer, useEffect, useState, useCallback, useContext } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import FormErrorMessages from '../forms/Messages/FormErrorMessages';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';



function sponsorFormInitialState(values) {
    return {
        firstName: values.firstName || '',
        lastName: values.lastName || '',
        title: values.title || '',
        company: values.company || '',
        email: values.email || '',
        billingAddressLine1: values.billingAddressLine1 || '',
        billingAddressLine2: values.billingAddressLine2 || '',
        billingAddressCity: values.billingAddressCity || '',
        billingAddressState: values.billingAddressState || '',
        billingAddressZipCode: values.billingAddressZipCode || '',
        billingAddressCountry: values.billingAddressCountry || '',
        sponsorship: values.sponsorship || '',
        comment: values.comment || '',
        errorMessages: []
    }
}
function sponsorFormReducer(state, action) {
    switch (action.type) {
        case 'value_changed':
            return {
                ...state,
                [action.keyName]: action.keyValue
            }
        case 'values_changed':
            return {
                ...state,
                ...action.values
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages

            }
        default:
            break;
    }
}

// TODO - break up user form so it is just creating a user
//      not assigning them to a team yet
// TODO - create team form so you can assign multiple users to a team
function SponsorRegistrationForm(props) {
    const [state, dispatch] = useReducer(sponsorFormReducer, props, sponsorFormInitialState)
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings: { defaultCompetition } } = useContext(GlobalSettingsContext)


    useEffect(() => {
        // if we allow for editing for
        const fetch = async () => {}

        fetch()
            .catch(console.error)

    }, [])
    const isValid = (state) => {
        const {
            firstName,
            lastName,
            title,
            company,
            email,
            billingAddressLine1,
            sponsorship,
            comment
        } = state;
        let validCheck = { result: true, errorMessages: [] }
        if (firstName === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('First Name cannot be blank');
        }
        if (lastName === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Last Name cannot be blank');
        }
        if (title === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Title cannot be blank');
        }
        if (company === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Company cannot be blank');
        }
        if (email === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Emailcannot be blank');
        }
        if (billingAddressLine1 === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Billing Address Line 1 cannot be blank');
        }
        if (sponsorship === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Sponsorship cannot be blank');
        }
        if (comment === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Comment cannot be blank');
        }

        return validCheck;
    }


    const submitButtonPressed = useCallback((e) => {
        e.preventDefault()
        const isValidCheck = isValid(state)
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: isValidCheck.errorMessages
        })
        createSponsorRegistration(isValidCheck)

        //patchSponsorshipRegistration

    }, [state])

    const createSponsorRegistration = (isValidCheck) => {
        if (isValidCheck.result) {
            const {
                firstName,
                lastName,
                title,
                company,
                email,
                billingAddressLine1,
                billingAddressLine2,
                billingAddressCity,
                billingAddressState,
                billingAddressZipCode,
                billingAddressCountry,
                sponsorship,
                comment
            } = state
            const data = {
                firstName,
                lastName,
                title,
                company,
                email,
                billingAddressLine1,
                billingAddressLine2,
                billingAddressCity,
                billingAddressState,
                billingAddressZipCode,
                billingAddressCountry,
                sponsorship,
                comment
            }
            // TODO - we MUST make THIS a per competition EVENT PAGE
            // FOR NOW WE USE DEFAULT COMPETITION
            firebase.insertSponsorRegistration(props.competition, data)
        }
    }
    const patchSponsorshipRegistration = (isValidCheck) => {
        if (isValidCheck.result) {
            const {
                firstName,
                lastName,
                title,
                company,
                email,
                billingAddressLine1,
                billingAddressLine2,
                billingAddressCity,
                billingAddressState,
                billingAddressZipCode,
                billingAddressCountry,
                sponsorship,
                comment
            } = state
            const data = {
                firstName,
                lastName,
                title,
                company,
                email,
                billingAddressLine1,
                billingAddressLine2,
                billingAddressCity,
                billingAddressState,
                billingAddressZipCode,
                billingAddressCountry,
                sponsorship,
                comment
            }
            firebase.patchSponsorRegistration(defaultCompetition, data)
        }
    }

    return (
        <Grid2>
            <Typography variant='h4'>Sponsorship Form Application</Typography>
            <Grid2 container flexDirection={'column'} sx={{}}>
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.firstName}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'firstName'
                            })
                        }}
                        label="First Name"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.lastName}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'lastName'
                            })
                        }}
                        label="Last Name"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.title}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'title'
                            })
                        }}
                        label="Title"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.company}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'company'
                            })
                        }}
                        label="Company"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.email}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'email'
                            })
                        }}
                        label="Email"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.billingAddressLine1}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'billingAddressLine1'
                            })
                        }}
                        label="Line 1"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.billingAddressLine2}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'billingAddressLine2'
                            })
                        }}
                        label="Line 2"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.billingAddressCity}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'billingAddressCity'
                            })
                        }}
                        label="City"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.billingAddressState}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'billingAddressState'
                            })
                        }}
                        label="State"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.billingAddressZipCode}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'billingAddressZipCode'
                            })
                        }}
                        label="Zip Code"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.billingAddressCountry}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'billingAddressCountry'
                            })
                        }}
                        label="Country"
                    />
                <FormControl>
                    <InputLabel id="demo-simple-select-label">Sponsorship</InputLabel>
                    <Select name="role" value={state.sponsorship}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                            })
                        }}>
                        <MenuItem value="BRONZE">Bronze ($5,000)</MenuItem>
                        <MenuItem value="SILVER">Silver ($8,000)</MenuItem>
                        <MenuItem value="GOLD">Gold ($10,000)</MenuItem>
                        <MenuItem value="PLATINUM">Platinum ($50,000)</MenuItem>
                    </Select>
                </FormControl>
                    <TextField
                        sx={{ flex: 1 }}
                        value={state.comment}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'comment'
                            })
                        }}
                        label="Comment"
                    />
            </Grid2>
            <Button onClick={submitButtonPressed}>Submit</Button>
            <FormErrorMessages errorMessages={state.errorMessages} />
        </Grid2 >
    )
}
// TOOD - checkboxes for judges and teams as a component
export default SponsorRegistrationForm
