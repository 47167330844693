import React, { useState, useEffect } from 'react';

const AsyncImage = ({ getImageSrc, width, alt = '', imageStyle, ...props }) => {
    const [imageSrc, setImageSrc] = useState(null);
    const [error, setError] = useState(false);

    useEffect(() => {
        const loadImage = async () => {
            try {
                const src = await getImageSrc();
                const img = new Image();
                img.src = src;
                img.onload = () => setImageSrc(src);
                img.onerror = () => setError(true);
            } catch (e) {
                setError(true);
            }
        };

        loadImage();
    }, [getImageSrc]);

    if (error) {
        console.error("Failed to load image");
        return <div>Failed to load image</div>;
    }

    return (
        imageSrc ? 
        <img 
            src={imageSrc} 
            alt={alt} 
            width={width} 
            style={imageStyle}
            {...props} 
        /> 
        : 
        <div>Loading...</div>
    );
};

export default AsyncImage;


