import { initializeApp } from '@firebase/app';
import { equalTo, get as dbGet, orderByChild, query as dbQuery, ref, set, push, remove } from "firebase/database";
import { Timestamp } from '@firebase/firestore';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, signOut, updateEmail, updatePassword } from '@firebase/auth';
import { getDatabase } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, listAll } from "firebase/storage";
import { ROLES } from '../components/misc/Utils';


var config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
};

export default class FirebaseNew {
    constructor() {
        this.app = initializeApp(config);
        this.db = getDatabase(this.app)
        this.auth = getAuth(this.app)
        this.storage = getStorage(this.app)
        //this.db = getDatabase(app)
        //this.auth = getAuth(app)
        //this.user = null
        //onAuthStateChanged(this.auth, user => {
        //    if (user) {
        //        console.log('we got a user from the firebase helper')
        //        this.user = user
        //    } else {
        //        this.user = null
        //    }

        //})
    }
    doSignInWithEmailAndPassword = (email, password) => {
        return signInWithEmailAndPassword(this.auth, email, password);
    }
    doSignOut = () => signOut(this.auth)

    passwordForgot = async (email) => {
        let result = {
            success: false,
            reason: []
        };
        const CODES = {
            "auth/user-not-found": 'Invalid Email', // keeping this agnostic for security
            "auth/missing-email": 'Missing Email',
            "auth/invalid-email": 'Invalid Email'
        }
        try {
            await sendPasswordResetEmail(this.auth, email)
            result.success = true
        } catch (e) {
            result.reason = result.reason.concat(CODES[e.code] || 'An unkonwn error occurred please try again later')
        } finally {
            return result
        }
    }

    updateUserEmail = async (newEmail) => {
        let result = {
            success: false,
            reason: []
        };
        const CODES = {
            "auth/invalid-email": 'Email format is invalid'
        }
        try {
            await updateEmail(this.auth.currentUser, newEmail)
            result.success = true
            result.reason = result.reason.concat('Successfully Changed Email')
        } catch (e) {
            console.log(e)
            result.reason = result.reason.concat(CODES[e.code] || 'An unkonwn error occurred please try again later')
        } finally {
            return result
        }
    }

    updateUserPassword = async (newPassword) => {
        const CODES = {
            "auth/requires-recent-login": 'Please Login Again Before Attempting To Change Your Password'
        }
        let result = {
            success: false,
            reason: []
        };
        try {
            await updatePassword(this.auth.currentUser, newPassword)
            result.success = true
            result.reason = result.reason.concat('Successfully Changed Password')
        } catch (e) {
            result.reason = result.reason.concat(CODES[e.code] || 'An unkonwn error occurred please try again later')
        } finally {
            return result
        }
    }

    now = () => {
        return Timestamp.now().toMillis();

    }

    /*
        usersNew
            uid
            email
            username
            role
            createdAt
            updatedAt
    */
    User = (data) => {
        return {
            uid: data.uid || '',
            email: data.email || '',
            username: data.username || '',
            role: data.role || '',
            eventsNew: data.eventsNew || {},
            teamsNew: data.teamsNew || {},
            competitions: data.competitions || {},
            defaultCompetition: data.defaultCompetition || '',
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now(),
            invited: data.invited || false
        }

    }
    insertUser = async (uid, data) => {
        const toSave = this.User(data)
        const location = ref(this.db, `users/${uid}`)
        return set(location, toSave)
        //return this.db.ref(`users/${uid}`).set(toSave);
    }
    listenUser = (uid) => ref(this.db, `users/${uid}`);

    getUser = async (uid) => {
        const users = await dbGet(ref(this.db, `users/${uid}`))
        return users.val() || {}
    }
    listenUsersMap = () => {
        return ref(this.db, 'users')
    }
    getUsers = async () => {
        const users = await dbGet(ref(this.db, 'users'))
        return users.val() || {}

    }
    listenTeamUsersMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.TEAM)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)
    }
    listenAdminUsersMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.ADMIN)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)

    }
    listenScoreCommitteeUsersMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.SCORECOMMITTEE)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)
    }
    getTeamUserMap = async () => {
        const queryConstraints = [equalTo('TEAM')]
        const teamUsers = await dbGet(dbQuery(ref(this.db, 'users').orderByChild('role'), ...queryConstraints).equalTo('TEAM'))
        return teamUsers.val() || {}
    }
    patchUser = async (userKey, newUser, competition) => {
        const toSave = this.User(newUser)

        if(toSave.role === "JUDGE") {
            await this.removeUserFromTeams(userKey, toSave, competition);
            await this.addJudgeToEvents(userKey, toSave, competition);

            delete toSave.teamsNew;
        }

        if(toSave.role === "TEAM") {
            await this.removeJudgeFromEvents(userKey, toSave, competition);
            await this.addUserToTeams(userKey, toSave, competition);
        }
        console.log('this is what we need to save', toSave)
        const location = ref(this.db, `users/${userKey}`)
        return set(location, toSave)
    }

    // This will go into teamsNew and eventsNew and remove the uesr from any team 
    // within the competition
    removeUserFromTeams = async (userKey, user, competition) => {
        const teams = Object.values(user.teamsNew[competition] || {});

        teams.forEach((team) => {
            const teamsNewLocation = ref(this.db, `teamsNew/${competition}/${team}/teamMembers/${userKey}`);
            console.log(`teamsNew/${competition}/${team}/teamMembers/${userKey}`);
            remove(teamsNewLocation);
        });

        const events = await this.getEventsByCompetition(competition);
        console.log(events);
        const eventValues = Object.keys(events);

        eventValues.forEach(async (key) => {
            const associatedTeams = (await dbGet(ref(this.db, `eventsNew/${competition}/${key}/associatedTeams`))).val();

            associatedTeams?.forEach((team, teamIndex) => {
                if(teams.includes(team.uid)) {

                    team.teamMembers?.forEach((userId, userIndex) => {
                        if(userId === userKey) {
                            const eventsnewLocation = ref(this.db, `eventsNew/${competition}/${key}/associatedTeams/${teamIndex}/teamMembers/${userIndex}`);
                            remove(eventsnewLocation);
                        }
                    });
                }   
            });
        });
    }

    // This will go into teamsNew and eventsNew and add the user to any teams they are
    // About to join
    addUserToTeams = async (userKey, user, competition) => {
        const teams = Object.values(user.teamsNew[competition] || {});

        console.log(userKey);
        console.log(user);
        console.log(competition);
        console.log(teams);

        teams.forEach((team) => {
            const teamsNewLocation = ref(this.db, `teamsNew/${competition}/${team}/teamMembers/${userKey}`);
            console.log(`teamsNew/${competition}/${team}/teamMembers/${userKey}`);
            set(teamsNewLocation, user);
        });

        const events = await this.getEventsByCompetition(competition);
        console.log(events);
        const eventValues = Object.keys(events);

        eventValues.forEach(async (key) => {
            const associatedTeams = (await dbGet(ref(this.db, `eventsNew/${competition}/${key}/associatedTeams`))).val();
            console.log(associatedTeams);

            associatedTeams?.forEach((team, teamIndex) => {
                if(teams.includes(team.uid)) {
                    console.log("test", team);
                    if(team.teamMembers == undefined) {
                        team.teamMembers = [];
                    }
                    team.teamMembers.push(userKey);
                    const eventsnewLocation = ref(this.db, `eventsNew/${competition}/${key}/associatedTeams/${teamIndex}/teamMembers`);
                    console.log(team.teamMembers);
                    set(eventsnewLocation, team.teamMembers);
                }   
            });
        });
    }

    removeJudgeFromEvents = async (userKey, user, competition) => {
        const events = Object.values(user.eventsNew[competition] || {});

        events.forEach((event) => {
            const eventsNewLocation = ref(this.db, `eventsNew/${competition}/${event}/judgesNew/${userKey}`);
            console.log(`eventsNew/${competition}/${event}/judgesNew/${userKey}`);
            remove(eventsNewLocation);
        });
    }

    addJudgeToEvents = async (userKey, user, competition) => {
        const events = Object.values(user.eventsNew[competition] || {});

        events.forEach((event) => {
            const eventsNewLocation= ref(this.db, `eventsNew/${competition}/${event}/judgesNew/${userKey}`);
            console.log(`eventsNew/${competition}/${event}/judgesNew/${userKey}`);
            set(eventsNewLocation, user);
        });
    }
    /*
    TODO - maybe we do not need judges
        judgesNew
            uid - derived from users
            company
            email
            createdAt
            updatedAt
    */
    Judge = (data) => {
        return {
            uid: data.uid || '',
            company: data.company || '',
            email: data.email || '',
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now()
        }


    }
    insertJudge = () => {

    }
    listenJudgesMap = () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.JUDGE)]
        return dbQuery(ref(this.db, 'users'), ...queryConstraints)
    }
    getJudgesMap = async () => {
        const queryConstraints = [orderByChild('role'), equalTo(ROLES.JUDGE)]
        const judgeUsers = await dbGet(dbQuery(ref(this.db, 'users'), ...queryConstraints))
        return judgeUsers.val() || {}
    }
    getCompanies = () => {
        return new Promise((resolve) => {
            // TODO - to test
            dbGet(ref(this.db, 'judgesNew'))
                .then((result) => {
                    const companies = Object.keys(result || {}).reduce((acc, judge_key) => {
                        acc = acc.push(result[judge_key]['company'])
                        return acc
                    }, [])
                    resolve(companies)
                })
        })
    }
    patchJudge = async (judgeKey, newJudge) => {
        const toSave = this.Judge(newJudge)
        const location = ref(this.db, `judgesNew/${judgeKey}`)
        return set(location, toSave)
    }
    /*
        teamsNew
            uid
            category
            division
            teamNumber
            teamMembers: [uid - derived from users]
            createdAt
            updatedAt
     */
    Team = (data) => {
        return {
            category: data.category || '',
            division: data.division || '',
            teamNumber: data.teamNumber || '',
            teamName: data.teamName || '',
            teamMembers: data.teamMembers || [],
            associatedEvents: data.associatedEvents || [],
            sponsors: data.sponsors || [],
            checkedIn: data.checkedIn || false,
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now()
        }
    }
    insertTeam = async (team, competition) => {
        if (!competition) {
            return {}
        }
        const toSave = this.Team(team)
        console.log('we are about to save this team', toSave)
        console.log('this is our competition', competition)
        const location = ref(this.db, `teamsNew/${competition}`)
        return push(location, toSave)
    }
    listenTeamsMap = () => {
        return ref(this.db, 'teamsNew')
    }
    getTeamsMap = async () => {
        const teams = await dbGet(ref(this.db, 'teamsNew'))
        return teams.val() || {}
    }
    getTeam = async (teamKey, competition) => {
        if (!teamKey) {
            return false
        }
        const team = await dbGet(ref(this.db, `teamsNew/${competition}/${teamKey}`));
        return team.val() || {}
    }
    patchTeam = (teamKey, newTeam) => {
        const toSave = this.Team(newTeam)
        if (newTeam.associatedEvents) {
            newTeam.associatedEvents.forEach((v) => {
                if (v.associatedTeams) {
                    v.associatedTeams = []
                }
            })
            // TODO Remove associated events from each team
        }
        const location = ref(this.db, `teamsNew/${teamKey}`)
        return set(location, toSave)
    }
    deleteTeam = async (teamKey, competition, members) => {
        if (!teamKey || !competition) {
            return false
        }

        for (const mem of members) {
            const memberLocation = ref(this.db, `users/${mem}/teamsNew/${competition}`);

            try {
                const snapshot = await dbGet(memberLocation);

                if (snapshot.exists()) {
                    snapshot.forEach((child) => {
                        const key = child.key;
                        const val = child.val();

                        if (val === teamKey) { 
                            console.log("Found matching entry:");
                            console.log(`users/${mem}/teamsNew/${competition}/${key}`);
                            const memLocation = ref(this.db, `users/${mem}/teamsNew/${competition}/${key}`);
                            remove(memLocation);
                        }
                    });
                } else {
                    console.log(`No data found at users/${mem}/teamsNew/${competition}`);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }        

        const location = ref(this.db, `teamsNew/${competition}/${teamKey}`)
        remove(location)
    }
    /*
        eventsNew
            name
            number
            judgesNew
            isQuiz
            createdAt
            updatedAt
    */
    Event = (data) => {
        return {
            name: data.name || '',
            number: parseInt(data.number) || '',
            judgesNew: data.judgesNew || [],
            isQuiz: data.isQuiz ? true : false,
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now(),
            excludeEvent: data.excludeEvent || false,
            company: data.company || '',
            fullTitle: data.fullTitle || '',
            associatedTeams: data.associatedTeams || [],
        }

    }
    insertEvent = (event, competition) => {
        let toSave = this.Event(event)
        const location = ref(this.db, `eventsNew/${competition}`)
        return push(location, toSave)
    }
    patchEvent = async (eventKey, newEvent) => {
        let toSave = this.Event(newEvent)
        console.log('we are attempting to patch the event', toSave)
        if (newEvent.associatedTeams) {
            newEvent.associatedTeams.forEach((v) => {
                if (v.associatedEvents) {
                    v.associatedEvents = []
                }
            })
            // TODO Remove associated events from each team
        }
        const location = ref(this.db, `eventsNew/${eventKey}`)
        return set(location, toSave)
    }
    listenEventsMap = () => {
        return ref(this.db, `eventsNew`)
    }
    getEvents = async () => {
        const events = await dbGet(ref(this.db, `eventsNew`))
        return events.val() || {}
    }
    getEventsByCompetition = async (competition) => {
        const events = await dbGet(ref(this.db, `eventsNew/${competition}`))
        return events.val() || {}
    }
    getEvent = async (eventKey, competition) => {
        if (!eventKey) {
            return false
        }
        const event = await dbGet(ref(this.db, `eventsNew/${competition}/${eventKey}`));
        return event.val() || {}
    }
    deleteEvent = async (eventKey, competition) => {
        if (!eventKey || !competition) {
            return false
        }
        const location = ref(this.db, `eventsNew/${competition}/${eventKey}`)
        remove(location)
    }
    getQuizzes = async () => {
        const quizzes = await this.db.ref('eventsNew').orderByChild('isQuiz').equalTo(true).once('value')
        return quizzes.val() || {}
    }
    /*
        scoresNew
            uid
            comments
            finalScore
            delegateJudge*
            delegateJudgeUid
            event*
            eventUid
            judge*
            judgeUid
            team*
            teamUid
            time
            pastscore
            createdAt
            updatedAt
    */
    Score = (data) => {
        return {
            comments: data.comments || '',
            finalScore: data.finalScore || '',
            delegate: data.delegate || {},
            delegateUid: data.delegateUid || '',
            event: data.event || {},
            eventUid: data.eventUid || '',
            judge: data.judge || {},
            judgeUid: data.judgeUid || '',
            team: data.team || {},
            teamUid: data.teamUid || '',
            penalties: data.penalties || {},
            quizQuestionsMissed: data.quizQuestionsMissed || 0,
            time: data.time || 0,
            minutes: data.minutes || 0,
            seconds: data.seconds || 0,
            createdAt: data.createdAt || this.now(),
            updatedAt: this.now(),
            scoreContested: data.scoreContested || false,
            reasonForChange: data.reasonForChange || '',
            additionalComments: data.additionalComments || '',
            excludeScore: data.excludeScore || false,
            scoreMax: data.scoreMax || false
        }
    }
    trimTheScoreData = async(scoreId, competitionId, data) => {
        console.log('PRE data', data)
        data.event.judgesNew = {}
        data.event.associatedTeams = []

        data.judge.eventsNew = {}

        data.team.associatedEvents = []
        data.team.teamMembers =  {}
        console.log('data about to save', `scoresNew/${competitionId}/${scoreId}`)

        //return set(location, data)
    }
    trimAllTheScoreData = async () => {
        const scores = await dbGet(ref(this.db, `scoresNew/-NsB2z2PNuTDL9103RAy`))
        const result = scores.val() || {}
        let counter = 0;
        for (const item of Object.keys(result)) {
            if (item !== '-Nv4owZ1zgOMeB_NMzu1') {
                continue
            }
            let data = result[item]
            data.event.judgesNew = null
            data.event.associatedTeams = null

            data.judge.eventsNew = null
            data.judge.teamsNew = null
            data.judge.role = null
            data.judge.invited = null
            

            data.team.associatedEvents = null
            data.team.teamMembers =  null
            const newLocation = ref(this.db, `scoresNew/-NsB2z2PNuTDL9103RAy/${item}`)
            await set(newLocation, data)
            counter++
            console.log('data about to save', data)
            console.log('data about to save', `scoresNew/-NsB2z2PNuTDL9103RAy/${item}`)
            console.log('processed ', counter)
        }
        
        //console.log('this is the result,', result)
    }
    insertScore = async (score, competition) => {
        let toSave = this.Score(score);

        // Remove unnecessary information
        toSave.event.judgesNew = null;
        toSave.event.associatedTeams = null;

        toSave.judge.eventsNew = null;
        toSave.judge.teamsNew = null;
        toSave.judge.role = null;
        toSave.judge.invited = null;

        toSave.team.associatedEvents = null;
        toSave.team.teamMembers =  null;

        const location = ref(this.db, `scoresNew/${competition}`);

        // Push the data to Firebase
        const response = await push(location, toSave);

        // Retrieve and return the key of the newly pushed data
        const newKey = response.key;
        console.log('New key:', newKey);

        return newKey;
    }


    getOrphanedScores = async () => {
        console.log('INSIDE about to get orphaned scores')
        const score = await dbGet(ref(this.db, `scoresNew/-NsB2z2PNuTDL9103RAy`))
        const item = score.val() || {}
        const result = Object.keys(item).reduce((acc, v) => {
            const score = item[v]
            if (score.team.teamNumber === 78 || score.team.teamNumber === 79 || score.team.teamNumber === 80) {
                acc[v] = score
            }
            return acc
        }, {})
        console.log('about to output the mainscores')
        console.log(result)
        return item
    }

    deleteScore = async (scoreKey) => {
        const location = ref(this.db, `scoresNew/${scoreKey}`)
        remove(location)
    }
    deletePastScore = async (scoreKey) => {
        const location = ref(this.db, `pastScoresNew/${scoreKey}`)
        remove(location)
    }
    insertPastScore = async (score, scoreUid, competition) => {
        const location = ref(this.db, `pastScoresNew/${competition}/${scoreUid}`)
        return push(location, score)
    }
    getPastScoresMap = async (scoreUid, competition) => {
        const scores = await dbGet(ref(this.db, `pastScoresNew/${competition}/${scoreUid}`))
        return scores.val() || {}
    }
    listenScoresMap = () => {
        return ref(this.db, `scoresNew`);
    }
    getScore = async (scoreUid, competition) => {
        const score = await dbGet(ref(this.db, `scoresNew/${competition}/${scoreUid}`))
        return score.val() || {}
    }
    getScores = async (competition) => {
        const score = await dbGet(ref(this.db, `scoresNew/${competition}`))
        return score.val() || {}

    }
    patchScore = async (scoreKey, newScoreValue) => {
        const toSave = this.Score(newScoreValue)
        const location = ref(this.db, `scoresNew/${scoreKey}`)

        return set(location, toSave)
        //const location = ref(this.db, `globalSettings/divisions/${divisionKey}`)
        //set(location, newDivisionValue)

        //const toSave = this.User(data)
        //const location = ref(this.db, `users/${uid}`)
        //return set(location, toSave)
        ////return this.db.ref(`users/${uid}`).set(toSave);
    }
    getScoresForEvent = async (eventUid, competition) => {
        const queryConstraints = [orderByChild('eventUid'), equalTo(eventUid)]
        const scores = await dbGet(dbQuery(ref(this.db, `scoresNew/${competition}`), ...queryConstraints))
        return scores.val() || {}
    }
    getScoresForJudge = async (judgeUid, competition) => {
        const queryConstraints = [orderByChild('judgeUid'), equalTo(judgeUid)]
        const scores = await dbGet(dbQuery(ref(this.db, `scoresNew/${competition}`), ...queryConstraints))
        return scores.val() || {}

    }
    getScoresForTeam = async (teamUid, competition) => {
        const queryConstraints = [orderByChild('teamUid'), equalTo(teamUid)]
        const scores = await dbGet(dbQuery(ref(this.db, `scoresNew/${competition}`), ...queryConstraints))
        return scores.val() || {}

    }
    /*
        historyNew
            newData
            oldData
            who
            what
            when
            where
    */
    createHistory = (what, data, where) => {
        const toInsert = Object.assign({},
            data,
            {
                what,
                where,
                when: Timestamp.now().toMillis(),
                who: {
                    displayName: this.auth.currentUser.displayName,
                    email: this.auth.currentUser.email,
                    uid: this.auth.currentUser.uid
                }
            }

        );
        console.log('to insert', toInsert)
        this.db.ref('history').push(toInsert);

    }
    getHistory = () => {

    }
    getHistoryForUser = () => {

    }
    getHistoryForWhen = () => {

    }
    getHistoryForWhat = () => {

    }
    /*
        scoresheets
    */
    listenScoresheetsMap = () => {
        return ref(this.db, 'scoresheets')
    }
    insertGlobalScoresheet = async (values, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/global`)
        set(location, values)
    }
    editGlobalScoresheet = async (values, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/global`)
        set(location, values)
    }

    insertEventScoresheet = async (values, eventUid, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/events/${eventUid}`)
        set(location, values)
    }
    editEventScoresheet = async (values, eventUid, competition) => {
        const location = ref(this.db, `scoresheets/${competition}/events/${eventUid}`)
        set(location, values)
    }
    deleteEventScoresheet = async (eventUid, competition) => {
        if (!eventUid || !competition) {
            return false
        }

        const location = ref(this.db, `scoresheets/${competition}/events/${eventUid}`)
        remove(location)

        return true;
    }

    /*
        liabilityReleaseForm
            userId
                agreedToConsent
    */

    listenLiabilityMap = () => {
        return ref(this.db, 'liabilityForm')
    }

    upsertLiabiltyFormDescription = async (competition, value) => {
        const location = ref(this.db, `liabilityForm/${competition}/details`)
        return set(location, value)
    }

    insertLiabilityForm = async (competition, agreedToConsentSelf, agreedToConsentSelfMinor) => {
        const location = ref(this.db, `liabilityForm/${competition}/signatories/${this.auth.currentUser.uid}`)
        return set(location, {
            agreedToConsentSelf: agreedToConsentSelf === "" ? null : agreedToConsentSelf,
            agreedToConsentSelfMinor: agreedToConsentSelfMinor === "" ? null : agreedToConsentSelfMinor,
            createdAt: Timestamp.now().toMillis()
        })
    }

    getLiabilityFormSignatories = async (competition) => {
        const form = await dbGet(ref(this.db, `liabilityForm/${competition}/signatories/${this.auth.currentUser.uid}`))
        return form.val() || false
    }

    resetLiabilityFormEntries = async (competition) => {
        const location = ref(this.db, `liabilityForm/${competition}/signatories`)
        return set(location, null)

    }

    /*
        globalSettings
            showFinalScoresToTeams
            divisions
            categories
    */
    listenGlobalSettings = () => {
        return ref(this.db, 'globalSettings')

    }
    getGlobalSettings = async () => {
        const globalSettings = await dbGet(ref(this.db, 'globalSettings'))
        return globalSettings.val() || {}

    }

    getShowFinalScoresToTeams = async (competition) => {
        const showFinalScoresToTeams = await dbGet(ref(this.db, `globalSettings/showFinalScoresToTeams/${competition}`))
        return showFinalScoresToTeams.val() || {}
    }
    setShowFinalScoresToTeams = async (competition, value) => {
        console.log('ok')
        const location = ref(this.db, `globalSettings/showFinalScoresToTeams/${competition}`)
        return set(location, value)
    }

    getLockCompetition = async (competition) => {
        const lockCompetition = await dbGet(ref(this.db, `globalSettings/lockCompetition/${competition}`))
        return lockCompetition.val() || {}
    }
    setLockCompetition = async (competition, value) => {
        console.log('ok')
        const location = ref(this.db, `globalSettings/lockCompetition/${competition}`)
        return set(location, value)
    }

    getDivisionMap = async () => {
        const divisions = await dbGet(ref(this.db, `globalSettings/divisions`))
        return divisions.val() || {}
    }
    getDivisionMapByCompetition = async (competition) => {
        const divisions = await dbGet(ref(this.db, `globalSettings/divisions/${competition}`))
        return divisions.val() || {}


    }
    insertDivision = async (division, competition) => {
        const location = ref(this.db, `globalSettings/divisions/${competition}`)
        push(location, division)
    }
    patchDivision = async (divisionKey, newDivisionValue) => {
        const location = ref(this.db, `globalSettings/divisions/${divisionKey}`)
        set(location, newDivisionValue)
    }
    deleteDivision = async (divisionKey, competition) => {
        if (!divisionKey || !competition) {
            return false
        }
        const location = ref(this.db, `globalSettings/divisions/${competition}/${divisionKey}`)
        remove(location)
    }

    getCategoryMap = async () => {
        console.log('ok')
        const categories = await dbGet(ref(this.db, `globalSettings/categories`))
        return categories.val() || {}
    }
    insertCategory = async (category, competition) => {
        const location = ref(this.db, `globalSettings/categories/${competition}`)
        push(location, category)
    }
    patchCategory = async (categoryKey, newCategoryValue) => {
        const location = ref(this.db, `globalSettings/categories/${categoryKey}`)
        set(location, newCategoryValue)
    }
    deleteCategory = async (categoryKey, competition) => {
        if (!categoryKey || !competition) {
            return false
        }
        const location = ref(this.db, `globalSettings/categories/${competition}/${categoryKey}`)
        remove(location)
    }
    getDefaultCompetition = async () => {
        console.log('ok')
        const defaultCompetition = await dbGet(ref(this.db, `globalSettings/defaultCompetition`))
        return defaultCompetition.val() || ''
    }
    setDefaultCompetition = async (key) => {
        const location = ref(this.db, `globalSettings/defaultCompetition`)
        set(location, key)

    }
    getCompetitionMap = async () => {
        console.log('ok')
        const competitions = await dbGet(ref(this.db, `globalSettings/competitions`))
        return competitions.val() || {}
    }
    insertCompetition = async (competition) => {
        const location = ref(this.db, `globalSettings/competitions`)
        return push(location, competition)
    }
    patchCompetition = async (competitionKey, newCompetitionValue) => {
        const location = ref(this.db, `globalSettings/competitions/${competitionKey}`)
        return set(location, newCompetitionValue)
    }
    deleteCompetition = async (competitionKey) => {
        if (!competitionKey) {
            return false
        }
        const location = ref(this.db, `globalSettings/compeititions/${competitionKey}`)
        remove(location)
    }


    // STORAGE CALLS
    // TODO - break up

    storageUploadLiabilityForm = async (file, fileName, competition) => {
        const reference = storageRef(this.storage, `liabilityForm/${competition}/${fileName}`)
        const result = await uploadBytes(reference, file)
        return result
    }

    storageGetLiabilityForm = async (fileName, competition) => {
        try {
            const reference = storageRef(this.storage, `liabilityForm/${competition}/${fileName}`)
            const result = await getDownloadURL(reference)
            return result
        } catch (e) {
            return null
        }
    }


    // REGISTRATION SETTINGS
    listenRegistrationSettings = () => {
        return ref(this.db, 'registrationSettings')

    }
    getRegistrationSettings = async () => {
        const globalSettings = await dbGet(ref(this.db, 'registrationSettings'))
        return globalSettings.val() || {}
    }

    setTeamRegistrationSettings = async (competition, values) => {
        const location = ref(this.db, `registrationSettings/${competition}/team`)
        set(location, values)
    }

    setEventRegistrationSettings = async (competition, values) => {
        const location = ref(this.db, `registrationSettings/${competition}/event`)
        set(location, values)
    }

    setSponsorRegistrationSettings = async (competition, values) => {
        const location = ref(this.db, `registrationSettings/${competition}/sponsor`)
        set(location, values)
    }

    storageUploadRegistrationFile = async (file, fileName, competition) => {
        const reference = storageRef(this.storage, `registrationSettings/${competition}/${fileName}`)
        const result = await uploadBytes(reference, file)
        return result
    }

    storageGetRegistrationFile = async (fileName, competition) => {
        try {
            const reference = storageRef(this.storage, `registrationSettings/${competition}/${fileName}`)
            const result = await getDownloadURL(reference)
            return result
        } catch (e) {
            return null
        }
    }

    insertSponsorRegistration = async (competition, values) => {
        const location = ref(this.db, `registration/${competition}/sponsor`)
        const result = await push(location, values)
        return result.key
    }
    patchSponsorRegistration = async (competition, id, values) => {
        const location = ref(this.db, `registration/${competition}/sponsor/${id}`)
        set(location, values)
    }
    getSponsorRegistration = async (competition, id) => {
        const sponsor = await dbGet(ref(this.db, `registration/${competition}/sponsor/${id}`))
        return sponsor.val() || {}
    }
    setSponsorRegistrationPushed = async (competition, pushed) => {
        const sponsor = ref(this.db, `registration/${competition}/settings/sponsor/pushed`);
        set(sponsor, pushed);
    }
    getSponsorRegistrationPushed = async (competition) => {
        const sponsor = await dbGet(ref(this.db, `registration/${competition}/settings/sponsor/pushed`))
        return sponsor.val() 
    }
    getSponsorRegistrations = async (competition) => {
        const sponsor = await dbGet(ref(this.db, `registration/${competition}/sponsor`))
        return sponsor.val() || {}
    }
    deleteSponsorRegistration = async (competition, id) => {
        const sponsor = ref(this.db, `registration/${competition}/sponsor/${id}`)
        await remove(sponsor);
    }

    insertEventRegistration = async (competition, values) => {
        const location = ref(this.db, `registration/${competition}/event`)
        const result = await push(location, values)
        return result.key
    }
    patchEventRegistration = async (competition, id, values) => {
        console.log(competition + " " + id, values);
        const location = ref(this.db, `registration/${competition}/event/${id}`)
        set(location, values)
    }
    getEventRegistration = async (competition, id) => {
        const registration = await dbGet(ref(this.db, `registration/${competition}/event/${id}`))
        return registration.val() || {}
    }
    setEventRegistrationPushed = async (competition, pushed) => {
        const registration = ref(this.db, `registration/${competition}/settings/event/pushed`);
        set(registration, pushed);
    }
    getEventRegistrationPushed = async (competition) => {
        const registration = await dbGet(ref(this.db, `registration/${competition}/settings/event/pushed`))
        console.log(registration.val());
        return registration.val() 
    }
    getEventRegistrations = async (competition) => {
        console.log(competition);
        const registration = await dbGet(ref(this.db, `registration/${competition}/event`))
        console.log(registration);
        console.log(registration.val());
        return registration.val() || {}
    }
    deleteEventRegistration = async (competition, id) => {
        const registration = ref(this.db, `registration/${competition}/event/${id}`)
        await remove(registration);
    }
    ////////////
    storageGetUserEventFile = async (fileName, competition, id) => {
        try {
            const reference = storageRef(this.storage, `registration/${competition}/event/${id}/${fileName}`)
            const result = await getDownloadURL(reference)
            console.log(result);
            return result
        } catch (e) {
            return null
        }
    }

    storageUploadUserEventRegistrationFile = async (file, fileName, competition, id) => {
        const reference = storageRef(this.storage, `registration/${competition}/event/${id}/${fileName}`)
        // we need this ID for the results files which may have been potentially uploaded
        const result = await uploadBytes(reference, file)
        return result
    }
    storageGetUserEventRegistrationFile = async (fileName, competition, id) => {
        try {
            const reference = storageRef(this.storage, `registrationSettings/${competition}/event/${id}/${fileName}`)
            const result = await getDownloadURL(reference)
            return result
        } catch (e) {
            return null
        }
    }

    insertSponsorRegistration = async (competition, values) => {
        const location = ref(this.db, `registration/${competition}/sponsor`)
        push(location, values)
    }
    patchSponsorRegistration = async (competition, id, values) => {
        const location = ref(this.db, `registration/${competition}/sponsor/${id}`)
        set(location, values)
    }

    insertSchedule = async (currentCompetitionKey, data) => {
        const toSave = {
            ...data 
        };
        const location = ref(this.db, `schedule/competitions/${currentCompetitionKey}`);
        try {
            await set(location, toSave);
            console.log("Data saved successfully to schedule/competitions" + currentCompetitionKey);
        } catch (error) {
            console.error("Error saving data:", error);
        }
    };

    getSchedule = async (currentCompetitionKey) => {
        const location = ref(this.db, `schedule/competitions/${currentCompetitionKey}`);
        try {
            const snapshot = await dbGet(location);
            if (snapshot.exists()) {
                const schedule = snapshot.val();
                console.log("Schedule retrieved successfully:", schedule);
                return schedule;
            } else {
                console.log("No schedule found for competition key:", currentCompetitionKey);
                return null;
            }
        } catch (error) {
            console.error("Error retrieving schedule:", error);
            return null;
        }
    };

    getImage = async (competition, imageName) => {
        try {
            const reference = storageRef(this.storage, `schedule/${competition}/${imageName}`)
            console.log(imageName);
            console.log(reference);
            const result = await getDownloadURL(reference)
            console.log(imageName);
            console.log(result);
            return result
        } catch (e) {
            return null
        }
    };

    getImages = async (competition) => {
        const filesRef = storageRef(this.storage, `schedule/${competition}`);

        try {
            const res = await listAll(filesRef);
            const fileNames = res.items.map((itemRef) => itemRef.name);
            return fileNames;
        } catch (error) {
            return [];
        }
    }

    uploadImage = async (competition, file) => {
        if (!file) {
            console.error('No file provided for upload.');
            return null;
        }

        const fileRef = storageRef(this.storage, `schedule/${competition}/${file.name}`);

        try {
            // Upload the file to the specified path
            const snapshot = await uploadBytes(fileRef, file);

            // Get the download URL
            const downloadURL = await getDownloadURL(snapshot.ref);

            console.log('File uploaded successfully:', downloadURL);
            return downloadURL;
        } catch (error) {
            console.error('Error uploading file:', error);
            return null;
        }
    }

}
