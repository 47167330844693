import React, { createContext, useContext, useEffect, useState } from "react";
import { FirebaseNewContext } from "./FirebaseNewContext";
import { onValue } from "@firebase/database";

export const RegistrationSettingsContext = createContext('');

const RegistrationSettingsContextProvider = (props) => {
    const [registrationSettings, setRegistrationSettings] = useState({})
    const { firebase } = useContext(FirebaseNewContext)

    useEffect(() => {
        const fetch = async () => {
            onValue(firebase.listenRegistrationSettings(), snapshot => {
                const registrationSettingsResult = snapshot.val()
                setRegistrationSettings(registrationSettingsResult)
            })

        }
        fetch()
            .catch(console.error)

    }, [])// eslint-disable-line

    return (
        <RegistrationSettingsContext.Provider value={{ registrationSettings }}>
            {props.children}
        </RegistrationSettingsContext.Provider>
    )
}
export default RegistrationSettingsContextProvider
