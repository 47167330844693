import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Input = styled('input')({
  display: 'none',
});

const UploadFile = ({ onUpload }) => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
            setSelectedFile(file);
        } else {
            alert('Please upload a valid PNG or JPG file.');
        }
    };

    const handleUploadClick = () => {
        if (selectedFile) {
            onUpload(selectedFile);
        } else {
            alert('No file selected or invalid file type.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                borderRadius: 1,
                border: '1px solid #ccc',
                maxWidth: 400,
                margin: '0 auto',
                backgroundColor: '#f9f9f9',
            }}
        >
            <Typography variant="h6" gutterBottom>
                Upload Your File
            </Typography>
            <label htmlFor="file-upload">
                <Input
                    accept="image/png, image/jpeg"
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                />
                <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{
                        marginBottom: 2,
                        paddingX: 4,
                    }}
                >
                    Choose File
                </Button>
            </label>
            {selectedFile && (
                <Typography variant="body1" gutterBottom>
                    Selected File: {selectedFile.name}
                </Typography>
            )}
            <Button
                variant="contained"
                color="primary"
                onClick={handleUploadClick}
                disabled={!selectedFile}
                sx={{ marginTop: 2, paddingX: 4 }}
            >
                Upload
            </Button>
        </Box>
    );
};

export default UploadFile;

