import React, { useEffect, useState, useMemo, useContext } from 'react';
import * as routes from '../../routes/routes';
import { UserDataContext } from '../../firebase/UserDataContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Stack, Typography, Checkbox, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import EventRegistrationForm from '../../components/registration/EventRegistrationForm';
import { REGISTRATION_STATES } from '../../components/misc/Utils';
import * as ROUTES from '../../routes/routes';

function alternateReady(status) {

    if(status == REGISTRATION_STATES.PUSHED || status == REGISTRATION_STATES.READY_TO_PUSH) {
        return REGISTRATION_STATES.SUBMITTED;
    }

    return REGISTRATION_STATES.READY_TO_PUSH;
}

const registrationRows = (handleCheckboxChange, handleDeleteClick, competitionLock) => [
    { field: 'col1', headerName: 'Event Name', width: 250 },
    { field: 'col2', headerName: 'Booth Size', width: 120 },
    { field: 'col3', headerName: 'Contact Email', width: 120 },
    { field: 'col4', headerName: 'Company', width: 150 },
    { field: 'col5', headerName: 'Status', width: 150 },
    { field: 'col6', headerName: 'Ready to Push?', width: 200, renderCell: (params) => {
        return (
            <Checkbox
                checked={[REGISTRATION_STATES.READY_TO_PUSH, REGISTRATION_STATES.PUSHED].includes(params.value.status)}
                disabled={[REGISTRATION_STATES.PUSHED, REGISTRATION_STATES.NOT_PUSHED].includes(params.value.status)}
                onChange={() => handleCheckboxChange(params.row.id, alternateReady(params.value.status))}
            />
        );
    }},
    {
        field: 'col7', headerName: 'Actions', width: 300, getApplyQuickFilterFn: () => null,
        renderCell: (params) => {
            return (
                <>
                <Button component={Link} to={
                        ROUTES.routeReplace(ROUTES.EVENT_REGISTRATION_EDIT, { editEvent: params.value.uid, competition: params.value.selectedCompetition })
                    } 
                    target="_blank" rel="noopener noreferrer" disabled={params.value.disabled}>Edit</Button>
                <Button component={Link} to={
                        ROUTES.routeReplace(ROUTES.EVENT_REGISTRATION_VIEW, { eventUid: params.value.uid, competition: params.value.selectedCompetition })
                    } 
                    target="_blank" rel="noopener noreferrer" disabled={competitionLock}>View</Button>
                <Button color="error" disabled={params.value.disabled} onClick={() => handleDeleteClick(params.value.event, params.value.uid)}>Delete</Button>
                </>
            )
        }
    },
];

function WaitroomEvent(props) {
    const location = useLocation();
    const params = useParams();
    const { globalSettings: { competitions } } = useContext(GlobalSettingsContext);
    const { globalSettings } = useContext(GlobalSettingsContext);
    const { firebase } = useContext(FirebaseNewContext);
    const { defaultSelectedCompetition } = useContext(UserDataContext);
    const [selectedCompetition, setSelectedCompetition] = useState(defaultSelectedCompetition);
    const [events, setEvents] = useState([]);
    const [confirmDelete, setConfirmDelete] = useState({ open: false, event: null });
    const [pushed, setPushed] = useState(false);

    // Fetch events in useEffect
    useEffect(() => {
        const fetchEvents = async () => {
            const eventList = await firebase.getEventRegistrations(selectedCompetition);
            setEvents(eventList);
            setPushed(await firebase.getEventRegistrationPushed(selectedCompetition));
        };
        if (selectedCompetition) {
            fetchEvents().catch(console.error);
        }
    }, [selectedCompetition]);

    // Handle checkbox change event
    const handleCheckboxChange = async (eventId, newReadyStatus) => {
        const event = events[eventId];
        const updatedEvent = { ...event, status: newReadyStatus };

        // Update in Firebase
        await firebase.patchEventRegistration(selectedCompetition, eventId, updatedEvent);

        // Update the local state
        setEvents((prevEvents) => ({
            ...prevEvents,
            [eventId]: updatedEvent
        }));
    };

    const handleDeleteClick = (event, uid) => {
        console.log(event);
        event.uid = uid;
        setConfirmDelete({ open: true, event })
    }

    const handleCloseDialog = () => {
        setConfirmDelete({ open: false, team: null })
    }

    const deleteRegistrationEvent = async (event) => {
        console.log(event);
        await firebase.deleteEventRegistration(selectedCompetition, event.uid);
        window.location.reload(false);
    }

    const pushEvents = async() => {
        Object.keys(events).forEach(async (key) => {
            const event = events[key];
            
            if(event.status == REGISTRATION_STATES.READY_TO_PUSH) {
                await firebase.patchEventRegistration(selectedCompetition, key, {...event, status: REGISTRATION_STATES.PUSHED});
            } else if (event.status == REGISTRATION_STATES.PUSHED) {
                console.log("An event was already pushed, this shouldnt be allowed");
            } else {
                await firebase.patchEventRegistration(selectedCompetition, key, {...event, status: REGISTRATION_STATES.NOT_PUSHED});
            }
        });

        await firebase.setEventRegistrationPushed(selectedCompetition, true);
        window.location.reload();
    }

    const rollbackEvents = async() => {
        Object.keys(events).forEach(async (key) => {
            const event = events[key];
            
            if(event.status == REGISTRATION_STATES.NOT_PUSHED) {
                await firebase.patchEventRegistration(selectedCompetition, key, {...event, status: REGISTRATION_STATES.SUBMITTED});
            } else if (event.status == REGISTRATION_STATES.SUBMITTED) {
                console.log("An event was already pushed, this shouldnt be allowed");
            } else {
                await firebase.patchEventRegistration(selectedCompetition, key, {...event, status: REGISTRATION_STATES.READY_TO_PUSH});
            }
        });

        await firebase.setEventRegistrationPushed(selectedCompetition, false);
        window.location.reload();
    }

    const rows = useMemo(() => {
        if (!events) return [];

        return Object.keys(events).map((v) => {
            const event = events[v];
            return {
                id: v,
                col1: event.eventName || '',
                col2: event.boothSize || '',
                col3: event.primaryPointOfContactEmail || '',
                col4: event.companyName || '',
                col5: event.status || 'SUBMITTED',
                col6: { 
                    status: event.status || '', 
                    callback: async () => handleCheckboxChange(v, alternateReady(event.status))
                },
                col7: { 
                    uid: v, 
                    selectedCompetition, 
                    event: event, 
                    deleteCallback: () => handleDeleteClick(event, v), 
                    disabled: (globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[selectedCompetition]) || [REGISTRATION_STATES.NOT_PUSHED, REGISTRATION_STATES.PUSHED].includes(event.status) 
                }
            };
        });
    }, [events]);

    return (
        <Grid2>
            {typeof selectedCompetition !== 'string' && <h3>Please Select A Competition</h3>}

            {!pushed &&
                <Button onClick={() => pushEvents()}>Push All</Button>
            }
            {pushed &&
                <Button onClick={() => rollbackEvents()}>Rollback</Button>
            }

            {location.pathname === routes.WAITROOM_PAGE && typeof selectedCompetition === 'string' && (
                <Grid2 flexDirection={'column'}>
                    <DataGrid
                        sx={{ marginBottom: '25px' }}
                        autoHeight
                        rows={rows || []}
                        columns={registrationRows(handleCheckboxChange, handleDeleteClick, globalSettings?.lockCompetition[selectedCompetition] || false)}
                        slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <Typography variant="body">For the {competitions[selectedCompetition]} competition</Typography>
                                    <Typography variant="body">No Events Have Been Created</Typography>
                                </Stack>
                            ),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Grid2>
            )}

            {Object.keys(params).length !== 0 && (
                <EventRegistrationForm {...props} team={`${params.edit}`} competition={selectedCompetition} />
            )}
            {location.pathname.includes(routes.EVENT_REGISTRATION_PAGE) && <EventRegistrationForm {...props} />}

             <Dialog
                open={confirmDelete.open}
                onClose={handleCloseDialog}
             >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this team?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">No</Button>
                    <Button onClick={async () => await deleteRegistrationEvent(confirmDelete.event)} color="error">Yes</Button>
                </DialogActions>
            </Dialog>
        </Grid2>
    );
}

export default WaitroomEvent;

