import React, { useState, useContext, useMemo, useEffect } from 'react';
import * as ROUTES from '../routes/routes';
import { UserDataContext } from '../firebase/UserDataContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { Button, Chip, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { ScoresheetForm } from '../components/forms/ScoresheetForm';
import { CommonDataContext } from '../firebase/CommonDataContext';
import { DataGrid } from '@mui/x-data-grid';
import { determineScoresheetTableChipColor } from '../components/misc/Utils';
import { eventScoresheetColumns } from './JudgeScoresheetPage';
import { FirebaseNewContext } from '../firebase/FirebaseNewContext';

const globalScoresheetColumns = [
    { field: 'col1', headerName: 'Name', width: 200 },
    { field: 'col2', headerName: 'Description', width: 500 },
    {
        field: 'col3', headerName: 'Status', width: 200,
        renderCell: (params) => {
            let variant = determineScoresheetTableChipColor(params.value);
            let variantProps = variant === '' ? {} : { color: variant }
            return (<Chip label={params.value ? params.value : 'Not Yet Created'} {...variantProps} />)
        }
    },
    // TODO - make the actions do what you need them to
    // VIEW | LOCK | APPROVE | ALLOW_EDIT
    {
        field: 'col4', headerName: 'Actions', width: 250,
        renderCell: (params) => {
            const { status, competition, eventInfo } = params.value
            let toNavigate = ROUTES.BASE_SCORESHEET_PAGE_NEW
            if (status !== '') {
                toNavigate = ROUTES.routeReplace(ROUTES.BASE_SCORESHEET_PAGE_EDIT, {
                    competition: competition
                })
            }
            return (
                <Button
                    component={Link}
                    to={toNavigate}>
                    {status === '' ? 'Create' : 'Edit'}
                </Button>
            )
        }
    },
]

function BaseScoresheetPage(props) {
    const location = useLocation();
    const params = useParams();
    const [allLocked, setAllLocked] = React.useState(true);
    const { firebase } = useContext(FirebaseNewContext)

    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const { scoresheetsMap: commonScoresheetsMap, eventsMap: commonEventsMap, teamsMap: globalTeamsMap } = useContext(CommonDataContext)

    const sheetBuilder = () => {
        const teams = globalTeamsMap[selectedCompetition];
        const events = commonEventsMap[selectedCompetition];
        const penalties = commonScoresheetsMap[selectedCompetition]['events'];
        const headers = ["Team Number", "Team Name", "Event Number", "Event Name", "Company Name", "Other 1", "Other Seconds 1", "Other 2", "Other Seconds 2", "Other 3", "Other Seconds 3", "Other 4", "Other Seconds 4", "Other 5", "Other Seconds 5", "Other 6", "Other Seconds 6", "Other 7", "Other Seconds 7", "Other 8", "Other Seconds 8", "Other 9", "Other Seconds 9", "Other 10", "Other Seconds 10"];
        const rows = [];

        Object.values(teams).forEach((team) => {
            const row = [];

            Object.keys(events).forEach((eventKey) => {
                const event = events[eventKey];
                const eventPenalties = penalties[eventKey]?.penalties;

                const eventRow = [team.teamNumber, team.teamName, event.number, event.name, event.company];

                if (eventPenalties != undefined) {
                    console.log(eventPenalties.filter(x => x.custom == true))
                    eventPenalties
                        .filter(x => x.custom == true)
                        .forEach((penalty, _) => {
                        eventRow.push(...[penalty.type, penalty.value]);
                    });
                }

                row.push(eventRow);
            });

            rows.push(...row);
        });

        function exportToCSV(headers, rows, filename = "export.csv") {
            let csvContent = headers.join(",") + "\n";

            rows.forEach((row) => {
                csvContent += row.map((value) => `"${value}"`).join(",") + "\n";
            });

            // Create a Blob and trigger a download
            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }

        exportToCSV(headers, rows);
    };

    const setAllScoresLocked = (async () => {
        const scoresMap = commonScoresheetsMap[selectedCompetition]['events'];

        for (const key of Object.keys(scoresMap)) {
            console.log(scoresMap[key].status);
            if (scoresMap[key].status === "CREATED") {
                console.log( {...scoresMap[key], status: "LOCKED"} );
                await firebase.editEventScoresheet({...scoresMap[key], status: "LOCKED"}, key, selectedCompetition);
                setAllLocked(true);
                window.location.reload(false);
            }
        }
    });

    const globalScoresheetsMap = useMemo(() => {
        if (!commonScoresheetsMap || !commonScoresheetsMap[selectedCompetition]) {
            return {}
        }
        return commonScoresheetsMap[selectedCompetition]['global'] || {}
    }, [selectedCompetition, commonScoresheetsMap])
    const eventsScoresheetsMap = useMemo(() => {
        if (!commonScoresheetsMap || !commonScoresheetsMap[selectedCompetition]) {
            return {};
        }

        const scoresMap = commonScoresheetsMap[selectedCompetition]?.['events'];

        if (!scoresMap || typeof scoresMap !== 'object') {
            return {};
        }

        for (const key of Object.keys(scoresMap)) {
            if (scoresMap[key]?.status === "CREATED") {
                setAllLocked(false);
                break;
            }
        }

        return scoresMap;
    }, [selectedCompetition, commonScoresheetsMap]);

    const eventsMap = useMemo(() => {
        if (!commonEventsMap) {
            return {}
        }
        return commonEventsMap[selectedCompetition] || {}
    }, [selectedCompetition, commonEventsMap])

    const globalScoresheetRows = () => {
        const status = globalScoresheetsMap.status || ''
        return [{
            id: 1,
            col1: 'Base Scoresheet',
            col2: 'Base Scoresheet to be used with all other event scoresheets',
            col3: status,
            col4: { status, competition: selectedCompetition, eventInfo: null }
        }]

    }
    const eventsScoresheetRows = () => {
        return Object.keys(eventsMap).map((e, _) => {
            const event = eventsMap[e]
            const scoresheet = eventsScoresheetsMap[e] || {}
            return {
                id: e,
                col1: event['number'],
                col2: event['fullTitle'],
                col3: { status: scoresheet.status || 'Not Yet Created', eventInfo: event },
                col4: { status: scoresheet.status, competition: selectedCompetition, eventUid: e, eventInfo: event }

            }
        })
    }

    return (
        <Grid2>
            {
                location.pathname === ROUTES.BASE_SCORESHEET_PAGE &&
                    (
                        <Grid2>
                            <Typography variant='h3'>Base Scoresheet Page</Typography>
                            <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                                <Grid2 container direction={'row'} width={'100%'} justifyContent={'space-between'} alignItems={'center'}>

                                    <CompetitionDropdown
                                        value={selectedCompetition}
                                        onChange={setSelectedCompetition}
                                    />

                                    <Grid2 container alignItems={'center'}>
                                        <Button sx={{ marginRight: '25px' }} onClick={sheetBuilder}>
                                            Generate Sheet
                                        </Button>
                                    </Grid2>

                                </Grid2>

                                {
                                    Object.keys(globalScoresheetsMap).length == 0 &&
                                        <Button sx={{ maxHeight: '50px' }} component={Link} variant={'outlined'} to={ROUTES.BASE_SCORESHEET_PAGE_NEW}>Create A New Base Scoresheet</Button>
                                }
                            </Grid2>

                            <br />

                            <Button
                                disabled={allLocked}
                                onClick={async () => await setAllScoresLocked()}
                            >Lock all sheets</Button>
                            <Grid2>
                                {
                                    globalScoresheetsMap && (() => {
                                        return (<DataGrid
                                            sx={{ marginBottom: '25px' }}
                                            rows={globalScoresheetRows()}
                                            columns={globalScoresheetColumns}
                                        />
                                        )
                                    })()
                                }
                            </Grid2>
                            <Grid2>
                                <DataGrid
                                    sx={{ marginBottom: '25px' }}
                                    rows={eventsScoresheetRows()}
                                    columns={eventScoresheetColumns}
                                />
                            </Grid2>
                        </Grid2>
                    )
            }
            {
                location.pathname.includes(ROUTES.BASE_SCORESHEET_PAGE_NEW) &&
                    (
                        <ScoresheetForm />
                    )

            }
            {
                location.pathname.includes('edit') &&
                    location.pathname.includes('base-scoresheet') &&
                    (
                        <ScoresheetForm competition={params.competition} />
                    )
            }
        </Grid2 >
    )

}



export default BaseScoresheetPage
