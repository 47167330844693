import React, { useContext } from 'react';
import { GlobalSettingsContext } from '../../../firebase/GlobalSettingsContext';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { FormControlLabel, Typography } from '@mui/material';

const CompetitionDropdown = (props) => {
    const { globalSettings } = useContext(GlobalSettingsContext)

    const {
        mapValues,
        value,
        onChange,
        title,
        readonly
    } = props
    return (
        <Grid2>
            <Typography variant='h5'> { title ? title : "Selected Competition" }</Typography>
            <FormControl className='scoring-form-div'>
                <Select
                    name="competitionUid"
                    value={value}
                    onChange={(e) => onChange(e.target.value || {})}
                    className="scoring-form-select"
                    readOnly={readonly}
                    size={'small'}
                    sx={{ maxHeight: '50px' }}
                >
                    <MenuItem value={''}>Competitions</MenuItem>
                    {Object.keys(mapValues || globalSettings.competitions || {}).map(cUid => {
                        return (
                            <MenuItem
                                key={cUid}
                                value={cUid}
                            >
                                {globalSettings.competitions[cUid]}
                            </MenuItem>
                        )
                    })}

                </Select>
            </FormControl>
        </Grid2>
    )
}

export default CompetitionDropdown
