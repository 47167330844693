import { Button, Checkbox, FormControlLabel, FormGroup, TextField, Typography, Autocomplete, Chip } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useContext, useReducer, useEffect, useState, useCallback, useMemo } from 'react';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import { numberSuggester } from '../misc/Utils';
import CategoryDropdown from './Dropdowns/CategoryDropdown';
import CompetitionDropdown from './Dropdowns/CompetitionDropdown';
import DivisionDropdown from './Dropdowns/DivisionDropdown';
import FormErrorMessages from './Messages/FormErrorMessages';
import { MultiSearchSelectEvent } from './Dropdowns/EventDropdown';
import { MultiSearchSelectTeam, MultiSearchSelectTeamWrapper } from './Dropdowns/TeamDropdown';
import { MultiSearchSelectUserWrapper } from './Dropdowns/UserDropdown';

function SponsorAutocomplete(props) {
  const { valueChanged, values = [] } = props;
  const [value, setValue] = useState(values);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    setValue(values);
  }, [values]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const newValue = inputValue.trim();

      if (newValue && !value.includes(newValue)) {
        const updatedValue = [...value, newValue];
        setValue(updatedValue);
        valueChanged(updatedValue);
      }
      setInputValue('');
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
        valueChanged(newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip key={index + "R" + (Math.random() * 9999)} label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Sponsors"
          placeholder="Press Enter to add"
          onKeyDown={handleKeyDown}
        />
      )}
    />
  );
}


function teamFormInitialState(values) {
    return {
        teamNumber: values.teamNumber || '',
        teamName: values.teamName || '',
        teamMembers: values.teamMembers || [],
        division: values.division || '',
        category: values.category || '',
        associatedEvents: values.associatedEvents || [],
        sponsors: values.sponsors || [],
        checkedIn: values.checkedIn || false,
        errorMessages: []
    }
}

function teamFormReducer(state, action) {
    switch (action.type) {
        case 'value_changed':
            return {
                ...state,
                [action.keyName]: action.keyValue
            }
        case 'values_changed':
            return {
                ...state,
                ...action.values
            }
        case 'checkbox_values_changed':
            return {
                ...state,
                teamMembers: action.values
            }
        case 'checkbox_value_changed':
            const value = action.value;
            const isChecked = action.checked;
            if (isChecked) {
                return {
                    ...state,
                    teamMembers: [...state.teamMembers, action.value]
                }
            } else {
                return {
                    ...state,
                    teamMembers: state.teamMembers.filter((option) => option !== value)
                }
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages

            }
        default:
            break;
    }
}

function TeamForm(props) {
    const [state, dispatch] = useReducer(teamFormReducer, props, teamFormInitialState)
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings: { divisions: globalDivisionMap, categories: globalCategoryMap } } = useContext(GlobalSettingsContext)
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { teamUsersMap, teamsMap: globalTeamsMap, eventsMap: globalEventsMap, liabilityMap: globalLiabilityMap } = useContext(CommonDataContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const divisionMap = useMemo(() => {
        return globalDivisionMap[selectedCompetition] || {}
    }, [globalDivisionMap, selectedCompetition])
    const categoryMap = useMemo(() => {
        return globalCategoryMap[selectedCompetition] || {}
    }, [globalCategoryMap, selectedCompetition])
    const teamsMap = useMemo(() => {
        return globalTeamsMap[selectedCompetition] || {}
    }, [globalTeamsMap, selectedCompetition])
    const eventsMap = useMemo(() => {
        return globalEventsMap[selectedCompetition] || {}
    }, [globalEventsMap, selectedCompetition])
    const liabilityMap = useMemo(() => {
        const theLiabilities = globalLiabilityMap[selectedCompetition] || {}
        return 'signatories' in theLiabilities ? theLiabilities['signatories'] : {}
    }, [globalLiabilityMap, selectedCompetition])


    const [team, setTeam] = useState([])
    const [associatedEvents, setAssociatedEvents] = useState([])

    const signedMembers = useMemo(() => {
        // TODO - reducer for team members
        const ourTeam = teamsMap[props.team]
        if (!ourTeam) {
            return {}
        }
        console.log('this is our team', ourTeam)
        if (!ourTeam.teamMembers) {
            return {}
        }
        return Object.keys(ourTeam.teamMembers).reduce((acc, v) => {
            const member = teamUsersMap[v]
            console.log('our memeber', member)
            acc[v] = {...member, signed: false}
            if (v in liabilityMap) {
                acc[v]['signed'] = true
            }
            
            return acc
        }, {})

    }, [liabilityMap, team])

    useEffect(() => {
        const fetch = async () => {
            if (props.team && props.team in teamsMap) {
                const team = teamsMap[props.team]
                const theTeamMembers = team.teamMembers ? Object.keys(team.teamMembers).map(v => v) : []

                // TODO - we need to fix team members the are here but need to be selected
                setTeam(theTeamMembers)
                setAssociatedEvents(team.associatedEvents || [])
                dispatch({
                    type: 'values_changed',
                    values: {
                        teamNumber: team.teamNumber,
                        teamMembers: theTeamMembers,
                        teamName: team.teamName,
                        division: team.division,
                        category: team.category,
                        associatedEvents: team.associatedEvents || [],
                        sponsors: team.sponsors || [],
                        updatedAt: team.updatedAt,
                        createdAt: team.createdAt,
                        checkedIn: team.checkedIn
                    }
                })

            }
        }
        fetch().catch(console.error)

    }, [])// eslint-disable-line

    const isValid = (state) => {
        const {
            teamNumber,
            division,
            category
        } = state;
        let validCheck = { result: true, errorMessages: [] }

        if (teamNumber === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Team number must not be empty')
        }
        if (division === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Division name must not be empty')
        }
        if (category === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Category name must not be empty')
        }
        return validCheck;
    }
    const isValidTeamNumber = (teamNumber, teamsMap) => {
        let validCheck = { result: true, errorMessages: [] };
        const teamNumbers = Object.keys(teamsMap).map((v) => {
            return teamsMap[v]['teamNumber']
        })
        if (teamNumbers.includes(teamNumber)) {
            validCheck.result = false;

            const toSuggest = numberSuggester(teamNumbers)
            validCheck.errorMessages.push(`Event number has already been assigned why not try - ${toSuggest}`)
        }
        return validCheck
    }

    // TODO - change to a use callback
    //state, teamUsersMap, teamsMap, 
    const insertNewTeam = async () => {
        const validResult = () => {
            let validCheck = isValid(state)
            const isValidTeamNumberCheck = isValidTeamNumber(state.teamNumber, teamsMap)
            if (!isValidTeamNumberCheck.result) {
                validCheck = isValidTeamNumberCheck;
            }
            return validCheck
        }
        let validResultReturn = validResult()
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: validResultReturn.errorMessages
        })
        if (validResultReturn.result) {
            const teamGrouping = groupTeamMembers(teamUsersMap, state.teamMembers)
            const result = await firebase.insertTeam({ ...state, teamMembers: teamGrouping }, selectedCompetition)
            await addTeamEntry(result.key)
            // propogate data to events
            //await addEventEntry(props.team)
            await addEventEntry(result.key)

            window.location.reload(false)
        }
    }

    const patchExistingTeam = async () => {
        const isValidCheck = isValid(state)
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: isValidCheck.errorMessages
        })
        if (isValidCheck.result) {
            const teamGrouping = groupTeamMembers(teamUsersMap, state.teamMembers)
            await firebase.patchTeam(`${props.competition}/${props.team}`, { ...state, teamMembers: teamGrouping, associatedEvents: state.associatedEvents })
            // propogate data to teams
            await removeTeamEntry(props.team)
            await addTeamEntry(props.team)

            // propogate data to events
            await addEventEntry(props.team)
            await removeEventEntry(props.team)
            window.location.reload(false)
        }
    }
    const addTeamEntry = async (databaseId) => {
        console.log("test");
        const newUsers = newInTeam(team, state.teamMembers)
        console.log(state.teamMembers);
        const result = await Promise.all(state.teamMembers.map(async (x) => {
            console.log(x);
            let user = await firebase.getUser(x)
            if (!user.teamsNew) {
                user.teamsNew = {}
            }

            console.log(props.team || databaseId);
            user.teamsNew = { 
                ...user.teamsNew, 
                [selectedCompetition]: [props.team || databaseId]
            };
            console.log(user.teamsNew);
            return firebase.patchUser(x, user, selectedCompetition)
        }))
        return result

    }
    const removeTeamEntry = async (databaseId) => {
        const removedUsers = outInTeam(team, state.teamMembers)
        const result = await Promise.all(removedUsers.map(async (x) => {
            let user = await firebase.getUser(x)
            if (!user.teamsNew) {
                user.teamsNew = {}
            }

            let teamsList = (user.teamsNew[selectedCompetition] || []).filter(x => x !== databaseId)
            user.teamsNew = { ...user.teamsNew, ...{ [selectedCompetition]: teamsList } }
            return firebase.patchUser(x, user, selectedCompetition)

        }))
        return result
    }


    const newInTeam = (originalTeam, aboutToSave) => {
        return aboutToSave.filter(x => !originalTeam.includes(x))
    }
    const outInTeam = (originalTeam, aboutToSave) => {
        return originalTeam.filter(x => !aboutToSave.includes(x))
    }
    const addEventEntry = async (teamUid) => {
        const addedEvents = newTeamInEvent(associatedEvents, state.associatedEvents)
        const result = await Promise.all(addedEvents.map(async (x) => {
            let event = await firebase.getEvent(x.uid, selectedCompetition)
            if (!event.associatedTeams) {
                event.associatedTeams = []
            }
            event.associatedTeams = event.associatedTeams.concat({ ...(firebase.Team(state)), ...{ uid: teamUid } })
            return firebase.patchEvent(`${selectedCompetition}/${x.uid}`, event)
        }))
        return result
    }
    const removeEventEntry = async (teamUid) => {
        const removedEvents = outTeamInEvent(associatedEvents, state.associatedEvents)
        const result = await Promise.all(removedEvents.map(async (x) => {
            let event = await firebase.getEvent(x.uid, selectedCompetition)
            if (!event.associatedTeams) {
                event.associatedTeams = []
            }

            event.associatedTeams = event.associatedTeams.filter(y => y.uid !== teamUid)
            return firebase.patchEvent(`${selectedCompetition}/${x.uid}`, event)
        }))
        return result
    }

    const newTeamInEvent = (originalEvents, aboutToSave) => {
        return aboutToSave.filter(x => !originalEvents.some(y => x.uid == y.uid))
    }
    const outTeamInEvent = (originalEvents, aboutToSave) => {
        return originalEvents.filter(x => !aboutToSave.some(y => x.uid == y.uid))
    }

    const submitButtonPressed = useCallback((e) => {
        e.preventDefault()
        if (props.team) {
            console.log('time to patch')
            patchExistingTeam()
        } else {
            console.log('time to insert')
            insertNewTeam()
        }
    }, [props.team, state, selectedCompetition]) // eslint-disable-line
    const groupTeamMembers = (teamUsersMap, teamMembers) => {
        const items = teamMembers.reduce((acc, v) => {
            if (!(v in teamUsersMap)) {
                return acc
            }
            acc[v] = teamUsersMap[v]
            return acc
        }, {})
        return items
    }

    // TODO - category + division dropdowns should be required
    return (
        <Grid2 sx={{ padding: '20px' }}>
            {
                !props.competition &&
                <CompetitionDropdown
                    value={selectedCompetition}
                    onChange={(value) => setSelectedCompetition(value)}
                />
            }
            <Grid2>
                <Typography variant='h5'>{props.team ? 'Edit' : 'Create'} A Team</Typography>
                <Grid2 container
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                >
                    <TextField
                        sx={{ flex: 1 }}
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        name="teamNumber"
                        value={state.teamNumber}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'teamNumber'
                            })
                        }}
                        label="Team Number"
                    />
                    <TextField
                        sx={{ flex: 1 }}
                        name="teamNumber"
                        value={state.teamName}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'teamName'
                            })
                        }}
                        label="Team Name"
                    />
                </Grid2>

                <Grid2 container>
                    <CategoryDropdown
                        categoriesMapValues={categoryMap}
                        value={state.category}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'category'
                            })
                        }}

                    />
                    <DivisionDropdown
                        divisionsMapValues={divisionMap}
                        value={state.division}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'division'
                            })
                        }}
                    />
                </Grid2>
                <Typography variant='h6'>Team Users</Typography>
                <MultiSearchSelectUserWrapper
                    selectedUsers={state.teamMembers}
                    value={state.teamMembers}
                    onChange={(value) => {
                        const values = value.map(v => v.uid)
                        dispatch({
                            type: 'checkbox_values_changed',
                            values: values,
                        })

                    }}
                    usersMapValues={teamUsersMap}
                />
                <Typography variant='h6'>Associated Events</Typography>
                <MultiSearchSelectEvent
                    eventsMapValues={eventsMap}
                    onChange={(value) => {
                        dispatch({
                            type: 'value_changed',
                            keyValue: value,
                            keyName: 'associatedEvents'
                        })
                    }}
                    value={state.associatedEvents}
                />

                <Typography variant='h6'>Sponsors</Typography>
                <SponsorAutocomplete 
                    values={state.sponsors}
                    valueChanged={(value) => {
                        dispatch({
                            type: 'value_changed',
                            keyValue: value,
                            keyName: 'sponsors'
                        })
                    }}
                    />

                <br />

                <FormGroup sx={{ justifyContent: 'center' }}>
                    <FormControlLabel
                        label={'Checked in?'}
                        control={
                            <Checkbox
                                value={state.checkedIn}
                                onChange={(e) => {
                                    dispatch({
                                        type: 'values_changed',
                                        values: {
                                            checkedIn: !state.checkedIn 
                                        }
                                    })

                                }}
                                checked={state.checkedIn}

                            />}
                    />
                </FormGroup>

                <br />

                <Grid2>
                    <FormErrorMessages errorMessages={state.errorMessages} />
                    <Button disabled={globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]} onClick={submitButtonPressed}>Submit</Button>
                </Grid2>
                <Grid2>
                    <Typography variant='h4'>Liability Form Status</Typography>
                    {
                        Object.keys(signedMembers).length > 0 ? 
                        Object.keys(signedMembers).map((v) => {
                            const member = signedMembers[v]
                            return (
                                <>
                                    <Grid2 color={member.signed ? 'black' : 'red'} key={v}>
                                        {member.email} - {member.signed ? 'Signed' : 'Not Signed'}
                                    </Grid2>

                                </>
                            )

                        })
                        :
                        <Typography>No Members assigned to the team</Typography>
                    }

                </Grid2>
            </Grid2>
        </Grid2>

    )

}

// TODO - we need dropdowns for categories and divisions

export default TeamForm
