import { Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useReducer, useEffect, useState, useCallback, useContext } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import FormErrorMessages from '../forms/Messages/FormErrorMessages';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useParams } from 'react-router-dom';



function sponsorFormInitialState(values) {
    return {
        organization: values.organization || '',
        country: values.country || '',
        teamCategory: values.teamCategory || '',
        teamName: values.teamName || '',
        teamContactNameFirst: values.teamContactNameFirst || '',
        teamContactNameLast: values.teamContactNameLast || '',
        teamContactEmail: values.teamContactEmail || '',
        teamContactPhone: values.teamContactPhone || '',
        billingEmail: values.billingEmail || '',
        additionalMembers: values.additionalMembers || '',
        sponsorFirstName: values.sponsorFirstName || '',
        sponsorLastName: values.sponsorLastName || '',
        sponsorTitle: values.sponsorTitle || '',
        sponsorCompany: values.sponsorCompany || '',
        sponsorEmail: values.sponsorEmail || '',
        sponsorship: values.sponsorship || '',
        errorMessages: []
    }
}
function sponsorFormReducer(state, action) {
    switch (action.type) {
        case 'value_changed':
            return {
                ...state,
                [action.keyName]: action.keyValue
            }
        case 'values_changed':
            return {
                ...state,
                ...action.values
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages

            }
        case 'LOAD_EVENT_DATA':
            return {
                ...state,
                ...action.payload,
            };
        default:
            break;
    }
}

// TODO - break up user form so it is just creating a user
//      not assigning them to a team yet
// TODO - create team form so you can assign multiple users to a team
function TeamRegistrationForm(props) {
    const params = useParams();
    const [isView, setView] = useState(window.location.pathname.includes("view"));
    const [state, dispatch] = useReducer(sponsorFormReducer, props, sponsorFormInitialState)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)

    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const sponsorRegistrationData = await firebase.getSponsorRegistration(selectedCompetition, (params.editSponsor || params.sponsorUid));
                console.log(selectedCompetition + " " + params.editSponsor);
                console.log(sponsorRegistrationData);
                dispatch({ type: 'LOAD_EVENT_DATA', payload: { ...sponsorRegistrationData, id: params.editSponsor } });
            } catch (err) {
                console.error(err);
            }
        };

        if ((params.editSponsor || params.sponsorUid) && selectedCompetition) {
            fetchEventData();
        }
    }, [params.editSponsor, selectedCompetition, firebase]);

    const isValid = (state) => {
        const {
            organization,
            country,
            teamCategory,
            teamName,
            teamContactNameFirst,
            teamContactNameLast,
            teamContactPhone,
            sponsorFirstName,
            sponsorLastName,
            sponsorTitle,
            sponsorCompany,
            sponsorEmail,
            sponsorship,
        } = state;
        let validCheck = { result: true, errorMessages: [] }
        if (organization === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Organization cannot be blank');
        }
        if (country === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Country cannot be blank');
        }
        if (teamCategory === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Team Category cannot be blank');
        }
        if (teamName === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Team Name cannot be blank');
        }
        if (teamContactNameFirst === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Team Contact First Name cannot be blank');
        }
        if (teamContactNameLast === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Team Contact Last Name cannot be blank');
        }
        if (teamContactPhone === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Team Contact Phone cannot be blank');
        }
        if (sponsorFirstName === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Sponsor First Name cannot be blank');
        }
        if (sponsorLastName === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Sponsor Last Name cannot be blank');
        }
        if (sponsorTitle === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Sponsor Title cannot be blank');
        }
        if (sponsorCompany === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Sponsor Company cannot be blank');
        }
        if (sponsorEmail === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Sponsor Email cannot be blank');
        }
        if (sponsorship === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Sponsorship cannot be blank');
        }

        return validCheck;
    }

    const additionalMembersCallback = useCallback((value) => {
        dispatch({
            type: "value_changed",
            keyName: 'additionalMembers',
            keyValue: value
        })
    }, [state])

    const createSponsorRegistration = () => {
        const {
            organization,
            country,
            teamCategory,
            teamName,
            teamContactNameFirst,
            teamContactNameLast,
            teamContactEmail,
            teamContactPhone,
            billingEmail,
            additionalMembers,
            sponsorFirstName,
            sponsorLastName,
            sponsorTitle,
            sponsorCompany,
            sponsorEmail,
            sponsorship,
        } = state
        const data = {
            organization,
            country,
            teamCategory,
            teamName,
            teamContactNameFirst,
            teamContactNameLast,
            teamContactEmail,
            teamContactPhone,
            billingEmail,
            additionalMembers,
            sponsorFirstName,
            sponsorLastName,
            sponsorTitle,
            sponsorCompany,
            sponsorEmail,
            sponsorship,
        }
        firebase.insertSponsorRegistration (props.competition, data)
    }
    const patchSponsorRegistration = () => {
        const {
            organization,
            country,
            teamCategory,
            teamName,
            teamContactNameFirst,
            teamContactNameLast,
            teamContactEmail,
            teamContactPhone,
            billingEmail,
            additionalMembers,
            sponsorFirstName,
            sponsorLastName,
            sponsorTitle,
            sponsorCompany,
            sponsorEmail,
            sponsorship,
        } = state
        const data = {
            organization,
            country,
            teamCategory,
            teamName,
            teamContactNameFirst,
            teamContactNameLast,
            teamContactEmail,
            teamContactPhone,
            billingEmail,
            additionalMembers,
            sponsorFirstName,
            sponsorLastName,
            sponsorTitle,
            sponsorCompany,
            sponsorEmail,
            sponsorship,
        }
        firebase.patchSponsorRegistration(selectedCompetition, state.id, data)
    }

    const submitButtonPressed = useCallback((e) => {
        e.preventDefault()
        const isValidCheck = isValid(state)
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: isValidCheck.errorMessages
        })

        if (!isValidCheck.result) return;

        if (state.id) {
            patchSponsorRegistration();
        } else {
            createSponsorRegistration();
        }

        window.location.reload(false);

    }, [state])

    return (
        <Grid2 container spacing={3} flexDirection={'column'} sx={{ maxWidth: '800px', padding: '20px' }}>
            <Grid2 item>
                <Typography variant='h4' gutterBottom>Sponsor Registration</Typography>
            </Grid2>
            
            <Grid2 item>
                <Typography variant='body1' sx={{ color: 'red' }}>
                    SPONSOR REGISTRATION FOR THE 2025 COMPETITION WILL OPEN OCTOBER 2024.
                </Typography>
                <Typography variant='body1'>
                    This form is for sponsor registration; individual competitor names are not required. All individuals that need access to the competition floor (competitors, coaches, visitors, etc.) must also register for an MRO Americas floor pass (see link under Register menu).
                </Typography>
            </Grid2>

            <Grid2 container spacing={2}>
                {/* Organization and Country */}
                <Grid2 item xs={12} md={12} lg={12}><Typography variant='h4'>Team Information</Typography></Grid2>
                <Grid2 item xs={6} md={6}>
                    <TextField
                        fullWidth
                        disabled={isView}
                        value={state.organization}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'organization'
                            });
                        }}
                        label="Organization"
                    />
                </Grid2>
                <Grid2 item xs={6} md={6}>
                    <TextField
                        fullWidth
                        disabled={isView}
                        value={state.country}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'country'
                            });
                        }}
                        label="Country"
                    />
                </Grid2>

                {/* Team Category */}
                <Grid2 item xs={12}>
                    <FormControl fullWidth disabled={isView}>
                        <InputLabel>Team Category</InputLabel>
                        <Select
                            value={state.teamCategory}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'teamCategory'
                                });
                            }}
                        >
                            <MenuItem value="">Select One</MenuItem>
                            <MenuItem value="COMMERCIAL_AIRLINE">Commercial Airline ($1,000)</MenuItem>
                            <MenuItem value="GENERAL_AVIATION">General Aviation ($1,000)</MenuItem>
                            <MenuItem value="MILITARY">Military ($0)</MenuItem>
                            <MenuItem value="MRO_OEM">MRO/OEM ($1,000)</MenuItem>
                            <MenuItem value="SCHOOL">School ($500)</MenuItem>
                            <MenuItem value="SPACE">Space ($1,000)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>

                {/* Team Name */}
                <Grid2 item xs={12}>
                    <TextField
                        fullWidth
                        disabled={isView}
                        value={state.teamName}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'teamName'
                            });
                        }}
                        label="Team Name"
                    />
                </Grid2>

                {/* Team Contact Details */}
                <Grid2 item xs={12} md={12} lg={12}><Typography variant='h4'>Team Contact</Typography></Grid2>
                <Grid2 container item xs={12} spacing={2}>
                    <Grid2 item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={isView}
                            value={state.teamContactNameFirst}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value.trim(),
                                    keyName: 'teamContactNameFirst'
                                });
                            }}
                            label="First Name"
                        />
                    </Grid2>
                    <Grid2 item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={isView}
                            value={state.teamContactNameLast}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value.trim(),
                                    keyName: 'teamContactNameLast'
                                });
                            }}
                            label="Last Name"
                        />
                    </Grid2>
                </Grid2>

                <Grid2 item xs={12}>
                    <TextField
                        fullWidth
                        disabled={isView}
                        value={state.teamContactEmail}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'teamContactEmail'
                            });
                        }}
                        label="Team Contact Email"
                    />
                </Grid2>

                <Grid2 item xs={12}>
                    <TextField
                        fullWidth
                        disabled={isView}
                        value={state.teamContactPhone}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'teamContactPhone'
                            });
                        }}
                        label="Team Contact Phone"
                    />
                </Grid2>

                <Grid2 item xs={12}>
                    <TextField
                        fullWidth
                        disabled={isView}
                        value={state.billingEmail}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'billingEmail'
                            });
                        }}
                        label="Billing Email (if different than above)"
                    />
                </Grid2>

                {/* Sponsor Information */}
                <Grid2 item xs={12} md={12} lg={12}><Typography variant='h4'>Sponsor Information</Typography></Grid2>
                <Grid2 container item xs={12} spacing={2}>
                    <Grid2 item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={isView}
                            value={state.sponsorFirstName}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value.trim(),
                                    keyName: 'sponsorFirstName'
                                });
                            }}
                            label="Sponsor First Name"
                        />
                    </Grid2>
                    <Grid2 item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={isView}
                            value={state.sponsorLastName}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value.trim(),
                                    keyName: 'sponsorLastName'
                                });
                            }}
                            label="Sponsor Last Name"
                        />
                    </Grid2>
                </Grid2>

                <Grid2 container item xs={12} spacing={2}>
                    <Grid2 item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={isView}
                            value={state.sponsorTitle}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value.trim(),
                                    keyName: 'sponsorTitle'
                                });
                            }}
                            label="Title"
                        />
                    </Grid2>
                    <Grid2 item xs={12} md={6}>
                        <TextField
                            fullWidth
                            disabled={isView}
                            value={state.sponsorCompany}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value.trim(),
                                    keyName: 'sponsorCompany'
                                });
                            }}
                            label="Company"
                        />
                    </Grid2>
                </Grid2>

                <Grid2 item xs={12}>
                    <TextField
                        fullWidth
                        value={state.sponsorEmail}
                        disabled={isView}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value.trim(),
                                keyName: 'sponsorEmail'
                            });
                        }}
                        label="Sponsor Email"
                    />
                </Grid2>

                {/* Sponsorship Level */}
                <Grid2 item xs={12}>
                    <FormControl fullWidth disabled={isView}>
                        <InputLabel>Sponsorship Level</InputLabel>
                        <Select
                            value={state.sponsorship}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'sponsorship'
                                });
                            }}
                        >
                            <MenuItem value="BRONZE">Bronze ($5,000)</MenuItem>
                            <MenuItem value="SILVER">Silver ($8,000)</MenuItem>
                            <MenuItem value="GOLD">Gold ($10,000)</MenuItem>
                            <MenuItem value="PLATINUM">Platinum ($50,000)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>

                <Grid2 item xs={12} md={12} lg={12}><Typography variant='h4'>Initial Team Members</Typography></Grid2>

                {/* Additional Members */}
                <Grid2 item xs={12}>
                    <AdditionalMembers callback={additionalMembersCallback} members={state.additionalMembers || []} isView={isView} />
                </Grid2>

                {/* Additional Information */}
                <Grid2 item xs={12}>
                    <Typography variant='body1'>
                        By submitting this form, I understand that all team competitors will be required to agree to the Terms and Conditions of Participation and acknowledge the AMC Privacy Policy and Media Policy.
                    </Typography>
                    <Typography variant='body1'>
                        The deadline to receive a refund for your team's registration is 90 days before the event. Registration cancellations received prior to the deadline are eligible for a refund less a $50 service fee.
                    </Typography>
                </Grid2>

                {/* Submit Button */}
                <Grid2 item xs={12}>
                    <Button variant="contained" color="primary" onClick={submitButtonPressed} disabled={isView}>Submit</Button>
                </Grid2>

                {/* Error Messages */}
                <Grid2 item xs={12}>
                    <FormErrorMessages errorMessages={state.errorMessages} />
                </Grid2>
            </Grid2>
        </Grid2>
    )
}
function AdditionalMembers(props) {
    const [members, setMembers] = useState(props.members);

    const addAdditionalMember = () => {
        setMembers(members.concat(''))
    }
    const removeMember = useCallback((key) => {
        setMembers(members.filter((_,k) => {
            return k != key
        }))
    }, [members])
    useEffect(() => {
        props.callback(members)
    }, [members])

    useEffect(() => {
        setMembers(props.members);
    }, [props]);

    return (
        <Grid2>
            <Button onClick={addAdditionalMember} disabled={props.isView}>Add Additional Team Member</Button>
            {
                members.map((v, k) => {
                    return (
                        <>
                        <div>
                        <Button disabled={props.isView} onClick={() => {
                            removeMember(k)
                        }}>Remove Member</Button>
                        <TextField
                            sx={{ flex: 1 }}
                            value={v}
                            disabled={props.isView}
                            onChange={(e) => {
                                const key = k
                                setMembers(members.map((mv,mk) => {
                                    if (mk == key) {
                                        return e.target.value
                                    }
                                    return mv
                                }))
                            }}
                            label="Team Member Email"
                        />
                        </div>
                        <br />
                        </>
                    )
                })
            }

        </Grid2>
    )
}
export default TeamRegistrationForm
