import React, { useEffect, useContext, useMemo, useReducer } from 'react';

import { Tab, Tabs, Typography } from '@mui/material';
import { CommonDataContext } from '../firebase/CommonDataContext';
import { FirebaseNewContext } from '../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import { UserDataContext } from '../firebase/UserDataContext';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { TeamDropdown } from '../components/forms/Dropdowns/TeamDropdown';
import { EventDropdown } from '../components/forms/Dropdowns/EventDropdown';
import CategoryDropdown from '../components/forms/Dropdowns/CategoryDropdown';
import DivisionDropdown from '../components/forms/Dropdowns/DivisionDropdown';
import { avgByCategoryInEvent, avgByDivisionInEvent, avgOverallInEvent, getCategoryTopOverallInEvent, getEventCategoryPlaceInEvent, getFinalScoresByTeam, getOverallTopInEvent, getPlacementOverallInEvent, groupedEventsScores, getPlacementOverAllEventsAndCategories } from '../components/misc/Utils';

const resultsPageInitialState = (_) => {
    return {
        eventsScores: {},
        teamsDetails: {},
        byTeam: '',
        byEvent: '',
        byCategory: '',
        byDivision: '',
        byHighestCategory: '',
        byHighestEvent: ''
    }

}
const resultsPageReducer = (state, action) => {
    switch (action.type) {
        case 'values_changed':
            return {
                ...state,
                ...action.value
            }
        case 'by_team_changed':
            return {
                ...state,
                byTeam: action.value || ''
            }
        case 'by_event_changed':
            return {
                ...state,
                byEvent: action.value || ''
            }
        case 'by_category_changed':
            return {
                ...state,
                byCategory: action.value || ''
            }
        case 'by_division_changed':
            return {
                ...state,
                byDivision: action.value || ''
            }
        case 'by_highest_category_changed':
            return {
                ...state,
                byHighestCategory: action.value || ''
            }
        case 'by_highest_event_changed':
            return {
                ...state,
                byHighestEvent: action.value || ''
            }
        default:
            break
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Grid2 sx={{ p: 3 }}>
                    {children}
                </Grid2>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const overallStandingsColumns = [
    { field: 'col1', headerName: 'Team Number ', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 150 },
    { field: 'col4', headerName: 'Division', width: 150 },
    { field: 'col5', headerName: 'Final Score', width: 150, getApplyQuickFilterFn: () => null },
    { field: 'col6', headerName: 'Place', width: 150, getApplyQuickFilterFn: () => null }
]
const byTeamColumns = [
    { field: 'col1', headerName: 'Event Number', width: 125 },
    { field: 'col2', headerName: 'Event Name', width: 250 },
    { field: 'col3', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col4', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col5', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col6', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col7', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col8', headerName: 'Category Place', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col9', headerName: 'Overall Place', width: 125, getApplyQuickFilterFn: () => null }
]
const byEventColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 150 },
    { field: 'col4', headerName: 'Division', width: 150 },
    { field: 'col5', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col6', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col7', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col8', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col9', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col10', headerName: 'Place', width: 125, getApplyQuickFilterFn: () => null }
]
const byCategoryColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 150 },
    { field: 'col4', headerName: 'Division', width: 150 },
    { field: 'col5', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
]
const byDivisionColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 150 },
    { field: 'col4', headerName: 'Division', width: 150 },
    { field: 'col5', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
]
const byHighestPlacingByCategoryColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 225 },
    { field: 'col3', headerName: 'Category', width: 125 },
    { field: 'col4', headerName: 'Overall Category Place', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col5', headerName: 'Overall Place', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col6', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null }
]

const byOverallWinnersColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 125 },
    { field: 'col4', headerName: 'Event Number', width: 125 },
    { field: 'col5', headerName: 'Event Name', width: 225 },
    { field: 'col6', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col7', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col8', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col9', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col10', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col11', headerName: 'Place', width: 125, getApplyQuickFilterFn: () => null },
]
const byOverallSchoolWinnersColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 125 },
    { field: 'col4', headerName: 'Event Number', width: 125 },
    { field: 'col5', headerName: 'Event Name', width: 225 },
    { field: 'col6', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col7', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col8', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col9', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col10', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col11', headerName: 'Place', width: 125, getApplyQuickFilterFn: () => null },
]
const byTopSixForEachEventColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 125 },
    { field: 'col4', headerName: 'Event Number', width: 125 },
    { field: 'col5', headerName: 'Event Name', width: 225 },
    { field: 'col6', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col7', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col8', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col9', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col10', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col11', headerName: 'Place', width: 125, getApplyQuickFilterFn: () => null },
]
const byMissingScoresColumns = [
    { field: 'col1', headerName: 'Event Number', width: 125 },
    { field: 'col2', headerName: 'Event Name', width: 250 },
    { field: 'col3', headerName: 'Team Number', width: 125 },
    { field: 'col4', headerName: 'Team Name', width: 225 },
]

const by1500Columns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 125 },
    { field: 'col4', headerName: 'Event Number', width: 125 },
    { field: 'col5', headerName: 'Event Name', width: 225 },
    { field: 'col6', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col7', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col8', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col9', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col10', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col11', headerName: 'Place', width: 125, getApplyQuickFilterFn: () => null },
]
const byEventCategoryColumns = [
    { field: 'col1', headerName: 'Team Number', width: 125 },
    { field: 'col2', headerName: 'Team Name', width: 250 },
    { field: 'col3', headerName: 'Category', width: 150 },
    { field: 'col4', headerName: 'Division', width: 150 },
    { field: 'col5', headerName: 'Final Score', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col6', headerName: 'Category Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col7', headerName: 'Overall Avg', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col8', headerName: 'Category Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col9', headerName: 'Overall Top', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col10', headerName: 'Place', width: 125, getApplyQuickFilterFn: () => null },
    { field: 'col11', headerName: 'Initial Place', width: 125, getApplyQuickFilterFn: () => null }
]
const ResultsPage = (props) => {
    const [state, dispatch] = useReducer(resultsPageReducer, props, resultsPageInitialState)
    const [value, setValue] = React.useState(0);
    const handleChange = (_, newValue) => {
        setValue(newValue);
    };

    const { globalSettings } = useContext(GlobalSettingsContext)
    const { user, defaultSelectedCompetition } = useContext(UserDataContext)

    const { firebase } = useContext(FirebaseNewContext)
    const { teamsMap: globalTeamsMap, eventsMap: globalEventsMap } = useContext(CommonDataContext)
    const teamsMap = useMemo(() => {
        return globalTeamsMap[defaultSelectedCompetition]
    })
    const eventsMap = useMemo(() => {
        // TODO - exclude the events which should be excluded
        return globalEventsMap[defaultSelectedCompetition]
    })
    const categoriesMap = useMemo(() => {
        if (Object.keys(globalSettings).length != 0) {
            return globalSettings['categories'][defaultSelectedCompetition]
        }
        return {}
    }, [globalSettings])
    const divisionsMap = useMemo(() => {
        if (Object.keys(globalSettings).length != 0) {
            return globalSettings['divisions'][defaultSelectedCompetition]
        }
        return {}
    }, [globalSettings])

    useEffect(() => {
        const fetch = async () => {
            if (defaultSelectedCompetition !== '' && Object.keys(teamsMap).length != 0) {
                const scoresMapResult = await firebase.getScores(defaultSelectedCompetition)
                // NOTE  - our dupe finder
                //const dupeFinder = Object.keys(scoresMapResult).reduce((acc,v) => {
                //    const myScore  = scoresMapResult[v]
                //    if (! acc[myScore.team.teamNumber] ) {
                //        acc[myScore.team.teamNumber] = { }
                //    }

                //    if (! acc[myScore.team.teamNumber][myScore.event.number]) {
                //            acc[myScore.team.teamNumber][myScore.event.number] = {
                //                total: 0,
                //                scoreId: []
                //            }
                //    }
                //    //console.log(myScore, v)
                //    //console.log('before we crash', acc)
                //    acc[myScore.team.teamNumber][myScore.event.number]['total'] = acc[myScore.team.teamNumber][myScore.event.number]['total'] + 1
                //    acc[myScore.team.teamNumber][myScore.event.number]['scoreId'].push(v)

                //    return acc
                //}, {})
                //const foundDupes = Object.keys(dupeFinder).reduce((acc, v) => {
                //    const team = dupeFinder[v]
                //    const inner = Object.keys(team).reduce((innerAcc, w) => {
                //        const event = team[w]
                //        console.log(event)
                //        if (event.total > 1) {
                //            console.log('we have a dupe', event)
                //        }


                //    }, {})
                //    return acc
                //}, {})
                //console.log(dupeFinder)
                // TEAM
                getFinalScoresByTeam(scoresMapResult, teamsMap, eventsMap)
                // EVENT
                const eventScoresMap = groupedEventsScores(scoresMapResult, eventsMap)
                avgByCategoryInEvent(eventScoresMap, categoriesMap)
                avgByDivisionInEvent(eventScoresMap, divisionsMap)
                avgOverallInEvent(eventScoresMap)
                getOverallTopInEvent(eventScoresMap)
                getCategoryTopOverallInEvent(eventScoresMap, categoriesMap)
                getEventCategoryPlaceInEvent(eventScoresMap, categoriesMap)
                getPlacementOverallInEvent(eventScoresMap)

                // TEAM
                getPlacementOverAllEventsAndCategories(eventScoresMap, teamsMap, categoriesMap)
                dispatch({
                    type: 'values_changed',
                    value: {
                        eventsScores: eventScoresMap,
                        teamsDetails: teamsMap
                    }
                })

                console.log(eventScoresMap);
            }
        }
        fetch().catch(console.error)

    }, [])

    const overallStandings = (value, index) => {
        if (value !== index) {
            return false
        }
        const rows = Object.keys(state.teamsDetails).map((t) => {
            const team = state.teamsDetails[t]
            return {
                id: t,
                col1: team.teamNumber,
                col2: team.teamName,
                col3: categoriesMap[team.category],
                col4: divisionsMap[team.division],
                col5: team.finalScore,
                col6: team.place

            }
        })
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={overallStandingsColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }
    const byTeam = (value, index, teamUid) => {
        // TODO - this throws an error if you attempt to select a team when no scores
        //  for the competition have been entered in
        if (value !== index) {
            return false
        }
        if (teamUid == '') {
            return (<Typography>No Team Selected</Typography>)
        }

        let rows = Object.keys(state.eventsScores).map((e) => {
            const event = state.eventsScores[e]
            const targetScore = event.scores.find(x => x.teamUid == teamUid)
            if (!targetScore) {
                return false
            }
            return {
                id: event.event.number,
                col1: event.event.number,
                col2: `${event.event.name} ${event.event.company}`,
                col3: targetScore.finalScore,
                col4: event.categoriesAvg[targetScore.team.category],
                col5: event.avgOverall,
                col6: event.categoryTop[targetScore.team.category],
                col7: event.overallTopInEvent,
                col8: targetScore.categoryPlace,
                col9: targetScore.overallPlace

            }
        })

        rows = rows.filter(x => x != false)
        //if (rows.filter(x => x != false).length !== Object.keys(state.eventsScores).length) {
        //    return (
        //        <Typography>All scores for the team have not been entered</Typography>
        //    )
        //}
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <Typography>Team Number: {state.teamsDetails[teamUid]['teamNumber']}</Typography>
                <Typography>Category: {categoriesMap[state.teamsDetails[teamUid]['category']]}</Typography>
                <Typography>Division: {divisionsMap[state.teamsDetails[teamUid]['division']]}</Typography>
                <Typography>Overall Place: {state.teamsDetails[teamUid]['place']}</Typography>
                <Typography>Category Place: {state.teamsDetails[teamUid]['categoryPlace']}</Typography>
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byTeamColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }
    const byEvent = (value, index, eventUid) => {
        if (value !== index) {
            return false
        }
        if (eventUid == '') {
            return (<Typography>No Event Selected</Typography>)
        }
        const event = state.eventsScores[eventUid]
        // NOTE - event would be empty when an event is present but no scorse are entered
        const rows = !event ? [] : event.scores.map((s) => {

            const randomNumber = Math.floor(Math.random() * 10000000) + 1;
            return {
                id: `${s.teamUid}-${randomNumber}`,
                col1: s.team.teamNumber,
                col2: s.team.teamName,
                col3: categoriesMap[s.team.category],
                col4: divisionsMap[s.team.division],
                col5: s.finalScore,
                col6: event.categoriesAvg[s.team.category],
                col7: event.avgOverall,
                col8: event.categoryTop[s.team.category],
                col9: event.overallTopInEvent,
                col10: s.overallPlace
            }

        })
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byEventColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }
    const byCategory = (value, index, categoryUid) => {
        if (value !== index) {
            return false
        }
        if (categoryUid == '') {
            return (<Typography>No Category Selected</Typography>)
        }
        const rows = Object.keys(state.teamsDetails).reduce((accTeam, t) => {
            const team = state.teamsDetails[t]
            if (team.category !== categoryUid) {
                return accTeam
            }
            accTeam = accTeam.concat({
                id: t,
                col1: team.teamNumber,
                col2: team.teamName,
                col3: categoriesMap[team.category],
                col4: divisionsMap[team.division],
                col5: team.finalScore

            })
            return accTeam
        }, [])

        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byCategoryColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }
    const byDivision = (value, index, divisionUid) => {
        if (value !== index) {
            return false
        }
        if (divisionUid == '') {
            return (<Typography>No Division Selected</Typography>)
        }

        const rows = Object.keys(state.teamsDetails).reduce((accTeam, t) => {
            const team = state.teamsDetails[t]
            if (team.division !== divisionUid) {
                return accTeam
            }
            accTeam = accTeam.concat({
                id: t,
                col1: team.teamNumber,
                col2: team.teamName,
                col3: categoriesMap[team.category],
                col4: divisionsMap[team.division],
                col5: team.finalScore

            })
            return accTeam
        }, [])

        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byDivisionColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }
    const byHighestPlacingByCategory = (value, index) => {
        if (value !== index) {
            return false
        }
        const rows = Object.keys(state.teamsDetails).reduce((acc, t) => {
            const team = state.teamsDetails[t]
            if (team.teamNumber == '15') {
                //console.log('THIS IS OUR TEAM number 15', team)

            }
            if ([1, 2, 3, 4, 5, 6].includes(team.categoryPlace)) {
                acc = acc.concat({
                    id: t,
                    col1: team.teamNumber,
                    col2: team.teamName,
                    col3: categoriesMap[team.category],
                    col4: team.categoryPlace,
                    col5: team.place,
                    col6: team.finalScore
                })
            }

            return acc
        }, [])
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byHighestPlacingByCategoryColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )
    }
    const byOverallWinners = (value, index) => {
        if (value !== index) {
            return false
        }
        const rows = Object.keys(state.eventsScores).map((e, k) => {
            const event = state.eventsScores[e]
            const scores = event.scores
            return scores.reduce((acc, s) => {
                if (s.overallPlace == 1) {
                    const randomNumber = Math.floor(Math.random() * 10000000) + 1;
                    const theId = `${e}-${k}-${randomNumber}`
                    acc = {
                        id: theId,
                        col1: s.team.teamNumber,
                        col2: s.team.teamName,
                        col3: categoriesMap[s.team.category],
                        col4: s.event.number,
                        col5: `${s.event.name} ${s.event.company}`,
                        col6: s.finalScore,
                        col7: event.categoriesAvg[s.team.category],
                        col8: event.avgOverall,
                        col9: event.categoryTop[s.team.category],
                        col10: event.overallTopInEvent,
                        col11: s.overallPlace
                    }
                }

                return acc
            }, {})

        })
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows.filter(x => Object.keys(x).length != 0)}
                    columns={byOverallWinnersColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }
    const byOverallWinnersNonAffiliated = (value, index) => {
        if (value !== index) {
            return false
        }
        // get our event associated teams
        const affiliatedEventsToTeams = Object.keys(eventsMap||{}).reduce((acc, v) => {
            const event = eventsMap[v]
            if (event.associatedTeams) {
                acc[v] = event.associatedTeams.map(a => a.uid)
            }
            return acc
        }, {})
        // for each individual score does a teamuid have 
        const rows = Object.keys(state.eventsScores).map((e, k) => {
            const event = state.eventsScores[e]
            const scores = event.scores
            const nonAffiliatedScores = scores.reduce((acc, s) => {
                if (affiliatedEventsToTeams[e] && affiliatedEventsToTeams[e].includes(s.teamUid)) {
                    return acc
                }
                acc = acc.concat(s)

                return acc
            }, [])
            const nonAffiliatedScoresSorted = [...nonAffiliatedScores].sort((a, b) => {
                return a.finalScore - b.finalScore
            })
            return nonAffiliatedScoresSorted.reduce((acc, s, index) => {
                if (index === 0) {
                    const randomNumber = Math.floor(Math.random() * 10000000) + 1;
                    const theId = `${e}-${k}-${randomNumber}`
                    acc = {
                        id: theId,
                        col1: s.team.teamNumber,
                        col2: s.team.teamName,
                        col3: categoriesMap[s.team.category],
                        col4: s.event.number,
                        col5: `${s.event.name} ${s.event.company}`,
                        col6: s.finalScore,
                        col7: event.categoriesAvg[s.team.category],
                        col8: event.avgOverall,
                        col9: event.categoryTop[s.team.category],
                        col10: event.overallTopInEvent,
                        col11: s.overallPlace
                    }
                }

                return acc
            }, {})

        })
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows.filter(x => Object.keys(x).length != 0)}
                    columns={byOverallWinnersColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )
    }
    const byOverallSchoolWinners = (value, index) => {

        if (value !== index) {
            return false
        }
        const rows = Object.keys(state.eventsScores).map((e, k) => {
            const event = state.eventsScores[e]
            const scores = event.scores
            const schoolScores = scores.reduce((acc, s) => {
                if (categoriesMap[s.team.category] == 'School') {
                    acc = acc.concat(s)
                }
                return acc
            }, [])
            const schoolScoresSorted = [...schoolScores].sort((a, b) => {
                return a.finalScore - b.finalScore
            })
            return schoolScoresSorted.reduce((acc, s, index) => {
                if (index === 0) {
                    const randomNumber = Math.floor(Math.random() * 10000000) + 1;
                    const theId = `${e}-${k}-${randomNumber}`
                    acc = {
                        id: theId,
                        col1: s.team.teamNumber,
                        col2: s.team.teamName,
                        col3: categoriesMap[s.team.category],
                        col4: s.event.number,
                        col5: `${s.event.name} ${s.event.company}`,
                        col6: s.finalScore,
                        col7: event.categoriesAvg[s.team.category],
                        col8: event.avgOverall,
                        col9: event.categoryTop[s.team.category],
                        col10: event.overallTopInEvent,
                        col11: s.overallPlace
                    }
                }

                return acc
            }, {})

        })
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows.filter(x => Object.keys(x).length != 0)}
                    columns={byOverallSchoolWinnersColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }
    const byTopSixForEachEvent = (value, index) => {

        if (value !== index) {
            return false
        }
        const rows = Object.keys(state.eventsScores).reduce((acc, e) => {
            const event = state.eventsScores[e]
            const scores = event.scores
            scores.forEach((s) => {
                if ([1, 2, 3, 4, 5, 6].includes(s.overallPlace)) {
                    acc = acc.concat({
                        id: `${e}-${s.teamUid}`,
                        col1: s.team.teamNumber,
                        col2: s.team.teamName,
                        col3: categoriesMap[s.team.category],
                        col4: s.event.number,
                        col5: `${s.event.name} ${s.event.company}`,
                        col6: s.finalScore,
                        col7: event.categoriesAvg[s.team.category],
                        col8: event.avgOverall,
                        col9: event.categoryTop[s.team.category],
                        col10: event.overallTopInEvent,
                        col11: s.overallPlace
                    })
                }
            })

            return acc
        }, [])
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byTopSixForEachEventColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )
    }
    const byMissingScores = (value, index) => {
        if (value !== index) {
            return false
        }
        // NOTE - Only events which have at least 1 score entered in will show up for everyone else
        const allTeamUids = Object.keys(teamsMap)
        const rows = Object.keys(state.eventsScores).reduce((acc, e) => {
            const event = state.eventsScores[e]
            const scores = event.scores
            const teamScoreUids = scores.map(x => x.teamUid)
            const missingTeams = allTeamUids.filter(x => teamScoreUids.indexOf(x) == -1)
            if (missingTeams.length != 0) {
                missingTeams.forEach((teamUid, k) => {
                    const randomNumber = Math.floor(Math.random() * 10000000) + 1;
                    acc = acc.concat({
                        id: `${e}-${teamUid}-${randomNumber}`,
                        col1: event.event.number,
                        col2: `${event.event.name} ${event.event.company}`,
                        col3: teamsMap[teamUid]['teamNumber'],
                        col4: teamsMap[teamUid]['teamName']
                    })

                })

            }
            return acc
        }, [])

        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byMissingScoresColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }

    const by1500 = (value, index) => {
        if (value !== index) {
            return false
        }
        const rows = Object.keys(state.eventsScores).reduce((acc, e) => {
            const event = state.eventsScores[e]
            const scores = event.scores
            const biiigScores = scores.filter(x => x.finalScore == 1500)

            if (biiigScores.length != 0) {
                biiigScores.forEach((s) => {
                    acc = acc.concat({
                        id: `${s.teamUid}${s.eventUid}`,
                        col1: s.team.teamNumber,
                        col2: s.team.teamName,
                        col3: categoriesMap[s.team.category],
                        col4: s.event.number,
                        col5: `${s.event.name} ${s.event.company}`,
                        col6: s.finalScore,
                        col7: event.categoriesAvg[s.team.category],
                        col8: event.avgOverall,
                        col9: event.categoryTop[s.team.category],
                        col10: event.overallTopInEvent,
                        col11: s.overallPlace
                    })
                })
            }
            return acc

        }, [])
        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows.filter(x => Object.keys(x).length != 0)}
                    columns={by1500Columns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )
    }

    const byHighestInCategory = (value, index, eventUid, categoryUid) => {
        if (value !== index) {
            return false
        }

        if (eventUid == '') {
            return (<Typography>No Event Selected</Typography>)
        }

        if (categoryUid == '') {
            return (<Typography>No Category Selected</Typography>)
        }

        const event = state.eventsScores[eventUid]
        
        let currentPlace = 0;
        let previousOverallPlace = null;

        const rows = !event ? [] : event.scores
            .filter((s) => s.team.category === categoryUid)
            .sort((a, b) => a.overallPlace - b.overallPlace)
            .map((s, index) => {
                const randomNumber = Math.floor(Math.random() * 10000000) + 1;

                if (previousOverallPlace !== s.overallPlace) {
                    previousOverallPlace = s.overallPlace;
                    currentPlace++;
                }

                return {
                    id: `${s.teamUid}-${randomNumber}`,
                    col1: s.team.teamNumber,
                    col2: s.team.teamName,
                    col3: categoriesMap[s.team.category],
                    col4: divisionsMap[s.team.division],
                    col5: s.finalScore,
                    col6: event.categoriesAvg[s.team.category],
                    col7: event.avgOverall,
                    col8: event.categoryTop[s.team.category],
                    col9: event.overallTopInEvent,
                    col10: currentPlace,
                    col11: s.overallPlace
                };
            });



        return (
            <Grid2 style={{ height: '500px', width: '100%' }} >
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={rows}
                    columns={byEventCategoryColumns}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        )

    }

    const byOverallEventScores = (value, index) => {
        if (value !== index) {
            return false
        }

        const eventRows = Object.keys(state.eventsScores).map((eventUid) => {
            const event = state.eventsScores[eventUid];
            return {
                id: eventUid,
                col1: event.event.fullTitle, // Assuming event has a name property
                col2: event.avgOverall, // The average overall score for the event
            };
        });

        console.log(state.eventsScores);

        return (
            <Grid2 style={{ height: '500px', width: '100%' }}>
                <DataGrid
                    sx={{ marginBottom: '25px' }}
                    rows={eventRows}
                    columns={[
                        { field: 'col1', headerName: 'Event Name', flex: 1 },
                        { field: 'col2', headerName: 'Average Score', flex: 1 },
                    ]}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                        },
                    }}
                />
            </Grid2>
        );
    };

    return (
        <Grid2>
            <Grid2 sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                >
                    <Tab label="Overall Standings" {...a11yProps(0)} />
                    <Tab label="By Teams" {...a11yProps(1)} />
                    <Tab label="By Events" {...a11yProps(2)} />
                    <Tab label="By Categories" {...a11yProps(3)} />
                    <Tab label="By Divisions" {...a11yProps(4)} />
                    <Tab label="Highest Placing Category" {...a11yProps(5)} />
                    <Tab label="Overall Winners" {...a11yProps(6)} />
                    <Tab label="Overall Winners Non Affiliated" {...a11yProps(7)} />
                    <Tab label="Overall School Winners" {...a11yProps(8)} />
                    <Tab label="Top 6 For Each Event" {...a11yProps(9)} />
                    <Tab label="Missing Scores" {...a11yProps(10)} />
                    <Tab label="Scores With 1500" {...a11yProps(11)} />
                    <Tab label="Highest in Category" {...a11yProps(12)} />
                    <Tab label="Overall Event Scores" {...a11yProps(13)} />
                </Tabs>
            </Grid2>
            <TabPanel value={value} index={0}>
                {overallStandings(value, 0)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TeamDropdown
                    teamsMapValues={teamsMap}
                    value={state.byTeam}
                    onChange={(e, selected) => {
                        e.preventDefault()
                        dispatch({
                            type: 'by_team_changed',
                            value: selected.uid
                        })
                    }}
                />
                {byTeam(value, 1, state.byTeam)}
            </TabPanel>
            <TabPanel value={value} index={2}>
                <EventDropdown
                    eventsMapValues={eventsMap}
                    value={state.byEvent}
                    onChange={(e, selected) => {
                        e.preventDefault()
                        dispatch({
                            type: 'by_event_changed',
                            value: selected.uid
                        })
                    }}
                />
                {byEvent(value, 2, state.byEvent)}
            </TabPanel>
            <TabPanel value={value} index={3}>
                <CategoryDropdown
                    categoriesMapValues={categoriesMap}
                    value={state.byCategory}
                    onChange={(e, selected) => {
                        e.preventDefault()
                        dispatch({
                            type: 'by_category_changed',
                            value: selected
                        })
                    }}
                />
                {byCategory(value, 3, state.byCategory)}
            </TabPanel>
            <TabPanel value={value} index={4}>
                <DivisionDropdown
                    divisionsMapValues={divisionsMap}
                    value={state.byDivision}
                    onChange={(e, selected) => {
                        e.preventDefault()
                        dispatch({
                            type: 'by_division_changed',
                            value: selected
                        })
                    }}
                />
                {byDivision(value, 4, state.byDivision)}
            </TabPanel>
            <TabPanel value={value} index={5}>
                {byHighestPlacingByCategory(value, 5)}
            </TabPanel>
            <TabPanel value={value} index={6}>
                {byOverallWinners(value, 6)}
            </TabPanel>
            <TabPanel value={value} index={7}>
                {byOverallWinnersNonAffiliated(value, 7)}
            </TabPanel>
            <TabPanel value={value} index={8}>
                {byOverallSchoolWinners(value, 8)}
            </TabPanel>
            <TabPanel value={value} index={9}>
                {byTopSixForEachEvent(value, 9)}
            </TabPanel>
            <TabPanel value={value} index={10}>
                {byMissingScores(value, 10)}
            </TabPanel>
            <TabPanel value={value} index={11}>
                {by1500(value, 11)}
            </TabPanel>
            <TabPanel value={value} index={12}>
                <EventDropdown
                    eventsMapValues={eventsMap}
                    value={state.byHighestEvent}
                    onChange={(e, selected) => {
                        e.preventDefault()
                        dispatch({
                            type: 'by_highest_event_changed',
                            value: selected.uid
                        })
                    }}
                />
                <CategoryDropdown
                    categoriesMapValues={categoriesMap}
                    value={state.byHighestCategory}
                    onChange={(e, selected) => {
                        e.preventDefault()
                        dispatch({
                            type: 'by_highest_category_changed',
                            value: selected
                        })
                    }}
                />
                {byHighestInCategory(value, 12, state.byHighestEvent, state.byHighestCategory)}
            </TabPanel>
            <TabPanel value={value} index={13}>
                {byOverallEventScores(value, 13)}
            </TabPanel>
        </Grid2>
    )
}

export default ResultsPage
