import React from 'react';
import { Drawer, Typography, TextField, Select, MenuItem, FormControl, InputLabel, Divider, Checkbox, Button, Card, CardContent } from '@mui/material';
import UploadFile from '../forms/UploadFile';
import AsyncImage from '../misc/AsyncImage';
import ScheduleBackgroundDropdown from '../forms/Dropdowns/ScheduleImages';
import newGeneralGraphic2024 from '../../images/event-image.jpg';

const EventDrawer = ({
    drawerOpen,
    selectedEvent,
    setSelectedEvent,
    newEvent,
    setNewEvent,
    handleTypeChange,
    handleSave,
    setDrawerOpen,
    deleteEvent,
    presentEvent,
    firebase,
    selectedCompetition,
}) => {
    return (
        <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={() => {
                setDrawerOpen(false);
                setSelectedEvent(null);
                setNewEvent({ type: 'Event', name: '', duration: '' });
            }}
        >
            <div style={{ width: '300px', padding: '16px' }}>
                <Typography variant="h6">
                    {selectedEvent ? 'Edit Event' : 'Add New Event'}
                </Typography>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Type</InputLabel>
                    <Select
                        value={newEvent?.type || 'Event'}
                        onChange={handleTypeChange}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: 200,
                                    overflow: 'auto',
                                },
                            },
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                        }}
                    >
                        <MenuItem value="Event">Event</MenuItem>
                        <MenuItem value="Break">Break</MenuItem>
                        <MenuItem value="Lunch">Lunch</MenuItem>
                        <MenuItem value="Custom">Custom</MenuItem>
                    </Select>
                </FormControl>
                {newEvent?.type === 'Custom' && (
                    <TextField
                        fullWidth
                        label="Event Name"
                        value={newEvent?.name}
                        onChange={(e) =>
                            setNewEvent({ ...newEvent, name: e.target.value })
                        }
                        margin="normal"
                    />
                )}
                <TextField
                    fullWidth
                    label="Duration"
                    value={newEvent?.duration}
                    onChange={(e) =>
                        setNewEvent({ ...newEvent, duration: e.target.value })
                    }
                    margin="normal"
                    type="number"
                />
                {newEvent?.type !== 'Custom' && (
                    <TextField
                        fullWidth
                        label="Event Name"
                        value={newEvent?.name}
                        onChange={(e) =>
                            setNewEvent({ ...newEvent, name: e.target.value })
                        }
                        margin="normal"
                    />
                )}

                <Divider style={{ margin: '16px 0' }} />

                <Typography variant="h6">Background Image</Typography>
                <Card variant="outlined" style={{ marginTop: '8px', padding: '16px' }}>
                    <CardContent>
                        {["Break", "Lunch"].includes(newEvent?.type) && (
                            <>
                                <Checkbox
                                    checked={!!newEvent.carousel}
                                    onChange={(e) =>
                                        setNewEvent({ ...newEvent, carousel: e.target.checked })
                                    }
                                />
                                Carousel
                            </>
                        )}

                        {!newEvent?.carousel && (
                            <>
                                <ScheduleBackgroundDropdown
                                    value={newEvent?.image || "default"}
                                    onChange={(e) => {
                                        const updatedImage = e;
                                        setNewEvent(prev => ({ ...prev, image: updatedImage }));
                                    }}
                                    fetchOptions={async () => {
                                        try {
                                            const images = await firebase.getImages(selectedCompetition);
                                            return images.length > 0 ? images : ["default"];
                                        } catch (error) {
                                            console.error("Failed to fetch images:", error);
                                            return ["default"];
                                        }
                                    }}
                                    sx={{ marginBottom: '10px' }}
                                />

                                <AsyncImage
                                    getImageSrc={async () => {
                                        if (newEvent?.image === "default") {
                                            return newGeneralGraphic2024;
                                        } else {
                                            try {
                                                const imageSrc = newEvent?.image !== undefined ? await firebase.getImage(selectedCompetition, newEvent?.image) : undefined; 
                                                return imageSrc || newGeneralGraphic2024;
                                            } catch (error) {
                                                console.error("Failed to get image:", error);
                                                return newGeneralGraphic2024;
                                            }
                                        }
                                    }}
                                    width="100%"
                                    alt="Competition Image"
                                    style={{ marginBottom: '16px', borderRadius: '4px' }}
                                />

                                <UploadFile
                                    onUpload={async (file) => {
                                        try {
                                            const uploadedImage = await firebase.uploadImage(selectedCompetition, file);
                                            setNewEvent(prev => ({ ...prev, image: uploadedImage }));
                                        } catch (error) {
                                            console.error("Failed to upload image:", error);
                                        }
                                    }}
                                    style={{ marginTop: '16px' }}
                                    buttonProps={{
                                        variant: 'contained',
                                        color: 'primary',
                                        fullWidth: true,
                                        style: { marginTop: '8px' }
                                    }}
                                />
                            </>
                        )}
                    </CardContent>
                </Card>

                <Button
                    variant="contained"
                    color="success"
                    onClick={handleSave}
                    style={{ marginTop: '16px' }}
                >
                    Save
                </Button>

                {selectedEvent && (
                    <>
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => presentEvent(selectedEvent?.originalIndex)}
                                style={{ marginTop: '16px' }}
                            >
                                Present
                            </Button>
                        </div>

                        <div>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() => deleteEvent(selectedEvent?.originalIndex)}
                                style={{ marginTop: '16px' }}
                            >
                                Delete
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Drawer>
    );
};

export default EventDrawer;

