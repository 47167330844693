import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Button, Card, Table, TableContainer, Paper, TableHead, TableRow, TableCell, TableBody, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Tooltip, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { IconButton } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useReducer, useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import CompetitionDropdown from './Dropdowns/CompetitionDropdown';
import { ROLES, SCORESHEET_STATES, scoreSheetCalculation } from '../misc/Utils';
import { CommonDataContext } from '../../firebase/CommonDataContext';
import PenaltyForm from './ScoringFormComponents/PenaltyFormNew';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';

const ITEM_TYPE = 'PENALTY';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const isValidScoresheet = (penalties) => {
    let validCheck = { result: true, errorMessages: [] }
    penalties.forEach(element => {
        if (element.value === '-' || element.value === '') {
            validCheck.result = false
            validCheck.errorMessages = validCheck.errorMessages.concat({
                id: element.id,
                message: '"Value In Seconds" must contain a number'
            })
        }
    });
    return validCheck
}


const scoresheetFormInitialState = (values) => {
    // TODO - fill out with base properties
    return {
        status: '',
        penalties: [],
        errors: [],
        tabNumber: 0
    }
    /*
    State Flow
    CREATED -> SUBMITTED -> APPROVED | LOCKED
    APPROVED | LOCKED -> CREATED
    APPROVED | LOCKED -> EDIT_REQUESTED -> CREATED
    status: 'SUBMITTED' | 'APPROVED' | 'CREATED' | 'EDIT_REQUESTED' | 'LOCKED'
    penalties: {
        id: int, // self determined
        type: str,
        description: str,
        value: int,
        hasMultiplier: bool, // true
        custom: bool, // false
        format: str, // 'SECONDS'
    }
    */
}
const Penalty = (id, isEvent) => {
    return {
        id,
        type: '',
        description: '',
        value: 0,
        hasMultiplier: true,
        custom: isEvent,
        format: 'SECONDS',
    }
}

const scoresheetFormReducer = (state, action) => {
    switch (action.type) {
        case 'add_item':
            // TODO - change the property based on whether or not you are an admin or judge
            return {
                ...state,
                penalties: [...state.penalties, Penalty(state.penalties.length + 1, action.isEvent || false)],
                errors: []
            }
        case 'remove_item':
            const removeID = action.id
            const removedPenaltyList = state.penalties.filter((x) => {
                return x.id != removeID
            })
            const recalculateIDsPenaltyList = removedPenaltyList.map((v, i) => {
                return {
                    ...v,
                    id: i + 1
                }
            })
            return {
                ...state,
                penalties: recalculateIDsPenaltyList,
                errors: []
            }
        case 'modify_item':
            const modifyID = action.id
            const modifiedPenaltyList = state.penalties.reduce((acc, v) => {
                if (v.id == modifyID) {
                    v = { ...v, ...action.values }
                }
                acc = acc.concat(v)
                return acc
            }, [])
            return {
                ...state,
                penalties: modifiedPenaltyList
            }
        case 'modify_number_item':
            // TODO - ensure when submitting we don't have any floating '-' hanging around
            // that is in the valid check for the penalties
            const numberRe = /^-?[0-9]*$/;
            const numberModifyID = action.id
            const numberModifiedPenaltyList = state.penalties.reduce((acc, v) => {
                if (v.id === numberModifyID) {
                    if (action.value === '' || numberRe.test(action.value)) {
                        v = { ...v, ...{ value: action.value } }
                    }
                }
                acc = acc.concat(v)
                return acc
            }, [])

            return {
                ...state,
                penalties: numberModifiedPenaltyList
            }
        case 'change_order':
            // if no change in position, return state
            if (action.id === action.to) {
                return state;
            }

            // Find the item being moved
            const extractedItem = state.penalties.find((v) => v.id === action.id);

            if (!extractedItem) {
                return state;
            }

            // Remove the item from its original position
            const updatedPenalties = state.penalties.filter((v) => v.id !== action.id);

            // Insert the item at the new position
            updatedPenalties.splice(action.to - 1, 0, { ...extractedItem, id: action.to });

            // Recalculate the IDs after reordering
            const reOrderedPenaltyList = updatedPenalties.map((v, i) => ({
                ...v,
                id: i + 1 // Ensure IDs are sequential after reordering
            }));

            return {
                ...state,
                penalties: reOrderedPenaltyList,
                errors: []
            };
        case 'update_error_messages':
            return {
                ...state,
                ...{ errors: action.value }
            }
        case 'set_initial_values':
            return {
                ...state,
                ...action.values
            }
        default:
            break;
    }
}

function DraggableRow({ v, index, dispatch, state, disabledItem, isEvent }) {
    console.log(isEvent);
    const ref = React.useRef(null);

    // Drag functionality
    const [{ isDragging }, drag] = useDrag({
        type: ITEM_TYPE,
        item: { index },
        canDrag: () => !disabledItem,
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    // Drop functionality
    const [, drop] = useDrop({
        accept: ITEM_TYPE,
        canDrop: () => !disabledItem,
        drop(item) {
            if (item.index !== index) {
                // movePenalty(item.index, index);
                //item.index = index;
                dispatch({
                    type: "change_order",
                    id: item.index + 1,
                    to: index + 1

                });
                console.log(item.index + " " + index);
            }
        },
    });

    drag(drop(ref));

    const backgroundColor = index % 2 === 0 ? '#ffffff' : '#dfdfdf';

    return (
        <TableRow
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
                backgroundColor: backgroundColor
            }}
        >
            {!isEvent &&
                <TableCell>
                    <IconButton disabled={disabledItem} aria-label="drag">
                        <DragHandleIcon />
                    </IconButton>
                </TableCell>
            }

            <TableCell>
                <TextField
                    disabled={disabledItem}
                    label={"Name Of Field"}
                    value={v.type}
                    onChange={(e) => {
                        dispatch({
                            type: 'modify_item',
                            id: v.id,
                            values: { type: e.target.value }
                        });
                    }}
                    fullWidth
                />
            </TableCell>

            <TableCell>
                <Select
                    disabled={isEvent}
                    value={v.value}
                    onChange={(e) => {
                        dispatch({
                            type: 'modify_number_item',
                            id: v.id,
                            value: e.target.value
                        });
                    }}
                    fullWidth
                >
                    <MenuItem value={15}>15 (penalty)</MenuItem>
                    <MenuItem value={30}>30 (penalty)</MenuItem>
                    <MenuItem value={60}>60 (penalty)</MenuItem>
                    <MenuItem value={90}>90 (penalty)</MenuItem>
                    <MenuItem value={-30}>-30 (bonus)</MenuItem>
                    <MenuItem value={-60}>-60 (bonus)</MenuItem>
                </Select>
            </TableCell>

            <TableCell>
                <Checkbox
                    checked={v.hasMultiplier}
                    disabled={disabledItem}
                    onChange={(e) => {
                        dispatch({
                            type: 'modify_item',
                            id: v.id,
                            values: { hasMultiplier: e.target.checked }
                        });
                    }}
                />
            </TableCell>

            {!isEvent &&
                <>
                    <TableCell>
                        <Checkbox
                            checked={v.custom}
                            disabled={disabledItem}
                            onChange={(e) => {
                                dispatch({
                                    type: 'modify_item',
                                    id: v.id,
                                    values: { custom: e.target.checked }
                                });
                            }}
                        />
                    </TableCell>

                    <TableCell>
                        <Button
                            disabled={disabledItem}
                            onClick={() => {
                                dispatch({
                                    type: 'remove_item',
                                    id: v.id
                                });
                            }}
                            color="error"
                        >
                            Remove
                        </Button>
                    </TableCell>
                </>
            }
        </TableRow>
    );
}

function PenaltyList({ state, dispatch, disabledItem, isEvent }) {
    console.log(disabledItem);

    return (
        <DndProvider backend={HTML5Backend}>
            <Grid2 container direction="column">
                <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {!isEvent &&
                                    <TableCell>Drag</TableCell>
                                }
                                <TableCell>Field Name</TableCell>
                                <TableCell>Value (Seconds)</TableCell>
                                <TableCell>Multiplier</TableCell>
                                {!isEvent &&
                                    <>
                                        <TableCell>Event Editable</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state.penalties.map((v, index) => (
                                <DraggableRow
                                    key={v.id}
                                    v={v}
                                    index={index}
                                    dispatch={dispatch}
                                    state={state}
                                    disabledItem={disabledItem || (isEvent && !v.custom)}
                                    isEvent={isEvent}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {!isEvent &&
                    <Button
                        disabled={disabledItem}
                        onClick={() => {
                            dispatch({
                                type: 'add_item',
                                isEvent: isEvent
                            });
                        }}
                    >
                        Add Penalty/Bonus
                    </Button>
                }
            </Grid2>
        </DndProvider>
    );
}

function ScoresheetStateButtons({
    state,
    lockScoresheet,
    unlockScoresheet,
    approveScoresheet,
    submitScoresheetForReview,
    requestEdit,
    deleteScoresheet,
    disabledItem
}) {
    const { user } = useContext(UserDataContext)
    // TODO - we set this up like a dummy
    // dont keep checking for admin role do it ONCE ina block
    //  then run through the different state statuses
    const adminButtons = () => {
        if (user.role !== ROLES.ADMIN) {
            return false
        }
        return (
            <>
                {
                    [SCORESHEET_STATES.APPROVED,
                    SCORESHEET_STATES.SUBMITTED,
                    SCORESHEET_STATES.CREATED
                    ].includes(state.status) &&
                    lockScoresheet &&
                    <Button
                        disabled={disabledItem}
                        onClick={(e) => {
                            e.preventDefault()
                            lockScoresheet()
                            console.log(state.status);
                        }}>Lock Scoresheet</Button>
                }
                {
                    (state.status === SCORESHEET_STATES.LOCKED ||
                        state.status === SCORESHEET_STATES.EDIT_REQUESTED) &&
                    unlockScoresheet &&
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            unlockScoresheet()
                        }}
                    >
                        Unlock Scoresheet
                    </Button>
                }
                {
                    [SCORESHEET_STATES.SUBMITTED].includes(state.status) &&
                    approveScoresheet &&
                    <Button
                        onClick={(e) => {
                            e.preventDefault()
                            approveScoresheet()
                        }}>Approve Scoresheet</Button>
                }
            </>

        )
    }
    console.log('this is the scoresheet stats', state.status)
    return (
        <Grid2>
            {
                adminButtons()
            }
            {
                state.status === SCORESHEET_STATES.CREATED &&
                submitScoresheetForReview &&
                <Button disabled={['', SCORESHEET_STATES.LOCKED].includes(state.status)} onClick={(e) => {
                    e.preventDefault()
                    console.log('about to submit the scoresheet for erview', state, requestEdit)
                    submitScoresheetForReview()
                }}>Submit Scoresheet</Button>
            }
            {
                [SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.APPROVED].includes(state.status) &&
                <Button disabled={![SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.APPROVED].includes(state.status) || user.role === ROLES.ADMIN} onClick={(e) => {
                    e.preventDefault()
                    requestEdit()
                }}>Request Edit</Button>

            }
            {
                
                state.status != '' && 
                <Button disabled={user.role !== ROLES.ADMIN} onClick={(e) => {
                    e.preventDefault()
                    deleteScoresheet()
                }}>Delete</Button>

            }
        </Grid2>
    )


}

function EventScoresheetForm(props) {
    const [state, dispatch] = useReducer(scoresheetFormReducer, props, scoresheetFormInitialState)
    const { user, defaultSelectedCompetition } = useContext(UserDataContext)
    const { scoresheetsMap: commonScoresheetsMap } = useContext(CommonDataContext)
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings } = useContext(GlobalSettingsContext)
    const [confirmDelete, setConfirmDelete] = useState({ open: false, scoresheet: null });

    console.log(props);
    const globalScoresheetsMap = useMemo(() => {
        if (!commonScoresheetsMap || !commonScoresheetsMap[props.competition]) {
            return {}
        }
        return commonScoresheetsMap[props.competition]['global'] || {}
    }, [commonScoresheetsMap])
    useEffect(() => {
        if (props.competition &&
            commonScoresheetsMap &&
            commonScoresheetsMap[props.competition] &&
            commonScoresheetsMap[props.competition]['events']
        ) {
            dispatch({
                type: 'set_initial_values',
                values: commonScoresheetsMap[props.competition]['events'][props.eventUid] || {}
            })
        }

    }, [])

    const createScoresheet = async (penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        await firebase.insertEventScoresheet({ status, penalties }, props.eventUid, props.competition)
        window.location.reload(false)
    }

    const editScoresheet = async (penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        await firebase.editEventScoresheet({ status, penalties }, props.eventUid, props.competition)
        window.location.reload(false)
    }
    const submitButtonPressed = useCallback(() => {
        if (props.editScoresheet) {
            editScoresheet(state.penalties, SCORESHEET_STATES.CREATED)
        } else {
            createScoresheet(state.penalties, SCORESHEET_STATES.CREATED)
        }
        window.location.reload(false);
    }, [state.penalties])

     const CreateNew = useCallback(() => {
        if (props.editScoresheet) {
            editScoresheet(globalScoresheetsMap.penalties, SCORESHEET_STATES.CREATED)
        } else {
            createScoresheet(globalScoresheetsMap.penalties, SCORESHEET_STATES.CREATED)
        }
    }, [state.penalties])

    const submitScoresheetForReview = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.SUBMITTED)
    }, [state.penalties])
    const lockScoresheet = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.LOCKED)
    }, [state.penalties])
    const unlockScoresheet = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.CREATED)
    }, [user, state.penalties])
    const approveScoresheet = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.APPROVED)
    }, [state.penalties])
    const requestEdit = useCallback(() => {
        editScoresheet(state.penalties, SCORESHEET_STATES.EDIT_REQUESTED)
    }, [state.penalties])

    const deleteScoresheet = useCallback(() => {
        setConfirmDelete({ open: true, scoresheet: state });
    }, [state.penalties])

    const handleCloseDialog = () => {
        setConfirmDelete({ open: false, scoresheet: null })
    }

    const deleteFullScoresheet = async () => {
        await firebase.deleteEventScoresheet(props.eventUid, props.competition);
        setConfirmDelete({ open: false, scoresheet: null });
        window.location.reload(false)
    }


    // TODO - do we actually want to do this???
    //  NOTE the following is important
    const combinedPenalties = [...state.penalties]
    const shouldDisableSubmitButton = [SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.EDIT_REQUESTED, SCORESHEET_STATES.APPROVED].includes(state.status) || (globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]);
    console.log(state.status === '');

    console.log('should i disablethe button', shouldDisableSubmitButton)
    return (
        <Grid2>
            <Typography variant='h3'>{props.eventTitle}</Typography>
            <Typography variant='h3'>Event Scoresheet</Typography>
            <ScoresheetStateButtons
                {...{
                    state,
                    lockScoresheet,
                    unlockScoresheet,
                    approveScoresheet,
                    submitScoresheetForReview,
                    requestEdit,
                    deleteScoresheet,
                    disabledItem: globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]
                }}
            />

            <TabPanel value={state.tabNumber} index={0}>
                <PenaltyList {...{ state, dispatch, disabledItem: shouldDisableSubmitButton, isEvent: true }} />
            </TabPanel>
            <TabPanel value={state.tabNumber} index={1}>
                <PenaltyForm
                    penaltyList={combinedPenalties}
                    readonly={true}
                />
            </TabPanel>
            <Grid2>
                { state.penalties.length !== 0 &&
                    <Button disabled={shouldDisableSubmitButton} onClick={(e) => {
                        e.preventDefault()
                        submitButtonPressed()
                    }}>Edit ScoreSheet</Button>
                }
                {
                    shouldDisableSubmitButton &&
                    <Grid2>
                        <Typography variant='body'>An administrator must be contacted to enable changes</Typography>
                    </Grid2>
                }

                { state.penalties.length === 0 &&
                    <div>
                        <Button disabled={globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]}
                            onClick={(e) => {
                                e.preventDefault()
                                CreateNew();
                            }}
                        >Create New</Button>
                    </div>
                }
            </Grid2>

            {/* Confirmation Dialog */}
            <Dialog
                open={confirmDelete.open}
                onClose={handleCloseDialog}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this scoresheet?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">No</Button>
                    <Button onClick={async () => await deleteFullScoresheet(confirmDelete.scoresheet)} color="error">Yes</Button>
                </DialogActions>
            </Dialog>
        </Grid2>
    )

}

// TODO Will handle both editing and insertting of new data
// TODO need to have a new component for the event form
function ScoresheetForm(props) {
    const [state, dispatch] = useReducer(scoresheetFormReducer, props, scoresheetFormInitialState)
    const { scoresheetsMap: commonScoresheetsMap } = useContext(CommonDataContext)
    const { firebase } = useContext(FirebaseNewContext)
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { defaultSelectedCompetition, user } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)

    // Load in the scoresheet if we have it
    useEffect(() => {
        console.log('we are calling this ONCE')
        if (selectedCompetition &&
            commonScoresheetsMap &&
            commonScoresheetsMap[selectedCompetition]) {
            dispatch({
                type: 'set_initial_values',
                values: commonScoresheetsMap[selectedCompetition]['global'] || {}
            })
        }

    }, [])

    const createScoresheet = (competition, penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        firebase.insertGlobalScoresheet({ status, penalties }, competition)
    }

    const editScoresheet = (competition, penalties, status) => {
        const validCheck = isValidScoresheet(state.penalties)
        if (!validCheck.result) {
            dispatch({
                type: 'update_error_messages',
                value: validCheck.errorMessages
            })
            return false
        }
        firebase.editGlobalScoresheet({ status, penalties }, competition)

    }
    const lockScoresheet = useCallback(() => {
        editScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.LOCKED);
        window.location.reload(false);
    }, [state.penalties])
    const unlockScoresheet = useCallback(() => {
        editScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.CREATED)
        window.location.reload(false);
    }, [state.penalties])
    const submitButtonPressed = useCallback(() => {
        if (props.editScoresheet) {
            editScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.CREATED)
        } else {
            createScoresheet(selectedCompetition, state.penalties, SCORESHEET_STATES.CREATED)
        }
        window.location.reload();
    }, [state.penalties, selectedCompetition])

    const shouldDisableSubmitButton = state.penalties.length === 0 || [SCORESHEET_STATES.SUBMITTED, SCORESHEET_STATES.EDIT_REQUESTED, SCORESHEET_STATES.APPROVED].includes(state.status)
    return (
        <Grid2>
            <Typography variant='h3'>Global Base Scoresheet</Typography>
            <ScoresheetStateButtons
                {...{
                    state,
                    lockScoresheet,
                    unlockScoresheet,
                    disabledItem: globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]                   
                }}
            />
                {
                    !props.editScoresheet && !props.competition &&
                    (
                        <Grid2>
                            <CompetitionDropdown
                                value={selectedCompetition}
                                onChange={(value) => setSelectedCompetition(value)}
                            />
                        </Grid2>
                    )
                }
                <PenaltyList {...{ state, dispatch, disabledItem: (globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]) || state.status === SCORESHEET_STATES.LOCKED, isEvent: false }} />
            <Button disabled={(globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]) || state.status == SCORESHEET_STATES.LOCKED} onClick={(e) => {
                e.preventDefault()
                submitButtonPressed()
            }}>{state.status !== '' ? 'Edit' : 'Add'} ScoreSheet</Button>
        </Grid2>

    )
}

export {
    ScoresheetForm,
    EventScoresheetForm
}
