import React, { useContext, useEffect } from 'react';

import * as routes from '../routes/routes';

import { useLocation } from 'react-router';
import RegistrationPage from '../pages/RegistrationPage';
import RegistrationSettingsContextProvider from '../firebase/RegistrationSettingsContext';
import GlobalSettingsContextProvider from '../firebase/GlobalSettingsContext';


const RegistrationRouter = () => {
    return (
        <RegistrationSettingsContextProvider>
            <GlobalSettingsContextProvider>
                <RegistrationPage />
            </GlobalSettingsContextProvider>
        </RegistrationSettingsContextProvider>
    )
}

export default RegistrationRouter
