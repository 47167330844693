
import { Button, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState, useCallback, useContext, useMemo } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import CompetitionDropdown from './Dropdowns/CompetitionDropdown';
import CategoryList from './Lists/CategoryList';
import FormErrorMessages from './Messages/FormErrorMessages';

function CategoryForm(props) {
    const { globalSettings: { categories } } = useContext(GlobalSettingsContext)
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const { firebase } = useContext(FirebaseNewContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const [category, setCategory] = useState('')
    const categoryMap = useMemo(() => {
        return categories[selectedCompetition] || {}

    }, [selectedCompetition])
    const [errorMessages, setErrorMessages] = useState([])

    useEffect(() => {
        const fetch = async () => {
            if (props.category) {
                setCategory(categoryMap[props.category])
            }
        }
        fetch()
            .catch(console.error)

    }, [])

    const isValid = (category, categoryMap) => {
        let validCheck = { result: true, errorMessages: [] };
        if (category === '') {
            validCheck = {
                result: false,
                errorMessages: ['category cannot be empty']
            }
            return validCheck

        }

        const normalizedCategoryMap = Object.keys(categoryMap).map((v) => categoryMap[v]).map((v) => {
            return String(v).toUpperCase().trim()
        })

        const isIn = normalizedCategoryMap.includes(String(category).toUpperCase().trim())
        if (isIn) {
            validCheck = {
                result: false,
                errorMessages: ['category already exists']
            }
        }

        return validCheck
    }

    const insertNewCategory = async () => {
        const isValidCheck = isValid(category, categoryMap)
        setErrorMessages(isValidCheck.errorMessages)
        if (isValidCheck.result) {
            await firebase.insertCategory(category, selectedCompetition)
            window.location.reload(false)
        }

    }

    const patchExistingCategory = async () => {
        const excludedMap = Object.keys(categoryMap).reduce((acc, v) => {
            if (props.category === v) {
                return acc
            }
            acc[v] = categoryMap[v]
            return acc
        }, {})

        const isValidCheck = isValid(category, excludedMap)
        if (!isValidCheck.result) {
            setErrorMessages(isValidCheck.errorMessages)
        } else {
            console.log(`${props.competition}/${props.category}`)
            await firebase.patchCategory(`${props.competition}/${props.category}`, category)
            window.location.reload(false)
        }
    }
    const submitButtonPressed = useCallback((e) => {
        e.preventDefault();
        if (props.category) {
            patchExistingCategory()
        } else {
            insertNewCategory()
        }
    }, [category, categoryMap])

    // NOTE - should be saving this as a object and not a string so 
    //  you can edit it later and nothign changes
    return (
        <Grid2>
            <Typography variant='h5'>{props.category ? 'Edit' : 'Create'} a category</Typography>
            <Grid2>
                {
                    !props.category && !props.competition &&
                    (
                        <Grid2>
                            <CategoryList
                                competition={selectedCompetition}
                            />
                            <CompetitionDropdown
                                value={selectedCompetition}
                                onChange={(value) => setSelectedCompetition(value)}
                            />
                        </Grid2>

                    )

                }
                <TextField
                    name="category"
                    label="Category Name"
                    variant="standard"
                    value={category}
                    onChange={(e) => {
                        setCategory(e.target.value)
                    }}
                />
            </Grid2>
            <Grid2>
                <FormErrorMessages errorMessages={errorMessages} />
                <Button disabled={globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]} onClick={submitButtonPressed} variant='outlined'>{props.category ? 'Save' : 'Create'}</Button>
            </Grid2>
        </Grid2 >

    )
}

export default CategoryForm
