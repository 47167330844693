import React, { useReducer, useContext, useEffect, useCallback, useState, useRef } from 'react';
import CompetitionDropdown from '../../components/forms/Dropdowns/CompetitionDropdown';
import { UserDataContext } from '../../firebase/UserDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { RegistrationSettingsContext } from '../../firebase/RegistrationSettingsContext';

function eventRegistrationSettingsFormInitialState(props) {
    return {
        selectedCompetition: '',
        closeRegistration: false,
        errorMessages: []
    }
}

function eventRegistrationSettingsFormReducer(state, action) {
    switch (action.type) {
        case 'initial_values_changed':
            return {
                ...state,
                ...action.value
            }
        case 'new_registration_values_changed':
            return {
                ...state,
                closeRegistration: action.value.closeRegistration || false
            }
        case 'close_registration_changed':
            return {
                ...state,
                closeRegistration: !state.closeRegistration
            }
        case 'competition_changed':
            return {
                ...state,
                selectedCompetition: action.value,
            }
        default:
            break;
    }
}

function EventRegistrationSettingsForm(props) {
    const { registrationSettings } = useContext(RegistrationSettingsContext)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [state, dispatch] = useReducer(eventRegistrationSettingsFormReducer, props, eventRegistrationSettingsFormInitialState)

    const eventCriteriaFileUploadRef = useRef(null)
    const [eventCriteriaFile, setEventCriteriaFile] = useState(false)
    const scoresheetFileUploadRef = useRef(null)
    const [scoresheetFile, setScoresheetFile] = useState(false)


    useEffect(() => {
        dispatch({
            type: 'initial_values_changed',
            value: {
                selectedCompetition: defaultSelectedCompetition,
            }
        })


    }, [])

    useEffect(() => {
        const fetch = async () => {
            if (state.selectedCompetition) {
                const criteriaFileResult = await firebase.storageGetRegistrationFile('CriteriaFile.pdf', state.selectedCompetition) || null
                setEventCriteriaFile(criteriaFileResult)

                const scoresheetFileResult = await firebase.storageGetRegistrationFile('ScoresheetFile.pdf', state.selectedCompetition) || null
                setScoresheetFile(scoresheetFileResult)
            }
        }

        fetch()
            .catch(err => console.error(err))

    }, [state.selectedCompetition])

    useEffect(() => { 
        const settings = registrationSettings[state.selectedCompetition] || {}
        const eventSettings = settings.event || {}
        dispatch({
            type: 'new_registration_values_changed',
            value: eventSettings
        })
    }, [registrationSettings, state.selectedCompetition])

    const submitButtonPressed = useCallback(() => {
        const data = {
            closeRegistration: state.closeRegistration
        }
        firebase.setEventRegistrationSettings(state.selectedCompetition, data)
    }, [state])

    const handleFileUpload = useCallback(async (fileEvent) => {
        const { target } = fileEvent
        const name = target.name
        const fileReader = new FileReader()
        fileReader.readAsDataURL(target.files[0])
        const fileExtension = target.files[0].name.split('.').pop().toLowerCase()

        await firebase.storageUploadRegistrationFile(target.files[0], `${name}.${fileExtension}`, state.selectedCompetition)
        if (name == 'CriteriaFile') {
            const criteriaFileResult = await firebase.storageGetRegistrationFile('CriteriaFile.pdf', state.selectedCompetition) || null
            setEventCriteriaFile(criteriaFileResult)
        } else if (name == 'ScoresheetFile') {
            const scoresheetFileResult = await firebase.storageGetRegistrationFile('ScoresheetFile.pdf', state.selectedCompetition) || null
            setEventCriteriaFile(scoresheetFileResult)
        }

    }, [state.selectedCompetition])


    const eventCriteriaFileUploadClicked = useCallback(() => {
        eventCriteriaFileUploadRef.current.click()
    }, [eventCriteriaFile])
    const scoresheetFileUploadClicked = useCallback(() => {
        scoresheetFileUploadRef.current.click()
    }, [scoresheetFile])
    return (
        <Grid2>
            <Grid2>
                <CompetitionDropdown
                    value={state.selectedCompetition}
                    onChange={(value) => {
                        dispatch({
                            type: 'competition_changed',
                            value
                        })

                    }}
                />
            </Grid2>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    checked={state.closeRegistration}
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch({
                            type: 'close_registration_changed'
                        })
                    }}
                />} label={`Close Registration?`} />
            </FormGroup>
            <Grid2 alignContent={'center'} flexDirection={'column'} container>
                {
                    eventCriteriaFile &&
                    <Typography variant='h5'><a target="_blank" href={eventCriteriaFile}>Currently uploaded file here</a></Typography>
                }
                {
                    !eventCriteriaFile &&
                    <Typography variant='h5'>No Criteria File Uploaded For This Competition</Typography>
                }
                <Button
                    sx={{ marginTop: '5px' }}
                    variant='contained'
                    component="label"
                    onClick={eventCriteriaFileUploadClicked}
                >
                    Upload Event Criteria Form
                </Button>
                <input type="file" hidden
                    ref={eventCriteriaFileUploadRef}
                    name={'CriteriaFile'}
                    onChange={handleFileUpload}
                />
            </Grid2>
            <Grid2 alignContent={'center'} flexDirection={'column'} container>
                {
                    scoresheetFile &&
                    <Typography variant='h5'><a target="_blank" href={eventCriteriaFile}>Currently uploaded file here</a></Typography>
                }
                {
                    !scoresheetFile &&
                    <Typography variant='h5'>No Scoresheet File Uploaded For This Competition</Typography>
                }

                <Button
                    sx={{ marginTop: '5px' }}
                    variant='contained'
                    component="label"
                    onClick={scoresheetFileUploadClicked}
                >
                    Upload Example Scoresheet Form
                </Button>
                <input type="file" hidden
                    ref={scoresheetFileUploadRef}
                    name={'ScoresheetFile'}
                    onChange={handleFileUpload}
                />
            </Grid2>
            <Button type="submit" onClick={submitButtonPressed}>Save</Button>
        </Grid2>
    )

}



export default EventRegistrationSettingsForm;

