import React, { useState, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Typography } from '@mui/material';

const ScheduleBackgroundDropdown = (props) => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);

    const {
        value,
        onChange,
        readonly,
        fetchOptions
    } = props;

    useEffect(() => {
        const loadOptions = async () => {
            try {
                const fetchedOptions = await fetchOptions();
                setOptions(fetchedOptions);
            } catch (error) {
                console.error("Error fetching options:", error);
            } finally {
                setLoading(false);
            }
        };

        loadOptions();
    }, [fetchOptions]);

    return (
        <Grid2>
            <Typography variant='h5'>Selected Background</Typography>
            <FormControl className='scoring-form-div' disabled={loading} fullWidth>
                <Select
                    name="competitionUid"
                    value={value}
                    onChange={(e) => onChange(e.target.value || {})}
                    className="scoring-form-select"
                    readOnly={readonly}
                    size="small"
                    sx={{ 
                        maxWidth: '100%', 
                        width: '100%',
                        maxHeight: '50px',
                        boxSizing: 'border-box',
                    }}
                >
                    <MenuItem value={'default'}>Default</MenuItem>
                    {options.map(option => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid2>
    );
};

export default ScheduleBackgroundDropdown;

