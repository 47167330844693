import React from 'react';
import * as routes from '../routes/routes';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link, useLocation } from 'react-router-dom';
import TeamRegistrationSettingsForm from '../components/registration/TeamRegistrationSettingsForm';
import EventRegistrationSettingsForm from '../components/registration/EventRegistrationSettingsForm';
import SponsorRegistrationSettingsForm from '../components/registration/SponsorRegistrationSettingsForm';
import { Button } from '@mui/material';

function RegistrationSettingsPage() {
    const location = useLocation()

    console.log('got here')
    switch (true) {
        case location.pathname.includes(routes.TEAM_REGISTRATION_SETTINGS_PAGE):
            return <TeamRegistrationSettingsForm />
        case location.pathname.includes(routes.EVENT_REGISTRATION_SETTINGS_PAGE):
            return <EventRegistrationSettingsForm />
        case location.pathname.includes(routes.SPONSOR_REGISTRATION_SETTINGS_PAGE):
            return <SponsorRegistrationSettingsForm />
        default:
            return (
                <Grid2>
                    <h2>Registration Settings Page</h2>
                    {/*
                        <Grid2>
                            <Button component={Link} variant={'outlined'} to={routes.TEAM_REGISTRATION_SETTINGS_PAGE}>Team Registration</Button>
                        </Grid2>
                    */}
                    <Grid2>
                        <Button component={Link} variant={'outlined'} to={routes.EVENT_REGISTRATION_SETTINGS_PAGE}>Event Registration</Button>
                    </Grid2>
                    <Grid2>
                        <Button component={Link} variant={'outlined'} to={routes.SPONSOR_REGISTRATION_SETTINGS_PAGE}>Sponsor Registration</Button>
                    </Grid2>
                </Grid2>
            )

    }

}


export default RegistrationSettingsPage;
