import React, { useReducer, useContext, useEffect, useCallback } from 'react';
import CompetitionDropdown from '../../components/forms/Dropdowns/CompetitionDropdown';
import { UserDataContext } from '../../firebase/UserDataContext';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { RegistrationSettingsContext } from '../../firebase/RegistrationSettingsContext';

function teamRegistrationSettingsFormInitialState(props) {
    return {
        selectedCompetition: '',
        closeRegistration: false,
        errorMessages: []
    }
}

function teamRegistrationSettingsFormReducer(state, action) {
    switch (action.type) {
        case 'initial_values_changed':
            return {
                ...state,
                ...action.value
            }
        case 'new_registration_values_changed':
            return {
                ...state,
                closeRegistration: action.value.closeRegistration || false
            }
        case 'close_registration_changed':
            return {
                ...state,
                closeRegistration: !state.closeRegistration
            }
        case 'competition_changed':
            return {
                ...state,
                selectedCompetition: action.value,
            }
        default:
            break;
    }
}

function TeamRegistrationSettingsForm(props) {
    const { registrationSettings } = useContext(RegistrationSettingsContext)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [state, dispatch] = useReducer(teamRegistrationSettingsFormReducer, props, teamRegistrationSettingsFormInitialState)

    useEffect(() => {
        dispatch({
            type: 'initial_values_changed',
            value: {
                selectedCompetition: defaultSelectedCompetition,
            }
        })

    }, [])

    useEffect(() => { 
        const settings = registrationSettings[state.selectedCompetition] || {}
        const teamSettings = settings.team || {}
        dispatch({
            type: 'new_registration_values_changed',
            value: teamSettings
        })
    }, [registrationSettings, state.selectedCompetition])

    const submitButtonPressed = useCallback(() => {
        const data = {
            closeRegistration: state.closeRegistration
        }
        firebase.setTeamRegistrationSettings(state.selectedCompetition, data)
    }, [state])
    return (
        <Grid2>
            <Grid2>
                <CompetitionDropdown
                    value={state.selectedCompetition}
                    onChange={(value) => {
                        dispatch({
                            type: 'competition_changed',
                            value
                        })

                    }}
                />
            </Grid2>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    checked={state.closeRegistration}
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch({
                            type: 'close_registration_changed'
                        })
                    }}
                />} label={`Close Registration?`} />
            </FormGroup>
            <Button type="submit" onClick={submitButtonPressed}>Save</Button>
        </Grid2>
    )

}



export default TeamRegistrationSettingsForm;
