import React, { useState, useContext, useMemo } from 'react';
import CategoryForm from '../components/forms/CategoryForm';
import * as routes from '../routes/routes';
import { UserDataContext } from '../firebase/UserDataContext';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { Button, Stack, Typography } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

const categoryColumns = [
    { field: 'col1', headerName: 'Category Name', width: 350 },
    {
        field: 'col2', headerName: 'Actions', width: 150, getApplyQuickFilterFn: () => null,
        renderCell: (params) => {
            return <Button component={Link} to={routes.routeReplace(routes.CATEGORY_PAGE_EDIT, { edit: params.value.uid, competition: params.value.selectedCompetition })}>Edit</Button>
        }
    },
]

function CategoryPage(props) {
    const location = useLocation();
    const params = useParams()
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const { globalSettings: { categories: globalCategoriesMap, competitions } } = useContext(GlobalSettingsContext)
    const categoryMap = useMemo(() => {
        return globalCategoriesMap[selectedCompetition] || {}
    }, [selectedCompetition, globalCategoriesMap])
    const { globalSettings } = useContext(GlobalSettingsContext)


    const rows = useMemo(() => {
        return Object.keys(categoryMap).map((v) => {
            const category = categoryMap[v]
            return {
                id: v,
                col1: category,
                col2: { uid: v, selectedCompetition }

            }
        })

    }, [categoryMap])
    // TODO - make the renders a bit smaller and cleaner
    return (
        <Grid2>
            {
                location.pathname === routes.CATEGORY_PAGE &&
                (
                    <>
                        <Grid2 container justifyContent={'space-between'} alignItems={'center'}>
                            <Typography variant='h3'>Categories</Typography>
                            <Button disabled={globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]} sx={{ maxHeight: '50px' }} component={Link} variant={'outlined'} to={routes.CATEGORY_PAGE_NEW}>Create A Category</Button>
                        </Grid2>
                        <CompetitionDropdown
                            value={selectedCompetition}
                            onChange={setSelectedCompetition}
                        />
                    </>
                )
            }
            {
                typeof selectedCompetition !== 'string' &&
                <h3>Please Select A competition</h3>
            }
            {

                location.pathname === routes.CATEGORY_PAGE &&
                typeof selectedCompetition == 'string' &&
                <Grid2 flexDirection={'column'}>
                    <DataGrid
                        sx={{ marginBottom: '25px' }}
                        autoHeight
                        rows={rows || []}
                        columns={categoryColumns}
                        slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <Typography variant='body'>For the {competitions[selectedCompetition]} competition</Typography>
                                    <Typography variant='body'>No Categories Have Been Created</Typography>
                                </Stack>
                            ),
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Grid2>
            }
            {
                Object.keys(params).length !== 0 &&
                <CategoryForm {...props} category={params.edit} competition={params.competition} />
            }
            {
                location.pathname.includes(routes.CATEGORY_PAGE_NEW) &&
                <CategoryForm {...props} />

            }
        </Grid2 >
    )

}



export default CategoryPage;
