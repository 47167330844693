import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const ItemType = "EVENT";

const DraggableEvent = ({ event, index, moveEvent, handleEventClick, renderArrow, disabled }) => {
    const [{ isDragging }, dragRef] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: !disabled ? monitor.isDragging() : false,
        }),
    });

    const [, dropRef] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index && event && !disabled) {
                moveEvent(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const getHeaderColor = (type) => {
        switch (type) {
            case 'Event':
                return 'orange';
            case 'Break':
                return 'purple';
            case 'Lunch':
                return 'red';
            default:
                return 'gray';
        }
    };

    return (
        <div ref={(node) => dragRef(dropRef(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <Paper
                onClick={() => { 
                    if (!disabled) {
                        handleEventClick(event); 
                    }
                }}
                style={{
                    padding: '15px',
                    paddingTop: '8px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderTop: `10px solid ${getHeaderColor(event.type)}`,
                }}
            >
                <Typography variant="h6">{event.name}</Typography>
                <Typography variant="body2">{event.duration} min</Typography>
                <Typography variant="body2">{event.type}</Typography>
                {renderArrow(index)}
            </Paper>
        </div>
    );
};

export default DraggableEvent;

