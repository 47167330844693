import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import * as routes from '../routes/routes';
import { UserDataContext } from '../firebase/UserDataContext';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { Button, TextField, Typography } from '@mui/material';
import { FirebaseNewContext } from '../firebase/FirebaseNewContext';
import LiabilityList from '../components/forms/Lists/LiabilityList';
import { DialogConfirmMessaging, DialogSuccessMessaging } from '../components/forms/Messages/DialogMessaging';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { CommonDataContext } from '../firebase/CommonDataContext';


function LiabilityPage(props) {
    const fileUploadRef = useRef(null)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const { liabilityMap: globalLiabilityMap } = useContext(CommonDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const { firebase } = useContext(FirebaseNewContext)
    const [file, setFile] = useState(false)
    const [generalCompetitionDescription, setGeneralCompetitionDescription] = useState('')
    const [showConfirmDialog, setShowConfirmDialog] = useState(false)
    const { globalSettings } = useContext(GlobalSettingsContext)

    useEffect(() => {
        console.log('getting called')
        const fetch = async () => {
            const fileResult = await firebase.storageGetLiabilityForm('LiabilityForm.pdf', selectedCompetition)
            if (fileResult) {
                setFile(fileResult)
            }

            console.log('this is our details', globalLiabilityMap)
            setGeneralCompetitionDescription(globalLiabilityMap[selectedCompetition]?.details || '')
        }
        fetch()
            .catch(() => {
                setFile(false)
            })
    }, [defaultSelectedCompetition, globalLiabilityMap])

    const fileUploadClicked = useCallback(() => {
        if (file) {
            setShowConfirmDialog(true)
        } else {
            fileUploadRef.current.click()
        }

    }, [file])

    const handleFileUpload = useCallback(async (fileEvent) => {
        const { target } = fileEvent
        const fileReader = new FileReader()
        fileReader.readAsDataURL(target.files[0])
        const fileExtension = target.files[0].name.split('.').pop().toLowerCase()
        await firebase.storageUploadLiabilityForm(target.files[0], `LiabilityForm.${fileExtension}`, selectedCompetition)
        await firebase.resetLiabilityFormEntries(selectedCompetition)
        window.location.reload(false)
    }, [selectedCompetition])


    const submitDetails = useCallback(async () => {
        await firebase.upsertLiabiltyFormDescription(selectedCompetition, generalCompetitionDescription)
        // TODO simply make a non-reload and popup
        window.location.reload(false)

    }, [selectedCompetition, generalCompetitionDescription])


    return (
        <Grid2 sx={{ padding: '15px' }}>
            <Grid2
                sx={{ marginBottom: '15px' }}
                flexDirection={'row'}
                justifyContent={'space-between'}
                container
            >
                <Grid2>
                    <CompetitionDropdown
                        value={selectedCompetition}
                        onChange={setSelectedCompetition}
                    />
                </Grid2>
                <Grid2 alignContent={'center'} flexDirection={'column'} container>
                    {
                        file &&
                        <Typography variant='h5'><a target="_blank" href={file}>Currently uploaded file here</a></Typography>
                    }
                    {
                        !file &&
                        <Typography variant='h5'>No Liability Form Uploaded For This Competition</Typography>
                    }
                    <Button
                        sx={{ marginTop: '5px' }}
                        variant='contained'
                        component="label"
                        onClick={fileUploadClicked}
                        disabled={globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]}
                    >
                        upload a {file ? 'new ' : ''}Liability Form
                    </Button>
                </Grid2>
            </Grid2>
            <input type="file" hidden
                ref={fileUploadRef}
                onChange={handleFileUpload}
            />
            <TextField
                fullWidth
                type="text"
                onChange={({ target }) => setGeneralCompetitionDescription(target.value)}
                value={generalCompetitionDescription}
                multiline
                sx={{ marginBottom: '15px' }}
            />
            <Button
                sx={{ marginTop: '5px' }}
                variant='contained'
                component="label"
                onClick={submitDetails}
                disabled={globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[defaultSelectedCompetition]}
            >
                Save Detail
            </Button>


            <LiabilityList competition={selectedCompetition} />
            <DialogConfirmMessaging
                confirmTitle={'Warning!'}
                confirmDescription={'Uploading a new liability form will result in all users for the current competition to reconfirm their liability status'}
                showConfirmMessage={showConfirmDialog}
                onConfirmClose={() => { setShowConfirmDialog(false) }}
                onConfirmConfirm={() => {
                    setShowConfirmDialog(false)
                    fileUploadRef.current.click()
                }}
                onConfirmCancel={() => { setShowConfirmDialog(false) }}
            />
        </Grid2>
    )
}


export default LiabilityPage;
