import { Button, FormControl, FormControlLabel, FormLabel, Icon, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from '@mui/material';
import React, { useReducer, useEffect, useState, useCallback, useContext, useRef } from 'react';
import { FirebaseNewContext } from '../../firebase/FirebaseNewContext';
import { GlobalSettingsContext } from '../../firebase/GlobalSettingsContext';
import { UserDataContext } from '../../firebase/UserDataContext';
import FormErrorMessages from '../forms/Messages/FormErrorMessages';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import ArticleIcon from '@mui/icons-material/Article';
import { useParams } from 'react-router';

function eventFormInitialState(values) {
    console.log(values);
    return {
        data: {
            eventName: values.eventName || '',
            companyName: values.companyName || '',
            billingAddressLine1: values.billingAddressLine1 || '',
            billingAddressLine2: values.billingAddressLine2 || '',
            billingAddressCity: values.billingAddressCity || '',
            billingAddressState: values.billingAddressState || '',
            billingAddressZipCode: values.billingAddressZipCode || '',
            billingAddressCountry: values.billingAddressCountry || '',
            primaryPointOfContactFirst: values.primaryPointOfContactFirst || '',
            primaryPointOfContactLast: values.primaryPointOfContactLast || '',
            primaryPointOfContactEmail: values.primaryPointOfContactEmail || '',
            boothSize: values.boothSize || '10x10',
            eventProcedures: values.eventProcedures || '',
            eventProceduresFile: values.eventProceduresFile  || '',
            scoreSheetFile: values.scoreSheetFile  || '',
            referenceMaterialFile1: values.referenceMaterialFile1  || '',
            referenceMaterialFile2: values.referenceMaterialFile2  || '',
        },
        errorMessages: []
    }
}
function eventFormReducer(state, action) {
    switch (action.type) {
        case 'value_changed':
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.keyName]: action.keyValue
                },
            }
        case 'values_changed':
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.values
                }
            }
        case 'error_message_changed':
            return {
                ...state,
                errorMessages: action.nextErrorMessages

            }
        case 'LOAD_EVENT_DATA':
            console.log(action);
            return {
                ...state,
                data: {
                ...state.data,
                ...action.payload
                }
            };
        default:
            break;
    }
}

function EventRegistrationForm(props) {
    const params = useParams()
    const [state, dispatch] = useReducer(eventFormReducer, props, eventFormInitialState);
    const { firebase } = useContext(FirebaseNewContext)
    //const { globalSettings: { defaultCompetition } } = useContext(GlobalSettingsContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [selectedCompetition, setSelectedCompetition] = useState(props.competition || defaultSelectedCompetition)
    const [isView, setView] = useState(window.location.pathname.includes("view"));

    const [eventCriteriaFile, setEventCriteriaFile] = useState(false)
    const [scoreSheetFile, setScoreSheetFile] = useState(false)

    const [viewScoreSheetFile, setViewScoreSheetFile] = useState(null);
    const [viewProcedure, setViewProcedure] = useState(null);
    const [viewRef1, setViewRef1] = useState(null);
    const [viewRef2, setViewRef2] = useState(null);

    const eventProceduresFileUploadRef = useRef(null)
    const scoreSheetFileUploadRef = useRef(null)
    const referenceMaterialFile1FileUploadRef = useRef(null)
    const referenceMaterialFile2FileUploadRef = useRef(null)

    useEffect(() => {
        const onLoadAccompanyingFiles = async () => {
            try {
                const criteriaFileResult = await firebase.storageGetRegistrationFile('CriteriaFile.pdf', selectedCompetition);
                setEventCriteriaFile(criteriaFileResult || null);
                const scoreSheetFileResult = await firebase.storageGetRegistrationFile('ScoresheetFile.pdf', selectedCompetition);
                setScoreSheetFile(scoreSheetFileResult || null);
            } catch (err) {
                console.error(err);
            }
        };

        if (selectedCompetition) {
            onLoadAccompanyingFiles();
        }
    }, [selectedCompetition, firebase]); 



    useEffect(() => {
        const fetchEventData = async () => {
            try {
                const eventRegistrationData = await firebase.getEventRegistration(selectedCompetition, (params.editEvent || params.eventUid));
                dispatch({ type: 'LOAD_EVENT_DATA', payload: eventRegistrationData });
                console.log(eventRegistrationData);
                if(eventRegistrationData.scoreSheetFile) {
                    setViewScoreSheetFile(await firebase.storageGetUserEventFile(eventRegistrationData.scoreSheetFile?.fileName, selectedCompetition, params.eventUid));
                }

                if(eventRegistrationData.eventProceduresFile) {
                    setViewProcedure(await firebase.storageGetUserEventFile(eventRegistrationData.eventProceduresFile?.fileName, selectedCompetition, params.eventUid));
                }

                if(eventRegistrationData.referenceMaterialFile1) {
                    setViewRef1(await firebase.storageGetUserEventFile(eventRegistrationData.referenceMaterialFile1?.fileName, selectedCompetition, params.eventUid));
                }

                if(eventRegistrationData.referenceMaterialFile2) {
                    setViewRef2(await firebase.storageGetUserEventFile(eventRegistrationData.referenceMaterialFile2?.fileName, selectedCompetition, params.eventUid));
                }
            } catch (err) {
                console.error(err);
            }
        };

        if ((params.editEvent || params.eventUid) && selectedCompetition) {
            fetchEventData();
        }
    }, [params.editEvent, params.Uid, selectedCompetition, firebase]);

    const isValid = (state) => {
        const {
            eventName,
            billingAddressLine1,
            billingAddressLine2,
            billingAddressCity,
            billingAddressState,
            billingAddressZipCode,
            billingAddressCountry,
            boothSize,
            eventProcedures,
            primaryPointOfContactFirst,
            primaryPointOfContactLast,
            primaryPointOfContactEmail,
        } = state.data;
        let validCheck = { result: true, errorMessages: [] }
        if (eventName === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Event Name cannot be blank');
        }
        if (billingAddressLine1 === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Billing Address Line 1 cannot be blank');
        }
        if (billingAddressLine2 === '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Billing Address Line 2 cannot be blank');
        }
        if (primaryPointOfContactFirst == '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Primary Point of Contact First Name cannot be blank');
        }
        if (primaryPointOfContactLast == '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Primary Point of Contact Last Name cannot be blank');
        }
        if (primaryPointOfContactEmail == '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Primary Point of Contact Email cannot be blank');
        }
        if (boothSize == '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Booth Size cannot be blank');
        }
        if (eventProcedures == '') {
            validCheck.result = false;
            validCheck.errorMessages.push('Event Procedures cannot be blank');
        }

        return validCheck;
    }


    const stripFile = (data) => {
        if (!data) { return data }
        return {
            fileName: data['fileName']
        }
    }

    const insertData = async (state) => {
        // NOTE - we dont want to upload the file blob up to Firebase Database
        const relevantFields = ['eventProceduresFile', 'scoreSheetFile', 'referenceMaterialFile1', 'referenceMaterialFile2']
        const data = Object.keys(state.data).reduce((acc, v) => {
            acc[v] = state.data[v]
            if (relevantFields.includes(v)) {
                acc[v] = stripFile(state.data[v])
            }
            return acc
        }, {})

        const resultId = await firebase.insertEventRegistration(props.competition, data)

        // upload the relevant files
        const uploading = relevantFields.map((v) => {
            if (data[v]) {
                return firebase.storageUploadUserEventRegistrationFile(state['data'][v]['file'], state['data'][v]['fileName'], props.competition, resultId)
            }
        })
        if (uploading.length > 0 ) {
            for (const promise of uploading) {
                await promise
            }
        }
    }

    // NOTE - repeated and not DRY, could be drier for sure
    const patchData = async (state) => {
        // NOTE - we dont want to upload the file blob up to Firebase Database
        const relevantFields = ['eventProceduresFile', 'scoreSheetFile', 'referenceMaterialFile1', 'referenceMaterialFile2']
        const data = Object.keys(state.data).reduce((acc, v) => {
            acc[v] = state.data[v]
            if (relevantFields.includes(v)) {
                acc[v] = stripFile(state.data[v])
            }
            return acc
        }, {})

        await firebase.patchEventRegistration(props.competition, props.id, data)

        // upload the relevant files
        const uploading = relevantFields.map((v) => {
            if (data[v]) {
                return firebase.storageUploadUserEventRegistrationFile(state['data'][v]['file'], state['data'][v]['fileName'], props.competition, props.id)
            }
        })
        if (uploading.length > 0 ) {
            for (const promise of uploading) {
                await promise
            }
        }
        // console.log('this is the result of our upload', resultId)
    }

    const submitButtonPressed = useCallback(() => {
        const isValidCheck = isValid(state);
        dispatch({
            type: "error_message_changed",
            nextErrorMessages: isValidCheck.errorMessages
        });

        if (!isValidCheck.result) return;

        if (props.id) {
            patchData(state);
        } else {
            insertData(state);
        }
    }, [state]);

    const handleFileUpload = useCallback((fileEvent) => {
        const { target } = fileEvent;
        const name = target.name;
        const file = target.files[0];
        const fileName = `${name}.${file.name.split('.').pop()}`;

        dispatch({
            type: 'value_changed',
            keyName: name,
            keyValue: {
                fileName: fileName,
                file: file
            }
        });
    }, []);

    const eventProceduresFileUploadClicked = useCallback(() => {
        eventProceduresFileUploadRef.current.click()
    }, [state.data?.eventProceduresFile])
    const scoreSheetFileUploadClicked = useCallback(() => {
        scoreSheetFileUploadRef.current.click()
    }, [state.data?.scoreSheetFile])
    const referenceMaterialFile1FileUploadClicked = useCallback(() => {
        referenceMaterialFile1FileUploadRef.current.click()
    }, [state.data?.referenceMaterialFile1])
    const referenceMaterialFile2FileUploadClicked = useCallback(() => {
        referenceMaterialFile2FileUploadRef.current.click()
    }, [state.data?.referenceMaterialFile2])


    console.log(state.data);
    console.log(state);
    return (
        <Grid2 marginTop={'15px'} maxWidth={'85%'} marginLeft={'auto'} marginRight={'auto'}>
            <Typography variant='h4'marginBottom={'15px'}>Event Sponsor Application</Typography>
            <Typography variant='body1' marginBottom={'15px'}>Companies that develop and implement competitive events for the Aerospace Maintenance Competition are vital to the council’s mission. We thank and appreciate all our event sponsors and judges for their commitment to our current and future maintenance professionals.
            </Typography>
            <br/>
            <Typography variant='body1' marginBottom={'15px'}>Event sponsors take on the following obligations:</Typography>
            <Grid2 maxWidth={'85%'} marginBottom={'25px'}>
                <Typography variant='body1' marginBottom={'15px'}>
                    <ul>
                        <li><b>Pay the event sponsorship fee and associated expense.</b> The sponsorship fee covers the floor space, two six-foot tables, two chairs, and basic electric. Event sponsors are responsible for all additional costs associated with putting on the event, including equipment, freight, and any additional show-related needs (e.g., carpet, audio visual, wireless internet, etc.). </li>
                        <li><b>Provide judges to score the event.</b> Event sponsors must assign enough representatives to judge two simultaneous events for the duration of the competition. Judges are also required to attend the judge orientation the day before the competition begins.</li>
                        <li><b>Run the event within the designated space.</b> The entire event, including equipment, competitors, tables, chairs, and judges, must fit within the designated space, which are limited and available on a first come, first served basis.</li>
                        <li><b>Provide equipment and consumables to run two events simultaneously.</b> Each event must accommodate two teams competing on that event at the same time. For example, if competitors are expected to remove and replace a component on an engine, two engines must be available so that two teams can compete at once.</li>
                        <li><b>Design the event so that it can be completed within 15 minutes.</b> While not all teams will complete all events within the allotted time, the event should be designed so that competitors are generally able to complete it within 15 minutes. Five minute breaks are provided in between each event; therefore, the judge must be able to ready the event for the next team within that time period.</li>
                        <li><b>Provide prizes to the five-person team with the top score for that event.</b> The judges are invited to present prizes at the awards ceremony taking place at the completion of the event. Prizes are provided at the sponsor's discretion, but are generally given to each member of the five-person team (with some teams bringing an alternate)--tools, gift certificates, scholarships and/or company-branded items are common. In addition to providing a prize for the team with the top score, sponsors are welcome to bestow prizes on the top scoring student team. We ask that sponsors limit prizes to those two categories (i.e., top overall and top school).</li>
                        <li><b>Adhere to the standard scorekeeping process.</b> Judges will utilize the standard AMC score sheet to calculate team scores for each event. The use of the standard score sheet is mandatory. Event scores are calculated by adding the total amount of time expended to complete the event, plus penalties assessed and bonus points awarded. The AMC score sheet will include standard penalties for all events, any additional penalties specific to an event may be added to the score sheet but must be outlined in the event criteria.</li>
                        <li><b>Submit event procedures.</b> To ensure competitors have an opportunity to prepare for the competition, all event descriptions and procedures are published in the event manual using the standard event criteria form and score sheet form. Both forms are linked below.</li>
                    </ul>
                </Typography>
            </Grid2>
            {
                eventCriteriaFile &&
                <Grid2 container>
                    <span style={{paddingTop: '2px'}}><ArticleIcon /></span>
                    <Typography variant='h6'><a target="_blank" href={eventCriteriaFile} style={{textDecoration: 'none'}} >Click Here To Download The Criteria File</a></Typography>
                </Grid2>
            }
            {
                scoreSheetFile &&
                <Grid2 container>
                    <span style={{paddingTop: '2px'}}><ArticleIcon /></span>
                    <Typography variant='h6'><a target="_blank" href={scoreSheetFile} style={{textDecoration: 'none'}}>Click Here To Download The Scoresheet File</a></Typography>
                </Grid2>
            }
            <Grid2 container flexDirection={'column'} sx={{marginTop: '25px', maxWidth: '75%'}}>
                <Typography variant='h3'>Submission Form</Typography>
                <Grid2 marginBottom={'15px'} container>
                    <FormControl sx={{flex: .5}}>
                        <TextField
                            sx={{ flex: 1 }}
                            name="eventName"
                            value={state.data?.eventName}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'eventName'
                                })
                            }}
                            label="Event Name"
                        />
                    </FormControl>
                </Grid2>
                <Grid2 marginBottom={'15px'} container>
                    <FormControl sx={{flex: .5}}>
                        <TextField
                            sx={{ flex: 1 }}
                            name="companyName"
                            value={state.data?.companyName}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'companyName'
                                })
                            }}
                            label="Company Name"
                        />
                    </FormControl>
                </Grid2>
                <Typography variant='h6' marginBottom={'15px'}>Billing Address</Typography>
                <Grid2 marginBottom={'15px'} container>
                    <FormControl sx={{flex: .5}}>
                        <TextField
                            sx={{ flex: 1 }}
                            name="billingAddressLine1"
                            value={state.data?.billingAddressLine1}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'billingAddressLine1'
                                })
                            }}
                            label="Line 1"
                        />
                    </FormControl>
                </Grid2>
                <Grid2 marginBottom={'15px'} container>
                    <FormControl sx={{flex: .5}}>
                        <TextField
                            sx={{ flex: 1 }}
                            name="billingAddressLine2"
                            value={state.data?.billingAddressLine2}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'billingAddressLine2'
                                })
                            }}
                            label="Line 2"
                        />
                    </FormControl>
                </Grid2>
                <Grid2 container sx={{marginBottom: '15px'}}>
                    <FormControl sx={{flex: .25}}>
                        <TextField
                            sx={{ flex: 1, marginRight: '15px' }}
                            name="billingAddressCity"
                            value={state.data?.billingAddressCity}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'billingAddressCity'
                                })
                            }}
                            label="City"
                        />
                    </FormControl>
                    <FormControl sx={{flex: .25}}>
                        <TextField
                            sx={{ flex: 1}}
                            name="billingAddressState"
                            value={state.data?.billingAddressState}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'billingAddressState'
                                })
                            }}
                            label="State"
                        />
                    </FormControl>
                </Grid2>
                <Grid2 container sx={{marginBottom: '15px'}}>
                    <FormControl sx={{flex: .25}}>
                        <TextField
                            sx={{ flex: 1, marginRight: '15px'}}
                            name="billingAddressZipCode"
                            value={state.data?.billingAddressZipCode}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'billingAddressZipCode'
                                })
                            }}
                            label="Zip Code"
                        />
                    </FormControl>
                    <FormControl sx={{flex: .25}}>
                        <TextField
                            sx={{ flex: 1}}
                            name="billingAddressCountry"
                            value={state.data?.billingAddressCountry}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value.trim(),
                                    keyName: 'billingAddressCountry'
                                })
                            }}
                            label="Country"
                        />
                    </FormControl>
                </Grid2>
                <Grid2 marginBottom={'15px'} container>
                    <FormControl sx={{flex: .25}}>
                        <TextField
                            sx={{ flex: 1, marginRight: '15px'}}
                            name="primaryPointOfContactFirst"
                            value={state.data?.primaryPointOfContactFirst}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'primaryPointOfContactFirst'
                                })
                            }}
                            label="First"
                        />
                    </FormControl>
                    <FormControl sx={{flex: .25}}>
                        <TextField
                            sx={{ flex: 1 }}
                            name="primaryPointOfContactLast"
                            value={state.data?.primaryPointOfContactLast}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'primaryPointOfContactLast'
                                })
                            }}
                            label="Last"
                        />
                    </FormControl>
                </Grid2>
                <Grid2 marginBottom={'15px'} container>
                    <FormControl sx={{flex:.5}}>
                        <TextField
                            sx={{ flex: 1 }}
                            name="primaryPointOfContactEmail"
                            value={state.data?.primaryPointOfContactEmail}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'primaryPointOfContactEmail'
                                })
                            }}
                            label="Email"
                        />
                    </FormControl>
                </Grid2>
                <Grid2 marginBottom={'15px'} container>
                    <FormControl sx={{flex: .5}}>
                        <InputLabel id="demo-simple-select-label">Booth Size</InputLabel>
                        <Select name="boothSize" value={state.data?.boothSize}
                            label={'Booth Size'}
                            disabled={isView}
                            onChange={(e) => {
                                dispatch({
                                    type: 'value_changed',
                                    keyValue: e.target.value,
                                    keyName: 'boothSize'
                                })
                            }}>
                            <MenuItem value="10x10">10x10 ($5,000)</MenuItem>
                            <MenuItem value="10x20">10x20 ($7,000)</MenuItem>
                            <MenuItem value="20x20">20x20 ($9,000)</MenuItem>
                            <MenuItem value="other">Other (prior approval only)</MenuItem>
                        </Select>
                    </FormControl>
                </Grid2>
                <FormControl disabled={isView}>
                    <FormLabel id="demo-radio-buttons-group-label">Event Procedures</FormLabel>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={state.data?.eventProcedures}
                        disabled={isView}
                        onChange={(e) => {
                            dispatch({
                                type: 'value_changed',
                                keyValue: e.target.value,
                                keyName: 'eventProcedures'
                            })
                        }}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel disabled={isView} value="incumbent_no_change" control={<Radio />} label="Incumbent event, no change to procedures" />
                        <FormControlLabel disabled={isView} value="incumbent_revised" control={<Radio />} label="Incumbent event with a change, revised procedures and/or score sheet uploaded below" />
                        <FormControlLabel disabled={isView} value="new_event" control={<Radio />} label="New event, procedures and score sheet uploaded below" />
                    </RadioGroup>
                </FormControl>
            </Grid2>
            <Grid2 flexDirection={'column'} container={true}>
                {!isView && <>
                <FormControl>
                    <Grid2 container>
                        <Tooltip title="Upload the event criteria using the form linked above. Required for revised and new events." placement="right-end">
                            <Button
                                sx={{ marginTop: '5px' }}
                                variant='contained'
                                component="label"
                                onClick={eventProceduresFileUploadClicked}
                                disabled={isView}
                            >
                                Upload {state.data?.eventProceduresFile? 'New' : ''} Event Procedures
                            </Button>
                        </Tooltip>
                        <input type="file" hidden
                            ref={eventProceduresFileUploadRef}
                            name={'eventProceduresFile'}
                            onChange={handleFileUpload}
                        />
                        {
                            state.data?.eventProceduresFile &&
                            <Grid2 container  alignItems={'center'} marginLeft={'15px'}>
                                <span style={{paddingTop: '2px'}}><ArticleIcon /></span>
                                <Typography variant='body1'>{state.data?.eventProceduresFile.fileName}</Typography>
                            </Grid2>
                        }
                    </Grid2>
                </FormControl>
                <FormControl>
                    <Grid2 container>
                        {
                            state.data?.scoreSheetFile &&
                            <Typography variant='body1'>{state.data?.scoreSheetFile.fileName} uploaded</Typography>
                        }
                        <Tooltip title="Please upload the completed score sheet for your event. Required for new and revised events." placement="right-end">
                            <Button
                                sx={{marginTop: '5px'}}
                                variant='contained'
                                component="label"
                                onClick={scoreSheetFileUploadClicked}
                                disabled={isView}
                            >
                                Upload Scoresheet Form
                            </Button>
                        </Tooltip>
                        <input type="file" hidden
                            ref={scoreSheetFileUploadRef}
                            name={'scoreSheetFile'}
                            onChange={handleFileUpload}
                        />
                    </Grid2>
                </FormControl>
                <FormControl>
                    <Grid2 container>
                        {
                            state.data?.referenceMaterialFile1 &&
                            <Typography variant='body1'>{state.data?.referenceMaterialFile1.fileName} uploaded</Typography>
                        }
                        <Button
                            sx={{ marginTop: '5px' }}
                            variant='contained'
                            disabled={isView}
                            component="label"
                            onClick={referenceMaterialFile1FileUploadClicked}
                        >
                            Upload Reference Material 1
                        </Button>
                        <input type="file" hidden
                            ref={referenceMaterialFile1FileUploadRef}
                            name={'referenceMaterialFile1'}
                            onChange={handleFileUpload}
                        />
                    </Grid2>
                </FormControl>
                <FormControl>
                    <Grid2 container>
                        {
                            state.data?.referenceMaterialFile2 &&
                            <Typography variant='body1'>{state.data?.referenceMaterialFile2.fileName} uploaded</Typography>
                        }
                        <Button
                            sx={{marginTop: '5px'}}
                            variant='contained'
                            component="label"
                            disabled={isView}
                            onClick={referenceMaterialFile2FileUploadClicked}
                        >
                            Upload Reference Material 2
                        </Button>
                        <input type="file" hidden
                            ref={referenceMaterialFile2FileUploadRef}
                            name={'referenceMaterialFile2'}
                            onChange={handleFileUpload}
                        />
                    </Grid2>
                </FormControl>
                    </>
                }

                {isView &&
                        <>
                            {viewScoreSheetFile != null &&
                            <Button
                                variant='contained'
                                component='a'
                                href={viewScoreSheetFile}
                                download={`ScoreSheetFile-${state.data?.eventName}`}
                                >Download scoresheet file</Button>
                            }

                            {viewProcedure != null &&
                            <Button
                                variant='contained'
                                component='a'
                                href={viewProcedure}
                                download={`procedure-${state.data?.eventName}`}
                                >Download procedure file</Button>
                            }

                            {viewRef1 != null &&
                            <Button
                                variant='contained'
                                component='a'
                                href={viewRef1}
                                download={`reference1-${state.data?.eventName}`}
                                >Download reference file 1</Button>
                            }

                            {viewRef2 != null &&
                            <Button
                                variant='contained'
                                component='a'
                                href={viewRef2}
                                download={`reference2-${state.data?.eventName}`}
                                >Download reference file 2</Button>
                            }
                        </>
                }
            </Grid2>
            <Typography variant='body1' marginTop={'15px'}>By submitting this form on behalf of the sponsor company, you acknowledge your understanding of the event sponsor obligations set forth above. You also acknowledge that the AMC may elect to remove an event or exclude its scores from team tallies solely at its discretion. The AMC may also adjust individual team scores pursuant to its appeal process and at the discretion of the AMC chairman and chief judge.
            </Typography>
            <Typography variant='body1' marginTop={'30px'}>Submission of the form does not guarantee inclusion as an event sponsor. The final event roster will be announced by October 1.
            </Typography>
            <Button onClick={submitButtonPressed} disabled={isView}>Submit</Button>
            <FormErrorMessages errorMessages={state.errorMessages || []} />
        </Grid2 >
    )
}
// TOOD - checkboxes for judges and teams as a component
export default EventRegistrationForm
