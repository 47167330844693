import React, { useReducer, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import EventForm from '../components/forms/EventForm';
import * as routes from '../routes/routes';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';
import { CommonDataContext } from '../firebase/CommonDataContext';
import CompetitionDropdown from '../components/forms/Dropdowns/CompetitionDropdown';
import { UserDataContext } from '../firebase/UserDataContext';
import { FirebaseNewContext } from '../firebase/FirebaseNewContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Button, Checkbox, FormControlLabel, FormGroup, ListItemText, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

function globalSettingsFormInitialState(props) {
    return {
        selectedCompetition: '',
        globalDefaultCompetition: '',
        showFinalScore: false,
        lockCompetition: false,
        errorMessages: []
    }

}

function globalSettingsFormReducer(state, action) {
    switch (action.type) {
        case 'values_changed':
            return {
                ...state,
                ...action.value
            }
        case 'show_final_score_changed':
            return {
                ...state,
                showFinalScore: !state.showFinalScore
            }
        case 'lock_competition_changed':
            return {
                ...state,
                lockCompetition: !state.lockCompetition
            }
        case 'competition_changed':
            // TODO - will prompt the change of the show final score to change
            // pass the globals required to make this change
            let showFinalScore
            if (('showFinalScoresToTeams' in action.globalSettings)) {
                showFinalScore = action.globalSettings.showFinalScoresToTeams[action.value] || false
            }

            let lockCompetition
            if (('lockCompetition' in action.globalSettings)) {
                lockCompetition = action.globalSettings.lockCompetition[action.value] || false
            }
            return {
                ...state,
                selectedCompetition: action.value,
                showFinalScore,
                lockCompetition
            }
        case 'global_competition_changed':
            console.log('this is our', action.value)
            return {
                ...state,
                globalDefaultCompetition: action.value
            }
        default:
            break;
    }
}

function GlobalSettingsPage(props) {
    const { globalSettings } = useContext(GlobalSettingsContext)
    const { firebase } = useContext(FirebaseNewContext)
    const { defaultSelectedCompetition } = useContext(UserDataContext)
    const [state, dispatch] = useReducer(globalSettingsFormReducer, props, globalSettingsFormInitialState)
    useEffect(() => {
        let showFinalScore = false
        if (('showFinalScoresToTeams' in globalSettings)) {
            showFinalScore = globalSettings.showFinalScoresToTeams[globalSettings.defaultCompetition] || false
        }

        let lockCompetition = false
        if (('lockCompetition' in globalSettings)) {
            lockCompetition = (globalSettings.lockCompetition != undefined && globalSettings.lockCompetition[globalSettings.defaultCompetition]) || false
        }
        dispatch({
            type: 'values_changed',
            value: {
                selectedCompetition: globalSettings['defaultCompetition'],
                globalDefaultCompetition: globalSettings['defaultCompetition'],
                showFinalScore,
                lockCompetition
            }
        })

    }, [])
    const submitButtonPressed = () => {
        firebase.setShowFinalScoresToTeams(state.selectedCompetition, state.showFinalScore)
        firebase.setLockCompetition(state.selectedCompetition, state.lockCompetition);
        firebase.setDefaultCompetition(state.globalDefaultCompetition)
    }
    return (
        <Grid2>
            <Typography variant='h5'>Change The Global Competition to:</Typography>
            <CompetitionDropdown
                value={state.globalDefaultCompetition}
                onChange={(value) => {
                    dispatch({
                        type: 'global_competition_changed',
                        value
                    })

                }}
            />
            <Typography variant='h5'>Current Competition - {globalSettings.competitions[state.selectedCompetition]}</Typography>
            <CompetitionDropdown
                value={state.selectedCompetition}
                onChange={(value) => {
                    dispatch({
                        type: 'competition_changed',
                        value,
                        globalSettings
                    })

                }}
            />
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    checked={state.showFinalScore}
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch({
                            type: 'show_final_score_changed'
                        })
                    }}
                />} label={`Show The Final Score`} />
            </FormGroup>
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    checked={state.lockCompetition}
                    onClick={(e) => {
                        e.preventDefault()
                        dispatch({
                            type: 'lock_competition_changed'
                        })
                    }}
                />} label={`Lock the competition`} />
            </FormGroup>
            <Button type="submit" onClick={submitButtonPressed}>Save</Button>
        </Grid2>
    )

}

export default GlobalSettingsPage;
