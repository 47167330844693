import React, { useState, useEffect, useContext } from 'react';
import { Tabs, Tab, Box, Button } from '@mui/material';
import WaitroomEvent from '../components/registration/WaitroomEvent';
import WaitroomSponsor from '../components/registration/WaitroomSponsor';
import * as ROUTES from '../routes/routes';
import { UserDataContext } from '../firebase/UserDataContext';
import { Link } from 'react-router-dom';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function WaitroomPage(props) {
    const [value, setValue] = useState(0);
    const { defaultSelectedCompetition } = useContext(UserDataContext);
    const [selectedCompetition, setSelectedCompetition] = useState(defaultSelectedCompetition);

    // Retrieve the saved tab value from localStorage when the component mounts
    useEffect(() => {
        const savedTab = localStorage.getItem('waitroomTab');
        if (savedTab !== null) {
            setValue(Number(savedTab)); // Ensure the value is a number
        }
    }, []);

    // Handle tab change and save the value to localStorage
    const handleChange = (_, newValue) => {
        setValue(newValue);
        localStorage.setItem('waitroomTab', newValue); // Save the selected tab index
    };

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Tabs value={value} onChange={handleChange} aria-label="waitroom tabs">
                    <Tab label="Event" />
                    <Tab label="Sponsor" />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Button component={Link}
                            to={
                                ROUTES.routeReplace(ROUTES.EVENT_REGISTRATION_PAGE, { competition: selectedCompetition })
                            }
                            target="_blank" rel="noopener noreferrer">Register Event</Button>

                    <WaitroomEvent {...props} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Button component={Link}
                            to={
                                ROUTES.routeReplace(ROUTES.SPONSOR_REGISTRATION_PAGE, { competition: selectedCompetition })
                            }
                            target="_blank" rel="noopener noreferrer">Register Sponsor</Button>

                    <WaitroomSponsor {...props} />
                </TabPanel>
            </Box>
        </>
    );
}

export default WaitroomPage;

