import React, { useContext } from 'react';
import * as routes from '../routes/routes';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Link, useLocation, useParams } from 'react-router-dom';
import EventRegistrationForm from '../components/registration/EventRegistrationForm';
import TeamRegistrationForm from '../components/registration/TeamRegistrationForm';

import { Button, Typography } from '@mui/material';
import { RegistrationSettingsContext } from '../firebase/RegistrationSettingsContext';
import { GlobalSettingsContext } from '../firebase/GlobalSettingsContext';


function RegistrationPage() {
    const location = useLocation()
    const params = useParams()

    return (
        <>
            {
                location.pathname.includes('event')  &&
                <Grid2>
                    <EventRegistrationForm competition={params.competition} id={params.id} />
                </Grid2>
            }
            {
                location.pathname.includes('sponsor') &&
                <Grid2>
                    <TeamRegistrationForm competition={params.competition} id={params.id} />
                </Grid2>
            }
            {
                location.pathname === routes.REGISTRATION_PAGE &&
                <Grid2>
                    <MainRegistraitonPage />
                </Grid2>
            }
        </>
    )

}

function MainRegistraitonPage() {

    const {registrationSettings } = useContext(RegistrationSettingsContext)
    const { globalSettings: { competitions } }  = useContext(GlobalSettingsContext)

    return (
        <Grid2 paddingLeft={'25px'}>
            <Typography variant='h5'>Registration</Typography>
            <Grid2 direction={'column'} container>
                {
                    Object.keys(registrationSettings).map((v) => {
                        const hasSomethingOpen = ['event', 'team', 'sponsor'].reduce((acc,category) => {
                            if (acc) {
                                return acc
                            }
                            if (registrationSettings[v] && registrationSettings[v][category]) {
                                console.log(registrationSettings[v][category])
                                if (!registrationSettings[v][category]['closeRegistration']) {
                                    acc = true
                                }
                            }
                            return acc
                        }, false)
                        if (hasSomethingOpen && v in competitions) {
                            return (
                                <Grid2 key={`competition-${v}`}>
                                    <Typography variant='h6'>Register for {competitions[v]}</Typography>
                                    {
                                        registrationSettings[v] && registrationSettings[v]['event'] && (
                                            <Grid2 marginBottom={'15px'}>
                                            {
                                                registrationSettings[v]['event']['closeRegistration'] ?
                                                    (
                                                        <Typography variant='body'>Registration is closed for teams</Typography>
                                                    )
                                                    :
                                                    (

                                                        <Button component={Link} variant={'outlined'} to={routes.routeReplace(routes.EVENT_REGISTRATION_PAGE, {competition: v})}>Event Registration</Button>
                                                    )

                                            }
                                            </Grid2>
                                        )
                                    }
                                    {
                                        registrationSettings[v] && registrationSettings[v]['sponsor'] && (
                                            <Grid2 marginBottom={'15px'}>
                                                {
                                                    registrationSettings[v]['sponsor']['closeRegistration'] ?
                                                        (
                                                            <Typography variant='body'>Registration is closed for teams</Typography>
                                                        )
                                                        :
                                                        (
                                                        <Button component={Link} variant={'outlined'} to={routes.routeReplace(routes.SPONSOR_REGISTRATION_PAGE, {competition: v})}>Sponsor Registration</Button>
                                                        )
                                                    
                                                }
                                            </Grid2>
                                        )
                                    }
                                </Grid2>
                            )
                        }
                    })
                }
            </Grid2>
        </Grid2>
    )
}

export default RegistrationPage;
